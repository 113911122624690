import React from "react";
import { Col, Row, Input, Button, Checkbox, Dropdown, Menu } from "antd";
import { FiMoreHorizontal } from "react-icons/fi";

const ChecklistItems = ({
  checklist,
  // Edit Item
  editMode,
  setEditMode,
  editItemId,
  setEditItemId,
  editItemName,
  setEditItemName,
  editItemLoading,
  handleUpdateItem,
  // Delete Item
  handleDeleteItem,
}) => {
  return (
    <>
      <Row>
        {checklist?.items?.length > 0 &&
          checklist?.items?.map((item) => (
            <Col span={24} key={item._id}>
              <Row>
                <>
                  <Col span={2}>
                    <Checkbox
                      checked={item.done}
                      onChange={(e) => {
                        const body = {
                          done: e.target.checked,
                        };
                        handleUpdateItem(checklist._id, item._id, body);
                      }}
                    />
                  </Col>
                  <Col
                    span={20}
                    style={{
                      marginTop: "0.05rem",
                      marginLeft: "-20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditMode(true);
                      setEditItemId(item._id);
                      setEditItemName(item.itemName);
                    }}
                  >
                    {editMode && editItemId === item._id ? (
                      <>
                        <Input.TextArea
                          autoSize={{
                            minRows: 2,
                            maxRows: 3,
                          }}
                          style={{
                            marginBottom: "0.2rem",
                          }}
                          value={editItemName}
                          onChange={(e) => {
                            setEditItemName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <p>{item.itemName}</p>
                    )}
                  </Col>

                  {/* 3 dots more option */}
                  <Col
                    span={2}
                    style={{
                      marginTop: "-0.5rem",
                    }}
                  >
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <Menu
                          style={{
                            top: "-1.3rem",
                            transform: "scale(0.8)",
                          }}
                        >
                          <Menu.Item
                            key={0}
                            onClick={() => {
                              setEditMode(true);
                              setEditItemId(item._id);
                              setEditItemName(item.itemName);
                            }}
                          >
                            Edit item
                          </Menu.Item>
                          <Menu.Item
                            key={1}
                            onClick={() => {
                              handleDeleteItem(checklist._id, item._id);
                            }}
                          >
                            Delete item
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button
                        size="small"
                        style={{
                          border: "none",
                        }}
                      >
                        <FiMoreHorizontal className="remix-icon" size={16} />
                      </Button>
                    </Dropdown>
                  </Col>
                  {/* Save & Cancel buttons */}
                  {editMode && editItemId === item._id && (
                    <>
                      <Col
                        span={12}
                        style={{
                          marginLeft: "1.6rem",
                          marginTop: "0.8rem",
                          marginBottom: "0.8rem",
                        }}
                      >
                        <Button
                          type="primary"
                          size="small"
                          style={{
                            marginRight: "0.7rem",
                          }}
                          disabled={editItemName === ""}
                          onClick={() => {
                            const body = {
                              itemName: editItemName,
                            };
                            handleUpdateItem(checklist._id, item._id, body);
                          }}
                          loading={editItemLoading}
                        >
                          Save
                        </Button>
                        <Button
                          size="small"
                          onClick={() => {
                            setEditMode(false);
                            setEditItemId(null);
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </>
                  )}
                </>
              </Row>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default ChecklistItems;
