// Global Actions
export const ACTION_TYPES = {
  // Alert action types
  ALERT: "ALERT",
};

// Project Actions
export const PROJECT_TYPES = {
  // Tasks
  GET_TASKS: "GET_TASKS",
  UPDATE_TASKS_BOARD: "UPDATE_TASKS_BOARD",
  UPDATE_TASK_STATUS: "UPDATE_TASK_STATUS",
  CURRENT_PROJECT: "CURRENT_PROJECT",
  GET_TASK_ASSIGNEES: "GET_TASK_ASSIGNEES",
};

// Chat Actions
export const CHAT_TYPES = {
  // Notifications
  MESSAGE_NOTIFICATION: "MESSAGE_NOTIFICATION",
};

// Dashboard Actions
export const DASHBOARD_TYPES = {
  // Analytics
  GET_ANALYTICS: "GET_ANALYTICS",
};
