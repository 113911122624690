import React from "react";
import { Row, Button, Modal } from "antd";
import { MdDeleteForever } from "react-icons/md";
import { deleteTaskById } from "../../../../../api/task";
import { updateTasksBoardAction } from "../../../../../redux/actions/projectActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Actions = ({ taskId, handleModalClick }) => {
  const dispatch = useDispatch();
  const { id, mid } = useParams();

  // Handle task delete
  const handleTaskDelete = () => {
    // Confirm delete
    Modal.confirm({
      title: "Are you sure you want to delete this task?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      content: (
        <p className="hp-p1-body hp-text-color-black-80">
          All actions will be removed from the activity feed and you won’t be
          able to re-open the card. There is no undo.
        </p>
      ),
      onOk: async () => {
        try {
          await deleteTaskById(id, mid, taskId);

          // Fetch all tasks
          dispatch(updateTasksBoardAction(mid));

          // Close modal
          handleModalClick();
        } catch (error) {
          // console log the error
          console.log(error);
        }
      },
    });
  };

  return (
    <>
      <Row
        style={{
          marginBottom: "1rem",
        }}
      >
        <h4>Task Actions</h4>
      </Row>

      {/* Delete Task Button */}
      <Row>
        <Button
          type="danger"
          shape="rounded"
          icon={
            <MdDeleteForever
              style={{
                marginRight: "0.5rem",
              }}
            />
          }
          size={"small"}
          onClick={handleTaskDelete}
        >
          Delete Task
        </Button>
      </Row>
    </>
  );
};

export default Actions;
