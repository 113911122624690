import React, { useState } from "react";
import { Row, Col, Comment, Avatar, Input, Button, Modal } from "antd";
import { RiInformationLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import {
  createCommentForTask,
  editCommentById,
  deleteCommentOfTask,
} from "../../../../../api/comment";
import moment from "moment";

const Comments = ({ values, setValues, taskId }) => {
  const { user } = useSelector((state) => state.auth);
  // New comment
  const [newCommentMode, setNewCommentMode] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [newCommentLoading, setNewCommentLoading] = useState(false);
  // Edit comment
  const [editCommentMode, setEditCommentMode] = useState(false);
  const [editCommentId, setEditCommentId] = useState("");
  const [editComment, setEditComment] = useState("");
  const [editCommentLoading, setEditCommentLoading] = useState(false);

  // Handle create comment for task
  const handleCreateComment = async () => {
    setNewCommentLoading(true);
    const body = {
      by: user._id,
      comment: newComment,
    };
    createCommentForTask(taskId, body)
      .then((res) => {
        // Insert new comment at the beginning of the array
        const sortComments = [res.data, ...values.comments];
        setValues({
          ...values,
          comments: sortComments,
        });
        setNewComment("");
        setNewCommentMode(false);
        setNewCommentLoading(false);
      })
      .catch((err) => {
        setNewCommentLoading(false);
        console.log(err);
      });
  };

  // Handle edit comment
  const handleEditComment = async (cid) => {
    setEditCommentLoading(true);
    const body = {
      comment: editComment,
    };
    editCommentById(cid, body)
      .then((res) => {
        // Find the comment by id and update it
        const updatedComments = values.comments.map((comment) => {
          if (comment._id === res.data._id) {
            return res.data;
          }
          return comment;
        });

        setValues({
          ...values,
          comments: updatedComments,
        });

        setEditComment("");
        setEditCommentId("");
        setEditCommentMode(false);
        setEditCommentLoading(false);
      })
      .catch((err) => {
        setEditCommentLoading(false);
        console.log(err);
      });
  };

  // Handle delete comment of a task
  const handleDeleteComment = async (cid) => {
    // Confirm delete comment
    Modal.confirm({
      title: <h5 className="hp-mb-0 hp-font-weight-500">Delete comment?</h5>,
      icon: (
        <span className="remix-icon">
          <RiInformationLine />
        </span>
      ),
      okText: "Yes",
      cancelText: "No",
      content: (
        <p className="hp-p1-body hp-text-color-black-80">
          Deleting a comment is permanent. There is no undo.
        </p>
      ),
      onOk: async () => {
        deleteCommentOfTask(taskId, cid)
          .then(() => {
            // Find the comment by id and delete it
            const updatedComments = values.comments.filter((comment) => {
              return comment._id !== cid;
            });

            setValues({
              ...values,
              comments: updatedComments,
            });
          })
          .catch((err) => {
            console.log("DELETE_COMMENT_OF_TASK_ERROR", err);
          });
      },
    });
  };

  return (
    <>
      <Row>
        <h4>Comments </h4>
      </Row>
      <>
        <Comment
          avatar={
            <Avatar
              key={"1"}
              className="hp-text-color-danger-1 hp-bg-color-danger-4"
            >
              {user.name.charAt(0).toUpperCase()}{" "}
              {user.name.charAt(user.name.length - 1).toUpperCase()}
            </Avatar>
          }
          content={
            <>
              <Row
                onClick={() => {
                  setNewCommentMode(true);
                }}
                style={{
                  marginBottom: "0.7rem",
                }}
              >
                <Input.TextArea
                  autoSize={{
                    minRows: 2,
                    maxRows: 2,
                  }}
                  style={{
                    marginBottom: "0.2rem",
                  }}
                  placeholder="Write a comment..."
                  value={newComment}
                  onChange={(e) => {
                    setNewComment(e.target.value);
                  }}
                />
              </Row>

              {newCommentMode && (
                <Row>
                  <Button
                    size="small"
                    type="primary"
                    disabled={newComment === ""}
                    onClick={() => {
                      handleCreateComment();
                    }}
                    style={{
                      marginRight: "0.7rem",
                    }}
                    loading={newCommentLoading}
                  >
                    Save
                  </Button>

                  <Button
                    size="small"
                    onClick={() => {
                      setNewCommentMode(false);
                      setNewComment("");
                    }}
                  >
                    Cancel
                  </Button>
                </Row>
              )}
            </>
          }
        />

        {/* Map all the values.comments if it exists */}
        {values.comments &&
          values.comments.map((comment) => {
            return (
              <Comment
                key={comment._id}
                style={{
                  marginTop: "-1rem",
                  marginBottom: "-1rem",
                }}
                avatar={
                  <Avatar
                    key={comment._id}
                    className="hp-text-color-danger-1 hp-bg-color-danger-4"
                  >
                    {comment.by.name.charAt(0).toUpperCase()}{" "}
                    {comment.by.name
                      .charAt(comment.by.name.length - 1)
                      .toUpperCase()}
                  </Avatar>
                }
                content={
                  <>
                    {/* Comment By & Created At */}
                    <Row>
                      <h5>
                        <strong>{comment.by.name}</strong>
                      </h5>
                      {/* Time ago */}
                      <small
                        style={{
                          marginLeft: "0.4rem",
                          marginTop: "0.25rem",
                        }}
                      >
                        {moment(comment.createdAt).fromNow()}
                      </small>
                    </Row>

                    {/* The Comment */}
                    <Row>
                      <Col span={20}>
                        {editCommentMode && editCommentId === comment._id ? (
                          <Input.TextArea
                            autoSize={{
                              minRows: 3,
                              maxRows: 8,
                            }}
                            style={{
                              marginBottom: "0.2rem",
                            }}
                            value={editComment}
                            onChange={(e) => {
                              setEditComment(e.target.value);
                            }}
                          />
                        ) : (
                          <Input.TextArea
                            autoSize={{
                              minRows: 1,
                              maxRows: 8,
                            }}
                            style={{
                              marginBottom: "0.2rem",
                            }}
                            value={comment.comment}
                          />
                        )}
                      </Col>
                    </Row>

                    {/* Edit and Delete */}
                    {editCommentId !== comment._id &&
                      comment.by._id === user._id && (
                        <Row>
                          {/* Edit */}
                          <Button
                            size="small"
                            className="hp-text-color-primary-1 hp-hover-bg-primary-4"
                            type="text"
                            disabled={comment.by._id !== user._id}
                            onClick={() => {
                              setEditCommentMode(true);
                              setEditCommentId(comment._id);
                              setEditComment(comment.comment);
                            }}
                            style={{
                              marginRight: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            Edit
                          </Button>

                          {/* Delete */}
                          <Button
                            size="small"
                            className="hp-text-color-primary-1 hp-hover-bg-primary-4"
                            type="text"
                            disabled={comment.by._id !== user._id}
                            onClick={() => {
                              handleDeleteComment(comment._id);
                            }}
                            style={{
                              marginRight: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            Delete
                          </Button>
                        </Row>
                      )}

                    {/* Save or Cancel */}
                    {editCommentMode && editCommentId === comment._id && (
                      <Row>
                        {/* Save */}
                        <Button
                          size="small"
                          className="hp-text-color-primary-1 hp-hover-bg-primary-4"
                          type="primary"
                          onClick={() => {
                            handleEditComment(comment._id);
                          }}
                          disabled={editComment === ""}
                          style={{
                            marginRight: "0.7rem",
                          }}
                          loading={editCommentLoading}
                        >
                          Save
                        </Button>

                        {/* Cancel */}
                        <Button
                          size="small"
                          className="hp-text-color-primary-1 hp-hover-bg-primary-4"
                          type="danger"
                          disabled={comment.by._id !== user._id}
                          onClick={() => {
                            setEditCommentMode(false);
                            setEditCommentId("");
                            setEditComment("");
                          }}
                          style={{
                            marginRight: "0.7rem",
                          }}
                        >
                          Cancel
                        </Button>
                      </Row>
                    )}
                  </>
                }
              />
            );
          })}
      </>
    </>
  );
};

export default Comments;
