import React from "react";
import { Row, Col, List } from "antd";

const Logs = ({ values }) => {
  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
    // Time
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return (
    <>
      <Row>
        <h4>Logs</h4>
      </Row>
      {/* Logs */}
      <Row>
        <Col
          span={24}
          style={{
            overflow: "auto",
            maxHeight: "250px",
          }}
        >
          {values?.logs?.length > 0 ? (
            <List
              itemLayout="horizontal"
              dataSource={values.logs}
              renderItem={(item) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      title={`${item?.activity}`}
                      description={new Date(item?.createdAt).toLocaleDateString(
                        "en-US",
                        dateFormat
                      )}
                    />
                  </List.Item>
                </>
              )}
            />
          ) : (
            <p>No logs</p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Logs;
