import api from "./index";

// Create a project
export const createProject = async (values) => {
  return await api.post("/project", values);
};

// Get all projects
export const getAllProjects = async () => {
  return await api.get("/projects");
};

// Get a project by id
export const getProjectById = async (id) => {
  return await api.get(`/project/${id}`);
};

// Update a project by id
export const updateProjectById = async (id, project) => {
  return await api.put(`/project/${id}`, project);
};

// Delete a project by id
export const deleteProjectById = async (id) => {
  return await api.delete(`/project/${id}`);
};

// Get team (Mem + Man) of a project
export const getAllTeamOfProject = async (id) => {
  return await api.get(`/project/${id}/team`);
};

// Get project report
export const getProjectReportById = async (id) => {
  return await api.get(`/project/${id}/report`);
};

// Duplicate a project
export const duplicateProject = async (id) => {
  return await api.post(`/project/${id}/duplicate`);
};

// Get all projects for gantt
export const getAllProjectsForGantt = async () => {
  return await api.get("/projects/all/gantt");
};
