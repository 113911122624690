import React from "react";

import { Row, Col, Card, Avatar } from "antd";
import { RiArrowRightLine } from "react-icons/ri";
import { Link } from "react-router-dom";

import marketingImg1 from "../../../assets/images/dashboard/analytics-marketing-team-1.svg";
import marketingImg2 from "../../../assets/images/dashboard/analytics-marketing-team-2.svg";
import marketingImg3 from "../../../assets/images/dashboard/analytics-marketing-team-3.svg";
import marketingImg4 from "../../../assets/images/dashboard/analytics-marketing-team-4.svg";
import marketingImg5 from "../../../assets/images/dashboard/analytics-marketing-team-5.svg";
import marketingImg6 from "../../../assets/images/dashboard/analytics-marketing-team-6.svg";
import { useSelector } from "react-redux";

const ManageTeamCard = () => {
  const { user } = useSelector((state) => state.auth);

  const role = user?.role;

  // Handle role based redirect
  const handleRoleRedirect = (role) => {
    switch (role) {
      case "admin":
        return "/admin/team";
      case "manager":
        return "/manager/team";
      case "member":
        return "/member/chat";
      case "guest":
        return "/guest/chat";
      default:
        return "/";
    }
  };

  return (
    !user?.memberChatDisabled && (
      <Card className="hp-border-color-black-40">
        <Link to={handleRoleRedirect(role)}>
          <Row justify="space-between">
            <Col md={12} span={24}>
              <h3>
                {user?.role === "member" || user?.role === "guest"
                  ? "Chat with team"
                  : "Manage my team"}
              </h3>
            </Col>

            <Col md={12} span={24} className="hp-mt-sm-32">
              <Row align="middle" justify="center">
                <Avatar
                  size={36}
                  src={marketingImg1}
                  className="hp-mr-8 hp-bg-color-primary-3"
                />

                <Avatar
                  size={36}
                  src={marketingImg2}
                  className="hp-mr-8 hp-bg-color-info-3"
                />

                <Avatar
                  size={36}
                  src={marketingImg3}
                  className="hp-mr-8 hp-bg-color-black-60"
                />

                <Avatar
                  size={36}
                  src={marketingImg4}
                  className="hp-mr-8 hp-bg-color-danger-3"
                />

                <Avatar
                  size={36}
                  src={marketingImg5}
                  className="hp-mr-8 hp-bg-color-secondary-3"
                />

                <Avatar
                  size={36}
                  src={marketingImg6}
                  className="hp-mr-16 hp-bg-color-warning-3"
                />

                <RiArrowRightLine
                  className="remix-icon hp-text-color-dark-30"
                  size={24}
                />
              </Row>
            </Col>
          </Row>
        </Link>
      </Card>
    )
  );
};

export default ManageTeamCard;
