import React from "react";
import { Row, Button, Modal } from "antd";
import { MdDeleteForever } from "react-icons/md";
import { deleteTodoById } from "../../../../../api/user";
import { updateTodosBoardAction } from "../../../../../redux/actions/userActions";
import { useDispatch } from "react-redux";

const Actions = ({ todoId, handleModalClick }) => {
  const dispatch = useDispatch();

  // Handle todo delete
  const handleTodoDelete = () => {
    // Confirm delete
    Modal.confirm({
      title: "Are you sure you want to delete this todo?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      content: (
        <p className="hp-p1-body hp-text-color-black-80">
          All actions will be removed from the activity feed and you won’t be
          able to re-open the card. There is no undo.
        </p>
      ),
      onOk: async () => {
        try {
          await deleteTodoById(todoId);

          // Fetch all todos
          dispatch(updateTodosBoardAction());

          // Close modal
          handleModalClick();
        } catch (error) {
          // console log the error
          console.log(error);
        }
      },
    });
  };

  return (
    <>
      <Row
        style={{
          marginBottom: "1rem",
        }}
      >
        <h4>Todo Actions</h4>
      </Row>

      {/* Delete Todo Button */}
      <Row>
        <Button
          type="danger"
          shape="rounded"
          icon={
            <MdDeleteForever
              style={{
                marginRight: "0.5rem",
              }}
            />
          }
          size={"small"}
          onClick={handleTodoDelete}
        >
          Delete Todo
        </Button>
      </Row>
    </>
  );
};

export default Actions;
