import React, { useState } from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { createTask } from "../../../api/task";
import { updateTasksBoardAction } from "../../../redux/actions/projectActions";
import { SuccessNotification, ErrorNotification } from "../../../components";

const initialState = {
  name: "",
  stage: "",
};

const CreateTaskModal = ({ open, handleModalClick }) => {
  const { id, mid } = useParams(); // Project ID & Milestone ID
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [createLoading, setCreateLoading] = useState(false);

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle task create
  const handleTaskCreate = async (e) => {
    e.preventDefault();
    setCreateLoading(true);

    // Validate name
    if (!values.name) {
      setCreateLoading(false);
      return ErrorNotification("Task name is required");
    } else if (!values.stage) {
      setCreateLoading(false);
      return ErrorNotification("Task stage is required");
    } else if (values.name.length > 80) {
      setCreateLoading(false);
      return ErrorNotification("Task name must be less than 80 characters");
    }

    // Create task
    createTask(id, mid, values)
      .then((res) => {
        setCreateLoading(false);
        SuccessNotification(`${res.data.name} added successfully`);
        setValues(initialState);
        dispatch(updateTasksBoardAction(mid));
        handleModalClick();
      })
      .catch((err) => {
        setCreateLoading(false);

        ErrorNotification(err.response.data.message);
        console.log("CREATE_TASK_ERROR", err);
      });
  };

  return (
    <>
      <Modal
        title={
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Create Task
          </h2>
        }
        visible={open}
        onOk={handleModalClick}
        width={600}
        centered
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleTaskCreate}
            loading={createLoading}
          >
            Create
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* Task name */}
          <Form.Item label="Name">
            <Input
              name="name"
              placeholder="Enter task name"
              onChange={handleFormChange}
              value={values.name}
            />
          </Form.Item>

          {/* Task stage */}
          <Form.Item label="Stage">
            <Select
              name="stage"
              placeholder="Select task stage"
              onChange={(value) => setValues({ ...values, stage: value })}
              value={values.stage}
            >
              <Select.Option value="To do">To Do</Select.Option>
              <Select.Option value="In Progress">In Progress</Select.Option>
              <Select.Option value="Review">Review</Select.Option>
              <Select.Option value="Done">Done</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTaskModal;
