import React, { useState } from "react";

import Chart from "react-apexcharts";
import { Card, Row, Col } from "antd";
import { useSelector } from "react-redux";

const TaskCard = () => {
  const { analytics } = useSelector((state) => state.dash);

  const [data] = useState({
    totalTasks: [
      {
        data: [0, 20, 10, 40, 30, 50],
      },
    ],
    todo: [
      {
        data: [0, 40, 20, 70, 10, 30],
      },
    ],
    inProgress: [
      {
        data: [0, 13, 20, 10, 30, 50],
      },
    ],
    review: [
      {
        data: [0, 20, 30, 22, 10, 15],
      },
    ],
    done: [
      {
        data: [0, 30, 10, 40, 20, 50],
      },
    ],

    options: {
      chart: {
        fontFamily: "Manrope, sans-serif",
        type: "line",
        id: "visiters-line-card",

        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#0063F7", "#1BE7FF"],
      labels: {
        style: {
          fontSize: "16px",
        },
      },
      stroke: {
        curve: "smooth",
        lineCap: "round",
      },

      tooltip: {
        enabled: false,
      },

      dataLabels: {
        enabled: false,
      },

      grid: {
        show: false,
      },

      markers: {
        strokeWidth: 0,
        size: 0,
        colors: ["#0063F7", "#1BE7FF"],
        hover: {
          sizeOffset: 1,
        },
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 426,
          options: {
            legend: {
              itemMargin: {
                horizontal: 16,
                vertical: 8,
              },
            },
          },
        },
      ],
      yaxis: {
        show: false,
      },
    },
  });

  return (
    <Card className="hp-border-color-black-40 hp-mb-32 hp-card-1">
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row justify="center" align="top" style={{ marginBottom: "-1.25em" }}>
            <h3 className="hp-mr-8">Tasks</h3>
          </Row>
        </Col>

        <Col span={24}>
          <Col>
            {/* Total Tasks */}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "-1em" }}
            >
              <Col span={8}>
                <p
                  className="hp-mb-0 hp-text-color-dark-0"
                  style={{
                    fontSize: "16px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  Total Tasks
                </p>

                <Row align="middle" justify="center">
                  <h3 className="hp-mb-0 hp-mr-4">
                    {analytics?.totalTasks ? analytics.totalTasks : 0}
                  </h3>
                </Row>
              </Col>

              <Col id="revenue-line-1" span={12}>
                <Chart
                  options={data.options}
                  series={data.totalTasks}
                  type="line"
                  legend="legend"
                />
              </Col>
            </Row>

            {/* To do */}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "-2em" }}
            >
              <Col span={8}>
                <p
                  className="hp-mb-0 hp-text-color-dark-0"
                  style={{
                    fontSize: "16px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  To Do
                </p>

                <Row align="middle" justify="center">
                  <h3 className="hp-mb-0 hp-mr-4">
                    {analytics?.todoTasks ? analytics.todoTasks : 0}
                  </h3>
                </Row>
              </Col>

              <Col id="revenue-line-1" span={12}>
                <Chart
                  options={data.options}
                  series={data.todo}
                  type="line"
                  legend="legend"
                />
              </Col>
            </Row>

            {/* In Progress */}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "-2em" }}
            >
              <Col span={8}>
                <p
                  className="hp-mb-0 hp-text-color-dark-0"
                  style={{
                    fontSize: "16px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  In Progress
                </p>

                <Row align="middle" justify="center">
                  <h3 className="hp-mb-0 hp-mr-4">
                    {analytics?.inProgressTasks ? analytics.inProgressTasks : 0}
                  </h3>
                </Row>
              </Col>

              <Col id="revenue-line-1" span={12}>
                <Chart
                  options={data.options}
                  series={data.inProgress}
                  type="line"
                  legend="legend"
                />
              </Col>
            </Row>

            {/* Review */}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "-2em" }}
            >
              <Col span={8}>
                <p
                  className="hp-mb-0 hp-text-color-dark-0"
                  style={{
                    fontSize: "16px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  Review
                </p>

                <Row align="middle" justify="center">
                  <h3 className="hp-mb-0 hp-mr-4">
                    {analytics?.reviewTasks ? analytics.reviewTasks : 0}
                  </h3>
                </Row>
              </Col>

              <Col id="revenue-line-1" span={12}>
                <Chart
                  options={data.options}
                  series={data.review}
                  type="line"
                  legend="legend"
                />
              </Col>
            </Row>

            {/* Done */}
            <Row justify="space-between" align="middle">
              <Col span={8}>
                <p
                  className="hp-mb-0 hp-text-color-dark-0"
                  style={{
                    fontSize: "16px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  Done
                </p>

                <Row align="middle" justify="center">
                  <h3 className="hp-mb-0 hp-mr-4">
                    {analytics?.doneTasks ? analytics.doneTasks : 0}
                  </h3>
                </Row>
              </Col>

              <Col id="revenue-line-1" span={12}>
                <Chart
                  options={data.options}
                  series={data.done}
                  type="line"
                  legend="legend"
                />
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

export default TaskCard;
