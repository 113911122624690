import React from "react";

const SuperAdminDashboard = () => {
  return (
    <>
      <h3>Superadmin Dashboard</h3>
    </>
  );
};

export default SuperAdminDashboard;
