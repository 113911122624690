import React from "react";

import { Card, Row, Col, Table, Tag, Button, Dropdown, Menu } from "antd";
import {
  RiMoreFill,
  RiAddFill,
  RiEyeFill,
  RiEdit2Fill,
  RiDeleteBin6Fill,
} from "react-icons/ri";
import { BiTask } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

const MilestoneListTableCard = ({
  milestones,
  handleMilestoneCreate,
  handleMilestoneView,
  handleMilestoneUpdate,
  handleMilestoneDelete,
}) => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();

  // Handle task view
  const handleTasksView = (mid) => {
    if (user?.role === "admin") {
      history.push(`/admin/projects/view/${id}/milestone/${mid}/task/kanban`);
    } else if (user?.role === "manager") {
      history.push(`/manager/projects/view/${id}/milestone/${mid}/task/kanban`);
    } else if (user?.role === "member") {
      history.push(`/member/projects/view/${id}/milestone/${mid}/task/kanban`);
    } else if (user?.role === "guest") {
      history.push(`/guest/projects/view/${id}/milestone/${mid}/task/kanban`);
    }
  };

  const columns = [
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          #
        </span>
      ),
      dataIndex: "no",
      key: "no",
      render: (text) => (
        <p className="hp-mb-0 hp-font-weight-500 hp-text-color-black-100 hp-text-color-dark-0">
          {text}
        </p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          Name
        </span>
      ),
      dataIndex: "milestoneName",
      key: "milestoneName",
      render: (text) => (
        <p className="hp-mb-0 hp-text-color-black-100 hp-text-color-dark-30">
          {text}
        </p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          Status
        </span>
      ),
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          color={
            status === "Completed"
              ? "green"
              : status === "In Progress"
              ? "blue"
              : status === "On Hold"
              ? "magenta"
              : "red"
          }
          key={status}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          Start Date
        </span>
      ),
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => (
        <p className="hp-mb-0 hp-text-color-black-100 hp-text-color-dark-30">
          {text}
        </p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          End Date
        </span>
      ),
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => (
        <p className="hp-mb-0 hp-text-color-black-100 hp-text-color-dark-30">
          {text}
        </p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          Actions
        </span>
      ),
      key: "action",
      render: (text, record) => (
        <span className="hp-text-color-black-100 hp-text-color-dark-30">
          {
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu style={{ width: "150px" }}>
                  <Menu.Item
                    key={0}
                    onClick={() => handleMilestoneView(record.key)}
                    icon={
                      <RiEyeFill size={16} style={{ marginRight: "14px" }} />
                    }
                  >
                    View
                  </Menu.Item>

                  {/* Open Tasks */}
                  <Menu.Item
                    key={1}
                    onClick={() => handleTasksView(record.key)}
                    icon={<BiTask size={16} style={{ marginRight: "14px" }} />}
                  >
                    Show Tasks
                  </Menu.Item>

                  {(user?.role === "admin" || user?.role === "manager") && (
                    <>
                      <Menu.Item
                        key={2}
                        onClick={() => handleMilestoneUpdate(record.key)}
                        icon={
                          <RiEdit2Fill
                            size={16}
                            style={{ marginRight: "14px" }}
                          />
                        }
                      >
                        Edit
                      </Menu.Item>

                      <Menu.Item
                        key={3}
                        onClick={() => handleMilestoneDelete(record.key)}
                        icon={
                          <RiDeleteBin6Fill
                            size={16}
                            style={{ marginRight: "14px" }}
                          />
                        }
                      >
                        Delete
                      </Menu.Item>
                    </>
                  )}
                </Menu>
              }
            >
              <Button
                size="small"
                style={{
                  border: "none",
                }}
              >
                <RiMoreFill className="remix-icon" size={24} />
              </Button>
            </Dropdown>
          }
        </span>
      ),
    },
  ];

  const data = milestones?.map((milestone, index) => {
    return {
      key: milestone._id,
      no: index + 1,
      milestoneName: milestone.name,
      status: milestone.status,
      startDate: new Date(milestone.startDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      endDate: new Date(milestone.endDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
    };
  });

  return (
    <Col
      span={24}
      style={{
        marginRight: "1em",
      }}
    >
      <Card className="hp-border-color-black-40 hp-mb-32 hp-card-1 hp-project-table-card">
        <Row>
          <Col span={24}>
            <Row justify="space-between">
              <h3 className="hp-mb-16 hp-font-weight-400">Milestones</h3>

              {/* Plus Icon Button to Create a Milestone */}
              {(user?.role === "admin" || user?.role === "manager") && (
                <Button
                  type="primary"
                  size="small"
                  onClick={handleMilestoneCreate}
                  className="hp-mb-16"
                >
                  <RiAddFill className="remix-icon" size={16} />
                  <span className="hp-ml-8">Add Milestone</span>
                </Button>
              )}
            </Row>

            <Table
              style={{
                overflowY: "auto",
                height: "calc(85vh - 300px)",
              }}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{ x: 700 }}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default MilestoneListTableCard;
