export const adminSidebarSteps = [
  // Intro
  {
    target: ".hp-logo",
    content: (
      <div>
        <h1>Welcome to TaskyPhi</h1>
        <h3>Let's take a quick tour of the core features of the app</h3>

        <br />
        {/* Will take 30 seconds */}
        <p>
          Tour duration: <strong>30 seconds</strong>
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "center",
    styles: {
      options: {
        width: 900,
        zIndex: 1000,
      },
    },
  },
  // Dashboard
  {
    target: ".joy-dash-admin",
    content: (
      <div>
        <h3>Dashboard</h3>
        <p>This is where you can view of all your statistics at a glance</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Projects
  {
    target: ".joy-projects-admin",
    content: (
      <div>
        <h3>Projects</h3>
        <p>Create, view and manage your projects here</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Calendar
  {
    target: ".joy-calendar-admin",
    content: (
      <div>
        <h3>Calendar</h3>
        <p>Schedule and track all your day-to-day events here</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Todos
  {
    target: ".joy-todos-admin",
    content: (
      <div>
        <h3>Todo's</h3>
        <p>
          A personal to do list for you to keep track of your day-to-day tasks
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Chat
  {
    target: ".joy-chat-admin",
    content: (
      <div>
        <h3>My Chat</h3>
        <p>Chat with your team members & collaborate in real-time</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Team
  {
    target: ".joy-team-admin",
    content: (
      <div>
        <h3>My Team</h3>
        <p>Invite and manage your team members here</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
        // remove button outline
        outline: "none",
      },
    },
  },
  // Prompt admin to click on the dashboard
  {
    target: ".joy-projects-admin",
    content: (
      <div>
        <p>Great! let's create your first project 🤝</p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    // Rename "next" to "finish"
    locale: {
      last: "Finish",
    },
  },
];

export const managerSidebarSteps = [
  // Intro
  {
    target: ".hp-logo",
    content: (
      <div>
        <h1>Welcome to TaskyPhi</h1>
        <h3>Let's take a quick tour of the core features of the app</h3>

        <br />
        {/* Will take 30 seconds */}
        <p>
          Tour duration: <strong>30 seconds</strong>
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "center",
    styles: {
      options: {
        width: 900,
        zIndex: 1000,
      },
    },
  },
  // Dashboard
  {
    target: ".joy-dash-manager",
    content: (
      <div>
        <h3>Dashboard</h3>
        <p>This is where you can view of all your statistics at a glance</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Projects
  {
    target: ".joy-projects-manager",
    content: (
      <div>
        <h3>Projects</h3>
        <p>Create, view and manage your projects here</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Calendar
  {
    target: ".joy-calendar-manager",
    content: (
      <div>
        <h3>Calendar</h3>
        <p>Schedule and track all your day-to-day events here</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Todos
  {
    target: ".joy-todos-manager",
    content: (
      <div>
        <h3>Todo's</h3>
        <p>
          A personal to do list for you to keep track of your day-to-day tasks
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Chat
  {
    target: ".joy-chat-manager",
    content: (
      <div>
        <h3>My Chat</h3>
        <p>Chat with your team members & collaborate in real-time</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Team
  {
    target: ".joy-team-manager",
    content: (
      <div>
        <h3>My Team</h3>
        <p>Invite and manage your team members here</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
        // remove button outline
        outline: "none",
      },
    },
  },
  // Prompt admin to click on the dashboard
  {
    target: ".joy-projects-manager",
    content: (
      <div>
        <p>Great! let's view/create your first project 🤝</p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    // Rename "next" to "finish"
    locale: {
      last: "Finish",
    },
  },
];

export const memberSidebarSteps = [
  // Intro
  {
    target: ".hp-logo",
    content: (
      <div>
        <h1>Welcome to TaskyPhi</h1>
        <h3>Let's take a quick tour of the core features of the app</h3>

        <br />
        {/* Will take 30 seconds */}
        <p>
          Tour duration: <strong>30 seconds</strong>
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "center",
    styles: {
      options: {
        width: 900,
        zIndex: 1000,
      },
    },
  },
  // Dashboard
  {
    target: ".joy-dash-member",
    content: (
      <div>
        <h3>Dashboard</h3>
        <p>This is where you can view of all your statistics at a glance</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Projects
  {
    target: ".joy-projects-member",
    content: (
      <div>
        <h3>Projects</h3>
        <p>View and manage your projects here</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Calendar
  {
    target: ".joy-calendar-member",
    content: (
      <div>
        <h3>Calendar</h3>
        <p>Schedule and track all your day-to-day events here</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Todos
  {
    target: ".joy-todos-member",
    content: (
      <div>
        <h3>Todo's</h3>
        <p>
          A personal to do list for you to keep track of your day-to-day tasks
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Chat
  {
    target: ".joy-chat-member",
    content: (
      <div>
        <h3>My Chat</h3>
        <p>Chat with your team members & collaborate in real-time</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },

  // Prompt admin to click on the dashboard
  {
    target: ".joy-projects-member",
    content: (
      <div>
        <p>Great! let's view the projects you're assigned 🤝</p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    // Rename "next" to "finish"
    locale: {
      last: "Finish",
    },
  },
];

export const guestSidebarSteps = [
  // Intro
  {
    target: ".hp-logo",
    content: (
      <div>
        <h1>Welcome to TaskyPhi</h1>
        <h3>Let's take a quick tour of the core features of the app</h3>

        <br />
        {/* Will take 30 seconds */}
        <p>
          Tour duration: <strong>30 seconds</strong>
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "center",
    styles: {
      options: {
        width: 900,
        zIndex: 1000,
      },
    },
  },
  // Dashboard
  {
    target: ".joy-dash-guest",
    content: (
      <div>
        <h3>Dashboard</h3>
        <p>This is where you can view of all your statistics at a glance</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Projects
  {
    target: ".joy-projects-guest",
    content: (
      <div>
        <h3>Projects</h3>
        <p>View and manage your projects here</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Calendar
  {
    target: ".joy-calendar-guest",
    content: (
      <div>
        <h3>Calendar</h3>
        <p>Schedule and track all your day-to-day events here</p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },
  // Todos
  {
    target: ".joy-todos-guest",
    content: (
      <div>
        <h3>Todo's</h3>
        <p>
          A personal to do list for you to keep track of your day-to-day tasks
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
  },

  // Prompt admin to click on the dashboard
  {
    target: ".joy-projects-guest",
    content: (
      <div>
        <p>Great! let's view the projects you're assigned 🤝</p>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
    styles: {
      options: {
        width: 400,
        zIndex: 1000,
      },
    },
    // Rename "next" to "finish"
    locale: {
      last: "Finish",
    },
  },
];
