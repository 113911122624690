import React, { useState } from "react";
import { Modal, Button, Form, Input } from "antd";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { createGroupChat } from "../../../api/chat";
import { SuccessNotification, ErrorNotification } from "../../../components";

const initialState = {
  groupName: "",
  users: [],
};

const GroupChatCreateModal = ({
  open,
  handleModalClick,
  members,
  fetchChats,
  handleSelectedChat,
}) => {
  const [values, setValues] = useState(initialState);

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle group create
  const handleGroupCreate = async (e) => {
    e.preventDefault();
    // Filter members array to get only the user id
    const membersId = values.users.map((member) => member._id);
    values.users = membersId;

    // Create group chat
    createGroupChat(values)
      .then((res) => {
        // Fetch all chats
        fetchChats();
        // Set selected chat
        handleSelectedChat(res.data);
        // Close modal
        handleModalClick();
        setValues(initialState);
        SuccessNotification(`Group ${res.data.chatName} created successfully`);
      })
      .catch((err) => {
        ErrorNotification(err.response?.data.message);
      });
  };

  return (
    <>
      <Modal
        title="Create Group Chat"
        visible={open}
        onCancel={handleModalClick}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleGroupCreate}
            // Disabled if no members selected is less than or equal to 2
            disabled={values.users.length < 2}
          >
            Create
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* Group name */}
          <Form.Item label="Group Name">
            <Input
              name="groupName"
              placeholder="Enter group name"
              onChange={handleFormChange}
              value={values.groupName}
            />
          </Form.Item>

          {/* Group members */}
          <Form.Item label="Members">
            <Autocomplete
              multiple
              id="tags-outlined"
              options={members}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={(event, member) => {
                setValues({
                  ...values,
                  users: member,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Add members"
                />
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default GroupChatCreateModal;
