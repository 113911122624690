import React, { useState, useEffect } from "react";
import Avatar from "./Avatar";
import "./chatListItem.css";

const ChatListItem = ({ currentUser, chat, selected }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const otherMember = chat.users.find(
      (member) => member._id !== currentUser._id
    );

    setUser(otherMember);
  }, [chat, currentUser._id]);

  return (
    <div className={`chatlist__item`}>
      <Avatar image={user?.picture} />

      <div className="userMeta">
        <p>{chat?.isGroupChat ? chat.chatName + " (G)" : user?.name}</p>
        <span className="activeTime">32 mins ago</span>
      </div>
    </div>
  );
};

export default ChatListItem;
