import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form, Input } from "antd";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getChatById } from "../../../api/chat";
import { ErrorNotification } from "../../../components";

// Initial state
const initialValues = {
  groupName: "",
  users: [],
};

const GroupChatViewModal = ({
  open,
  handleModalClick,
  members,
  groupChatId,
  currentUser,
}) => {
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  // Fetch group chat by id
  const fetchGroupChatById = useCallback(async () => {
    setLoading(true);
    getChatById(groupChatId)
      .then((res) => {
        // Remove current user from users array
        const users = res.data.users.filter(
          (user) => user._id !== currentUser._id
        );

        // Extract only name, email and _id from users array
        const modUsers = users.map((user) => ({
          name: user.name,
          email: user.email,
          _id: user._id,
        }));

        setValues({
          groupName: res.data.chatName,
          users: modUsers,
        });

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        ErrorNotification(err.response?.data.message);
      });
  }, [groupChatId, currentUser]);

  // Fetch group chat on mount
  useEffect(() => {
    if (open) {
      fetchGroupChatById();
    }
  }, [fetchGroupChatById, open]);

  return (
    !loading && (
      <>
        <Modal
          title="Group Chat"
          visible={open}
          onCancel={handleModalClick}
          footer={[
            <Button key="back" onClick={handleModalClick}>
              Close
            </Button>,
          ]}
        >
          <Form
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            {/* Group name */}
            <Form.Item label="Group Name">
              <Input
                name="groupName"
                value={values.groupName ? values.groupName : ""}
                placeholder="Group name"
                disabled={true}
              />
            </Form.Item>

            {/* Group members */}
            <Form.Item label="Members">
              <Autocomplete
                multiple
                id="tags-outlined"
                options={members.filter(
                  (member) =>
                    !values.users?.some((user) => user._id === member._id)
                )}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option._id === value._id}
                filterSelectedOptions
                value={values.users ? values.users : []}
                disabled={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Add members"
                  />
                )}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  );
};

export default GroupChatViewModal;
