import React from "react";
import { Card, Row, Col, Progress } from "antd";

const CompletedMilestonesCard = ({ completedMilestones, totalMilestones }) => {
  const completedMilestonePercentage = isNaN(
    (completedMilestones?.length / totalMilestones) * 100
  )
    ? 0
    : (completedMilestones?.length / totalMilestones) * 100;

  return (
    <Card
      className="hp-border-color-black-40 hp-mb-16 hp-card-1"
      style={{
        marginRight: "1em",
      }}
    >
      <Row gutter={16} align="middle">
        <Col className="hp-pl-0" span={8}>
          <div id="chart">
            <Progress
              type="circle"
              percent={Math.round(completedMilestonePercentage)}
              format={(percent) => `${percent} %`}
              width={72}
              strokeColor="#00F7BF"
            />
          </div>
        </Col>

        <Col className="hp-pr-0" span={14}>
          <h3 className="hp-mb-0">{completedMilestones?.length}</h3>

          <p className="hp-p1-body hp-mb-0">Completed Milestones</p>
        </Col>
      </Row>
    </Card>
  );
};

export default CompletedMilestonesCard;
