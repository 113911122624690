import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Divider, Avatar, Row, Col } from "antd";
import { RiSettings3Line } from "react-icons/ri";

const MenuFooter = (props) => {
  const { user } = useSelector((state) => state.auth);

  return props.collapsed === false ? (
    <Row
      className="hp-sidebar-footer hp-bg-color-dark-90"
      align="middle"
      justify="space-between"
    >
      <Divider className="hp-border-color-black-20 hp-border-color-dark-70 hp-mt-0" />

      <Col>
        <Row align="middle">
          <Avatar
            className="hp-mr-8"
            size={48}
            style={
              props.mobileMenu
                ? {
                    marginLeft: "8px",
                    marginRight: "8px",
                    fontSize: "1rem",
                    backgroundColor: "#B4DAFD",
                  }
                : {
                    marginLeft: "-15px",
                    marginRight: "8px",
                    fontSize: "1.1rem",
                    backgroundColor: "#B4DAFD",
                  }
            }
          >
            {user?.name?.charAt(0).toUpperCase()}{" "}
            {user?.name?.charAt(user.name.length - 1).toUpperCase()}
          </Avatar>

          <div className="hp-mt-6">
            <span className="hp-d-block hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body">
              {user?.name}
            </span>

            <Link
              to="/profile/information"
              className="hp-badge-text hp-text-color-dark-30 hp-font-weight-400"
              onClick={props.onClose}
            >
              View Profile
            </Link>
          </div>
        </Row>
      </Col>

      <Col>
        <Link to="/profile/security" onClick={props.onClose}>
          <RiSettings3Line
            className="remix-icon hp-text-color-black-100 hp-text-color-dark-0"
            size={24}
            style={props.mobileMenu ? { marginRight: "12px" } : null}
          />
        </Link>
      </Col>
    </Row>
  ) : (
    <Row
      className="hp-sidebar-footer hp-bg-color-dark-90"
      align="middle"
      justify="center"
    >
      <Col>
        <Link to={`/profile/information`} onClick={props.onClose}>
          <Avatar
            size={48}
            style={{
              cursor: "pointer",
              backgroundColor: "#B4DAFD",
            }}
          >
            {user?.name?.charAt(0).toUpperCase()}{" "}
            {user?.name?.charAt(user?.name.length - 1).toUpperCase()}
          </Avatar>
        </Link>
      </Col>
    </Row>
  );
};

export default MenuFooter;
