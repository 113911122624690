import React, { useState, useEffect } from "react";
import { Tag, Row, Col, Button } from "antd";
import {
  AllTasksFilter,
  ParentTable,
  ParentTableHead,
} from "../../../../components";
import { getAdminAllProjectsTasks } from "../../../../api/admin";
import { getManagerAllProjectsTasks } from "../../../../api/manager";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const AllProjectsTasks = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const [tasks, setTasks] = useState([]);
  const [tasksCopy, setTasksCopy] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  // Filter States
  const [projectNames, setProjectNames] = useState([]);
  const [memberNames, setMemberNames] = useState([]);
  // Set Filter States
  const [filteredProject, setFilteredProject] = useState("");
  const [filteredMember, setFilteredMember] = useState("");
  const [filteredDaysLeft, setFilteredDaysLeft] = useState("");
  const [filteredStage, setFilteredStage] = useState("");
  const [filteredPriority, setFilteredPriority] = useState("");

  // Fetch all tasks of all projects for the admin
  useEffect(() => {
    fetchAllTasks();
  }, []);

  // Handle fetch all tasks of projects for the admin
  const fetchAllTasks = () => {
    setLoading(true);

    // If user is admin
    if (user.role === "admin") {
      getAdminAllProjectsTasks().then((res) => {
        setTasks(res.data);
        setTasksCopy(res.data);

        // Filter unique project names and set to state
        const projectNames = [
          ...new Set(res.data.map((task) => task.projectName)),
        ];
        setProjectNames(projectNames);

        // From each task extract assignedTo array
        const assignedTo = [res.data.map((task) => task.assignedTo).flat()];
        // For each member in the assignedTo array, get unique assignedTo.name
        const memberNames = [
          ...new Set(assignedTo.flat().map((member) => member.name)),
        ];
        setMemberNames(memberNames);
        setLoading(false);
      });
    } else if (user.role === "manager") {
      getManagerAllProjectsTasks().then((res) => {
        setTasks(res.data);
        setTasksCopy(res.data);

        // Filter unique project names and set to state
        const projectNames = [
          ...new Set(res.data.map((task) => task.projectName)),
        ];
        setProjectNames(projectNames);

        // From each task extract assignedTo array
        const assignedTo = [res.data.map((task) => task.assignedTo).flat()];
        // For each member in the assignedTo array, get unique assignedTo.name
        const memberNames = [
          ...new Set(assignedTo.flat().map((member) => member.name)),
        ];
        setMemberNames(memberNames);
        setLoading(false);
      });
    }
  };

  // Handle Show/Hide Filter Drawer
  const handleDrawer = () => {
    setVisible(!visible);
  };

  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Filtered tasks
  const filteredTasks = (project, member, daysLeft, stage, priority) => {
    if (
      project === "" &&
      member === "" &&
      daysLeft === "" &&
      stage === "" &&
      priority === ""
    ) {
      return tasksCopy;
    } else if (project !== "") {
      return tasks.filter((task) => task.projectName === project);
    } else if (member !== "") {
      return tasks.filter((task) =>
        task.assignedTo.map((member) => member.name).includes(member)
      );
    } else if (daysLeft !== "") {
      return tasks.filter((task) => task.daysLeft <= filteredDaysLeft);
    } else if (stage !== "") {
      return tasks.filter((task) => task.stage === stage);
    } else if (priority !== "") {
      return tasks.filter((task) => task.priority === priority);
    }
  };

  return (
    <>
      {/* Filter Drawer */}
      <AllTasksFilter
        onClose={handleDrawer}
        visible={visible}
        // Filter Lists
        projectNames={projectNames}
        memberNames={memberNames}
        // Set Filter States
        filteredProject={filteredProject}
        setFilteredProject={setFilteredProject}
        filteredMember={filteredMember}
        setFilteredMember={setFilteredMember}
        filteredDaysLeft={filteredDaysLeft}
        setFilteredDaysLeft={setFilteredDaysLeft}
        filteredStage={filteredStage}
        setFilteredStage={setFilteredStage}
        filteredPriority={filteredPriority}
        setFilteredPriority={setFilteredPriority}
      />

      <Row
        style={{
          marginBottom: "20px",
        }}
      >
        {/* Header Buttons */}
        <Col span={24} xs={8} sm={16} md={24} lg={32}>
          <Row justify="space-between">
            {/* Heading */}
            <Col span={17}>
              <h3
                style={{
                  marginBottom: "0.5rem",
                  transform: "translateX(1rem)",
                }}
              >
                All Tasks
              </h3>
            </Col>

            {/* Open Filters */}
            <Col
              span={4}
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                onClick={handleDrawer}
                style={{
                  backgroundColor: "#d6e5fe",
                  marginRight: "-2rem",
                  color: "#2e67f8",
                }}
              >
                Open Filters
              </Button>
            </Col>

            {/* Go Back Button */}
            <Col
              span={3}
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                style={{
                  marginRight: "15px",
                }}
                onClick={() => history.goBack()}
              >
                Go Back
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {loading ? (
        <div
          className="clock-loader"
          style={{ top: "calc(40vh - 100px)" }}
        ></div>
      ) : (
        <>
          {/* All Tasks Table */}
          <Row>
            <Col
              md={24}
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                height: "calc(100vh - 200px)",
                backgroundColor: "white",
              }}
            >
              <>
                <ParentTable>
                  <ParentTableHead>
                    <tr>
                      <th scope="col">Project</th>
                      <th scope="col">Milestone</th>
                      <th scope="col">Task</th>
                      <th scope="col">Stage</th>
                      <th scope="col">Priority</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Days Left</th>
                      <th scope="col">Assigned To</th>
                    </tr>
                  </ParentTableHead>
                  <tbody>
                    {tasks?.length > 0 ? (
                      filteredTasks(
                        filteredProject,
                        filteredMember,
                        filteredDaysLeft,
                        filteredStage,
                        filteredPriority
                      ).map((task) => (
                        <tr key={task?._id} style={{ cursor: "pointer" }}>
                          {/* Project Name */}
                          <td>
                            <h5
                              style={{
                                color: "#0070f3",
                                fontSize: "0.9rem",
                              }}
                            >
                              {task?.projectName}
                            </h5>
                          </td>

                          {/* Milestone Name */}
                          <td>
                            <h5
                              style={{
                                color: "#0070f3",
                                fontSize: "0.9rem",
                              }}
                            >
                              {task?.milestoneName}
                            </h5>
                          </td>

                          {/* Task Name */}
                          <td>
                            <h5
                              style={{
                                color: "#0070f3",
                                fontSize: "0.9rem",
                              }}
                            >
                              {task?.name}
                            </h5>
                          </td>
                          <td>
                            <Tag
                              color={
                                task?.stage === "Done"
                                  ? "#389e0d"
                                  : task?.stage === "In Progress"
                                  ? "blue"
                                  : task?.stage === "Review"
                                  ? "yellow"
                                  : "volcano"
                              }
                            >
                              {task?.stage}
                            </Tag>
                          </td>
                          <td>
                            <Tag
                              color={
                                task?.priority === "Critical"
                                  ? "red"
                                  : task?.priority === "High"
                                  ? "orange"
                                  : task?.priority === "Medium"
                                  ? "yellow"
                                  : "#389e0d"
                              }
                            >
                              {task?.priority}
                            </Tag>
                          </td>
                          <td>
                            {task?.startDate
                              ? new Date(task?.startDate).toLocaleDateString(
                                  "en-US",
                                  dateFormat
                                )
                              : "Not Set"}
                          </td>
                          <td>
                            {task?.endDate
                              ? new Date(task?.endDate).toLocaleDateString(
                                  "en-US",
                                  dateFormat
                                )
                              : "Not Set"}
                          </td>
                          <td>{task?.daysLeft}</td>
                          <td>
                            {task?.assignedTo
                              .map((assignedTo) => assignedTo.name)
                              .join(", ")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <h2
                        style={{
                          position: "absolute",
                          top: "40%",
                          left: "42%",
                        }}
                      >
                        No tasks found
                      </h2>
                    )}
                  </tbody>
                </ParentTable>
              </>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default AllProjectsTasks;
