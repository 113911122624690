import React, { useState } from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { useDispatch } from "react-redux";
import { createTodo } from "../../../api/user";
import { updateTodosBoardAction } from "../../../redux/actions/userActions";
import { SuccessNotification, ErrorNotification } from "../..";

const initialState = {
  name: "",
  stage: "",
};

const CreateTodoModal = ({ open, handleModalClick }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [createLoading, setCreateLoading] = useState(false);

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle todo create
  const handleTodoCreate = async (e) => {
    e.preventDefault();

    // Validate name
    if (!values.name) {
      setCreateLoading(false);
      return ErrorNotification("Todo name is required");
    } else if (!values.stage) {
      setCreateLoading(false);
      return ErrorNotification("Todo stage is required");
    } else if (values.name.length > 80) {
      setCreateLoading(false);
      return ErrorNotification("Todo name must be less than 80 characters");
    }

    // Create todo
    createTodo(values)
      .then((res) => {
        setCreateLoading(false);
        SuccessNotification(`${res.data.name} added successfully`);
        setValues(initialState);
        dispatch(updateTodosBoardAction());
        handleModalClick();
      })
      .catch((err) => {
        setCreateLoading(false);

        ErrorNotification(err.response.data.message);
        console.log("CREATE_TODO_ERROR", err);
      });
  };

  return (
    <>
      <Modal
        title={
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Create Todo
          </h2>
        }
        visible={open}
        onOk={handleModalClick}
        width={600}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={createLoading}
            onClick={handleTodoCreate}
          >
            Create
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* Todo name */}
          <Form.Item label="Name">
            <Input
              name="name"
              placeholder="Enter todo name"
              onChange={handleFormChange}
              value={values.name}
              rules={[{ required: true }]}
            />
          </Form.Item>

          {/* Todo stage */}
          <Form.Item label="Stage">
            <Select
              name="stage"
              placeholder="Select Todo stage"
              onChange={(value) => setValues({ ...values, stage: value })}
              value={values.stage}
              required={true}
            >
              <Select.Option value="To do">To Do</Select.Option>
              <Select.Option value="In Progress">In Progress</Select.Option>
              <Select.Option value="Review">Review</Select.Option>
              <Select.Option value="Done">Done</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTodoModal;
