import React, { useState, useEffect, useRef } from "react";
import {
  ChatListItem,
  Message,
  ChatOnline,
  SearchUser,
  GroupChatCreateModal,
  GroupChatViewModal,
  GroupChatUpdateModal,
  InfoNotification,
} from "../../../components";
import { Layout, Row, Col, Card, Button } from "antd";
import { Plus, Send, Show } from "react-iconly";
import { EditOutlined } from "@material-ui/icons";
import { getChats } from "../../../api/chat";
import { getTeamMembers } from "../../../api/admin";
import { getManagerTeam } from "../../../api/manager";
import { createMessage, getMessages } from "../../../api/message";
import { useSelector, useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { newMessageNotificationAction } from "../../../redux/actions/chatActions";
import "./adminChat.css";
// Joyride
import Joyride, { STATUS } from "react-joyride";
import { updateAdminTutStatusAction } from "../../../redux/actions/adminActions";
import { adminChatSteps } from "../../../helpers/joyride/adminChatSteps";

const { Content } = Layout;

const AdminChat = () => {
  const socket = useRef();
  const scrollRef = useRef();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [createGroupModalOpen, setCreateGroupModalOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [updateGroupModalOpen, setUpdateGroupModalOpen] = useState(false);
  const [viewGroupModalOpen, setViewGroupModalOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [socketConnected, setSocketConnected] = useState(false);
  let selectedChatCompare;
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const { notifications } = useSelector((state) => state.chat);

  const [onlineUsers, setOnlineUsers] = useState([]);

  // Joyride
  const [tutFinished, setTutFinished] = useState(
    JSON.parse(localStorage.getItem("joyride"))?.chat
  );

  // Socket.io connection
  useEffect(() => {
    // Connect to socket server just once (current is the useRef reference)
    socket.current = io(`${process.env.REACT_APP_BACKEND_SOCKET}`);

    // Emit user data to setup event
    socket.current.emit("setup", user);
    socket.current.on("connected", () => {
      setSocketConnected(true);
    });

    // Typing
    socket.current.on("typing", () => setIsTyping(true));
    socket.current.on("stop typing", () => setIsTyping(false));
  }, [user]);

  useEffect(() => {
    socket.current.on("message received", (newMessageReceived) => {
      // Send notifications only if the chat is not selected
      if (selectedChatCompare?._id !== newMessageReceived.chat._id) {
        // Add new message to notification
        if (!notifications?.includes(newMessageReceived)) {
          // console.log("newMessageReceived", newMessageReceived);
          // console.log("notifications", selectedChatCompare);
          // // Dispatch new message notification action with the updated redux notifications
          // dispatch(newMessageNotificationAction([newMessageReceived]));
        }
      } else {
        setMessages((messages) => [...messages, newMessageReceived]);
      }
    });
  });

  // Get all chats of current user
  const fetchChats = async () => {
    setLoading(true);
    getChats()
      .then((res) => {
        setChats(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("GET_CHATS_ERROR", err);
      });
  };

  // Get all team members
  const fetchTeamMembers = async () => {
    setLoading(true);

    // If admin
    if (user?.role === "admin") {
      getTeamMembers()
        .then((res) => {
          // Extract the name, email & _id from the response
          const members = res.data.members.map((member) => {
            return {
              name: member.name,
              email: member.email,
              _id: member.user,
            };
          });

          // Extract the name, email & _id from the response
          const managers = res.data.managers.map((manager) => {
            return {
              name: manager.name,
              email: manager.email,
              _id: manager.user,
            };
          });

          setMembers([...members, ...managers]);
          setLoading(false);
        })
        .catch((err) => {
          console.log("GET_TEAM_MEMBERS_ERROR", err);
        });
    } else if (user?.role === "manager") {
      getManagerTeam()
        .then((res) => {
          // Extract the name, email & _id from the response
          const members = res.data.members.map((member) => {
            return {
              name: member.name,
              email: member.email,
              _id: member.user,
            };
          });
          setMembers(members);
          setLoading(false);
        })
        .catch((err) => {
          console.log("GET_MANAGER_TEAM_MEMBERS_ERROR", err);
        });
    }
  };

  useEffect(() => {
    fetchChats();
  }, [selectedChat]);

  // Auto scrolling on new messages
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    // Get all team members
    fetchTeamMembers();
  }, []);

  // Handle Create Group Chat
  const handleCreateGroupClick = () => {
    // Check if number of members is greater than 2, then allow to create group
    if (members.length < 2) {
      InfoNotification("Need min 2 team members to create a group chat");
    } else {
      setCreateGroupModalOpen(!createGroupModalOpen);
    }
  };

  // Handle Update Group Chat
  const handleUpdateGroupClick = () => {
    setUpdateGroupModalOpen(!updateGroupModalOpen);
    // Get all admin team members
    fetchTeamMembers();
  };

  // Handle Update Group Chat
  const handleViewGroupClick = () => {
    setViewGroupModalOpen(!viewGroupModalOpen);
  };

  // Typing handler
  const typingHandler = (e) => {
    setNewMessage(e.target.value);

    // Typing indicator
    if (!socketConnected) {
      return;
    }

    if (!typing) {
      setTyping(true);
      socket.current.emit("typing", selectedChat._id);
    }

    let lastTypingTime = new Date().getTime();
    var timerLength = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTypingTime;

      if (timeDiff >= timerLength && typing) {
        socket.current.emit("stop typing", selectedChat._id);
        setTyping(false);
      }
    }, timerLength);
  };

  // Handle selected chat
  const handleSelectedChat = (chat) => {
    setSelectedChat(chat);
    selectedChatCompare = chat;

    // Emit "join chat" event
    socket.current.emit("join chat", chat._id);

    // Get all messages for the selected chat
    getMessages(chat._id)
      .then((res) => {
        setMessages(res.data);
      })
      .catch((err) => {
        console.log("GET_MESSAGES_ERROR", err);
      });
  };

  // Handle Send Message
  const handleSendMessage = (e, chatId) => {
    e.preventDefault();

    // Emit "stop typing" event
    socket.current.emit("stop typing", selectedChat._id);

    if (newMessage.trim() !== "") {
      const message = {
        sender: user._id,
        text: newMessage,
        chatId: chatId,
      };
      createMessage(message)
        .then((res) => {
          // Add the message to the messages array
          setMessages([...messages, res.data]);
          // Clear the input field
          setNewMessage("");

          // Emit "message sent" event
          socket.current.emit("new message", res.data);
        })
        .catch((err) => {
          console.log("CREATE_MESSAGE_ERROR", err);
        });
    }
  };

  // Handle joyride finish
  const handleJoyrideCallback = (data) => {
    if (data.status === STATUS.FINISHED) {
      setTutFinished(true);
      const pageToUpdate = {
        chat: true,
      };

      // If user is admin
      if (user?.role === "admin") {
        dispatch(updateAdminTutStatusAction(pageToUpdate));
      }

      // Get the joyride object from local storage
      const joyrideObj = JSON.parse(localStorage.getItem("joyride"));

      // Update the joyride object
      joyrideObj.chat = true;

      // Set the joyride object to local storage
      localStorage.setItem("joyride", JSON.stringify(joyrideObj));
    }
  };

  return (
    <>
      {/* <Joyride
        steps={adminChatSteps}
        run={!tutFinished}
        continuous
        showProgress={true}
        showSkipButton={false}
        // Run only once
        callback={(data) => {
          handleJoyrideCallback(data);
        }}
        disableScrolling
        disableOverlayClose
        disableCloseOnEsc
        spotlightClicks
      /> */}
      <Layout className="hp-calendar hp-mb-16 joy-admin-chat">
        <Content>
          <Row>
            <Col span={24}>
              {/* Create Group Chat Modal */}
              {createGroupModalOpen && (
                <GroupChatCreateModal
                  open={createGroupModalOpen}
                  handleModalClick={handleCreateGroupClick}
                  members={members}
                  fetchChats={fetchChats}
                  handleSelectedChat={handleSelectedChat}
                />
              )}

              {/* Group Chat View Modal */}
              {viewGroupModalOpen && (
                <GroupChatViewModal
                  open={viewGroupModalOpen}
                  handleModalClick={handleViewGroupClick}
                  members={members}
                  groupChatId={selectedChat._id}
                  currentUser={user}
                />
              )}
              {/* Group Chat Update Modal */}
              {updateGroupModalOpen && (
                <GroupChatUpdateModal
                  open={updateGroupModalOpen}
                  handleModalClick={handleUpdateGroupClick}
                  members={members}
                  fetchChats={fetchChats}
                  groupChatId={selectedChat._id}
                  currentUser={user}
                  handleSelectedChat={handleSelectedChat}
                />
              )}
              <Card>
                <Row
                  style={{
                    marginTop: "24px",
                  }}
                >
                  <h3 className="hp-mb-16">My Chat</h3>
                  {/* Create Group Chat Button (ADMIN Only) */}
                  {(user?.role === "admin" || user?.role === "manager") && (
                    <Button
                      type="primary"
                      onClick={handleCreateGroupClick}
                      style={{
                        marginLeft: "150px",
                        marginBottom: "12px",
                      }}
                      className="joy-admin-group-chat"
                    >
                      Create Group Chat
                    </Button>
                  )}
                </Row>
                <Row>
                  <Col flex="1 1" className="hp-py-24">
                    {/* Chat List */}
                    <div className="chatContainer">
                      {/* Chat Left */}
                      <div className="chatMenu">
                        <div className="chatMenuWrapper">
                          {/* Search User */}
                          <SearchUser handleSelectedChat={handleSelectedChat} />
                          {/* Chat List */}
                          <div className="joy-admin-chat-list">
                            {chats?.map((chat) => (
                              <div
                                key={chat._id}
                                onClick={() => handleSelectedChat(chat)}
                                style={
                                  selectedChat?._id === chat._id
                                    ? {
                                        backgroundColor: "#f5f5f5",
                                        width: "100%",
                                      }
                                    : {}
                                }
                              >
                                <ChatListItem
                                  currentUser={user}
                                  chat={chat}
                                  selected={selectedChat?._id === chat._id}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {/* Chat Box */}
                      <div
                        className="chatBox"
                        style={{
                          height: "calc(100vh - 250px)",
                          marginTop: "-50px",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          className="chatBoxWrapper"
                          style={{
                            backgroundColor: "#f5f5f5",
                          }}
                        >
                          {/* Chat Box Header */}
                          {selectedChat ? (
                            <>
                              <div className="chatBoxTop">
                                <div
                                  className="chatBoxTopLeft"
                                  style={{
                                    position: "absolute",
                                    top: "-10px",
                                    left: "0",
                                    zIndex: "1",
                                    backgroundColor: "#f5f5f5",
                                    width: "99%",
                                    // Border bottom box shadow
                                    boxShadow:
                                      "0 0.5px 0.5px 0 rgba(0,0,0,0.2)",
                                    padding: "5px",
                                  }}
                                >
                                  <div className="chatBoxTitle">
                                    <p className="chatBoxTitleText">
                                      {selectedChat?.isGroupChat
                                        ? selectedChat?.chatName +
                                          " (" +
                                          selectedChat?.chatName[0] +
                                          ")"
                                        : selectedChat.users?.find(
                                            (member) => member._id !== user._id
                                          )?.name}
                                    </p>
                                  </div>
                                  {/* View Group Chat */}
                                  {selectedChat?.isGroupChat && (
                                    <button
                                      onClick={handleViewGroupClick}
                                      style={{
                                        border: "none",
                                        cursor: "pointer",
                                        marginLeft: "400px",
                                        outline: "none",
                                      }}
                                    >
                                      <Show />
                                    </button>
                                  )}
                                  {/* Edit Group Chat (ADMIN & MANAGER Only) */}
                                  {(user?.role === "admin" ||
                                    user?.role === "manager") &&
                                    selectedChat?.isGroupChat && (
                                      <button
                                        onClick={handleUpdateGroupClick}
                                        style={{
                                          border: "none",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <EditOutlined />
                                      </button>
                                    )}
                                </div>

                                {/* Messages Section */}
                                {messages?.map((message, i) => (
                                  <>
                                    <div
                                      key={message?._id}
                                      ref={scrollRef}
                                      // Message should start below fixed top bar
                                      style={{
                                        marginTop: "70px",
                                      }}
                                    >
                                      <Message
                                        message={message}
                                        own={message.sender?._id === user?._id}
                                      />
                                    </div>
                                  </>
                                ))}
                                {isTyping ? (
                                  <div
                                    className="dot-flashing"
                                    style={{
                                      marginLeft: "60px",
                                      bottom: "60px",
                                      position: "absolute",
                                    }}
                                  ></div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="chatBoxBottom">
                                <div className="sendNewMessage">
                                  <button className="addFiles">
                                    <Plus
                                      style={{
                                        fontSize: "20px",
                                        marginTop: "5px",
                                      }}
                                    />
                                  </button>
                                  <input
                                    type="textarea"
                                    placeholder="Type a message here"
                                    value={newMessage}
                                    onChange={(e) => typingHandler(e)}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleSendMessage(e, selectedChat._id);
                                        setNewMessage("");
                                      }
                                    }}
                                    required
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      borderBottom: "1px solid #e5e5e5",
                                      padding: "5px",
                                      outline: "none",
                                    }}
                                  />
                                  <button
                                    className="btnSendMsg"
                                    id="sendMsgBtn"
                                    onClick={(e) => {
                                      handleSendMessage(e, selectedChat._id);
                                      setNewMessage("");
                                    }}
                                  >
                                    <Send
                                      style={{
                                        fontSize: "20px",
                                        marginTop: "5px",
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <span
                              className="noConversationText"
                              style={{
                                marginTop: "50px",
                                marginLeft: "55px",
                                color: "#8c8c8c",
                              }}
                            >
                              Select a conversation to start chatting
                            </span>
                          )}
                        </div>
                      </div>
                      {/* Chat Online Section */}
                      <div className="chatOnline">
                        <div className="chatOnlineWrapper">
                          <h3
                            style={{
                              marginLeft: "40px",
                              marginTop: "-60px",
                            }}
                          >
                            Shared Files
                          </h3>
                          <ChatOnline
                            currentUser={user}
                            onlineUsers={onlineUsers}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default AdminChat;
