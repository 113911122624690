import React, { useState, useEffect } from "react";
import { Row, Button, Select, Dropdown } from "antd";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { useSelector } from "react-redux";

const { Option } = Select;

const AssignTo = ({ assignedTo, values, setValues, handleUpdateTask }) => {
  const { taskAssignees } = useSelector((state) => state.project);
  const [loading, setLoading] = useState(false);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [alreadyAssigned, setAlreadyAssigned] = useState([]);

  useEffect(() => {
    setLoading(true);
    // Set assignee options
    setAssigneeOptions(taskAssignees);

    // Modify the data to match the format of react-select
    const modifiedAlreadyAssigned = assignedTo?.map((member) => ({
      label: member.name,
      email: member.email,
      value: member._id,
    }));
    // Set already assigned
    setAlreadyAssigned(modifiedAlreadyAssigned);

    setLoading(false);
  }, [assignedTo, taskAssignees]);

  // Handle assignee change
  const handleChange = (value) => {
    setAlreadyAssigned(value);

    // Modify each "label" to "name" & "value" to "_id"
    const modifiedValue = value.map((assignee) => ({
      _id: assignee.value,
      name: assignee.label,
    }));

    // Set the parent state
    setValues({
      ...values,
      assignedTo: modifiedValue,
    });

    // Create an array of assignee _id of type ObjectId
    const assigneeIds = modifiedValue.map((assignee) => assignee._id);

    // Update the task with the new assignee/s
    handleUpdateTask("assignedTo", assigneeIds);
  };

  return (
    <Row
      style={{
        marginTop: "1rem",
        marginBottom: "0.7rem",
      }}
    >
      {!loading && (
        <Dropdown
          getPopupContainer={(trigger) => trigger.parentNode}
          overlay={
            <>
              {/* Select with already selected values based on assignedTo */}
              <Select
                mode="multiple"
                style={{ width: "200px" }}
                labelInValue={true}
                placeholder="Select assignees"
                value={alreadyAssigned}
                onChange={handleChange}
              >
                {assigneeOptions?.length > 0 &&
                  assigneeOptions?.map((assignee) => (
                    <Option
                      key={assignee._id}
                      value={assignee._id}
                      label={assignee.name}
                    >
                      {assignee.name}
                    </Option>
                  ))}
              </Select>
            </>
          }
        >
          <Button
            shape="round"
            style={{
              background: "linear-gradient(to right, #839cff, #b1f6ff)",
            }}
            icon={
              <BsFillPersonPlusFill
                style={{
                  marginRight: "0.5rem",
                }}
              />
            }
            size={"small"}
          >
            Assign to
          </Button>
        </Dropdown>
      )}
    </Row>
  );
};

export default AssignTo;
