import api from "./index";

// Create a task
export const createTask = async (pid, mid, values) => {
  return await api.post(`/project/${pid}/milestone/${mid}/task`, values);
};

// Get a task by id
export const getTaskById = async (tid) => {
  return await api.get(`/task/${tid}`);
};

// Update a task by id
export const updateTaskById = async (pid, mid, tid, values) => {
  return await api.put(`/project/${pid}/milestone/${mid}/task/${tid}`, values);
};

// Delete a task by id
export const deleteTaskById = async (pid, mid, tid) => {
  return await api.delete(`/project/${pid}/milestone/${mid}/task/${tid}`);
};

// Get all task assignees
export const getTaskAssignees = async (pid, mid) => {
  return await api.get(`/task/${pid}/milestone/${mid}/assignees`);
};

// Update task assignees
export const updateTaskAssignees = async (pid, mid, tid, values) => {
  return await api.put(
    `/project/${pid}/milestone/${mid}/task/${tid}/assignees`,
    values
  );
};

// Upload a task attachment
export const uploadTaskAttachment = async (pid, mid, tid, values, config) => {
  return await api.post(
    `/project/${pid}/milestone/${mid}/task/${tid}/attachment`,
    values,
    config
  );
};
