import React, { useEffect, useState } from "react";
import { Button, Row, Col, Tag, Input } from "antd";
import { getMemberProjectsAction } from "../../../redux/actions/memberActions";
import { useDispatch, useSelector } from "react-redux";
import { BiSearchAlt } from "react-icons/bi";

const MemberProjectsList = ({ history }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { projects } = useSelector((state) => state.member);
  // Projects search
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setLoading(true);
    dispatch(getMemberProjectsAction(setLoading));
  }, []);

  // Handle project view
  const handleProjectView = (id) => {
    history.push(`/member/projects/view/${id}`);
  };

  // Filtered projects by search term
  const filteredProjects = (searchTerm) => {
    return projects.filter((project) => {
      return (
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <>
      <Col>
        <Row>
          <Col span={24} xs={8} sm={16} md={24} lg={32}>
            <Row>
              <Col span={3}>
                <h3
                  style={{
                    transform: "translateX(1rem)",
                  }}
                >
                  My Projects
                </h3>
              </Col>

              <Col
                span={10}
                xs={8}
                sm={16}
                md={24}
                lg={32}
                style={{
                  textAlign: "right",
                  transform: "translateY(-2.5rem)",
                }}
              >
                {/* Search icon with input projects */}
                <Input
                  placeholder="Search projects"
                  prefix={<BiSearchAlt />}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  style={{
                    width: "20rem",
                    marginRight: "35px",
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {loading ? (
          <div
            className="clock-loader"
            style={{ top: "calc(45vh - 100px)" }}
          ></div>
        ) : (
          <>
            {/* All projects table */}
            {projects?.length ? (
              <Col
                style={{
                  overflowY: "auto",
                  height: "calc(100vh - 200px)",
                }}
              >
                {projects &&
                  filteredProjects(searchTerm).map((project) => (
                    <Row key={project._id} style={{ marginBottom: "1rem" }}>
                      <Col xs={8} sm={16} md={24} lg={32}>
                        <div className="hp-border-2 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius hp-bg-color-black-0 hp-bg-color-dark-100 hp-p-12">
                          <Row
                            style={{
                              transform: "translateY(1.5rem)",
                            }}
                          >
                            {/* Project name */}
                            <Col sm={24} md={3}>
                              <h5
                                style={{
                                  color: "#0070f3",
                                }}
                              >
                                {project?.name}
                              </h5>
                            </Col>

                            {/* Project status */}
                            <Col sm={24} md={3}>
                              <Tag
                                color={
                                  project.status === "Completed"
                                    ? "#389e0d"
                                    : project.status === "In Progress"
                                    ? "blue"
                                    : project.status === "On Hold"
                                    ? "yellow"
                                    : "red"
                                }
                              >
                                {project.status}
                              </Tag>
                            </Col>

                            {/* Project timeline */}
                            <Col sm={24} md={7}>
                              <p
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                <b>Timeline:</b>{" "}
                                <i>
                                  {new Date(
                                    project.startDate
                                  ).toLocaleDateString(
                                    "en-US",
                                    dateFormat
                                  )}{" "}
                                </i>
                                <i>
                                  -{" "}
                                  {new Date(project.endDate).toLocaleDateString(
                                    "en-US",
                                    dateFormat
                                  )}
                                </i>
                              </p>
                            </Col>

                            {/* Project actions */}
                            <Col
                              span={8}
                              xs={8}
                              sm={16}
                              md={24}
                              lg={32}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                transform: "translateY(-3rem)",
                              }}
                            >
                              <Row>
                                {/* View */}
                                <Button
                                  type="primary"
                                  size="small"
                                  style={{
                                    marginRight: "15px",
                                  }}
                                  onClick={() => handleProjectView(project._id)}
                                >
                                  View Project
                                </Button>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  ))}
              </Col>
            ) : (
              <Col
                span={24}
                style={{
                  textAlign: "center",
                }}
              >
                <h3>No projects assigned to you </h3>
              </Col>
            )}
          </>
        )}
      </Col>
    </>
  );
};

export default MemberProjectsList;
