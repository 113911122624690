import { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { withRouter } from "react-router";
import { getCategoryById, updateCategoryById } from "../../../api/category";
import { SuccessNotification, ErrorNotification } from "../..";

const initialState = {
  name: "",
};

const CategoryUpdateModal = ({
  open,
  handleModalClick,
  fetchCategories,
  categoryId,
}) => {
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCategoryById(categoryId).then((res) => {
      setValues(res.data);
    });
  }, []);

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle category update
  const handleCategoryUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Validate form
    if (!values.name) {
      setLoading(false);
      return ErrorNotification("Please enter a name");
    }

    // Update category
    updateCategoryById(categoryId, values)
      .then(() => {
        setValues(initialState);
        handleModalClick();
        fetchCategories();
        SuccessNotification("Category updated successfully");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        ErrorNotification(err.response.data.message);
      });
  };

  return (
    <>
      <Modal
        title="Update Category"
        visible={open}
        onOk={handleModalClick}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCategoryUpdate}
            loading={loading}
          >
            Update
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* Category name */}
          <Form.Item label="Name">
            <Input
              name="name"
              placeholder="Enter category name"
              onChange={handleFormChange}
              value={values.name}
              rules={[{ required: true }]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default withRouter(CategoryUpdateModal);
