import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { createAdminEventAction } from "../../../redux/actions/adminActions";
import { createManagerEventAction } from "../../../redux/actions/managerActions";
import { createMemberEventAction } from "../../../redux/actions/memberActions";
import { createGuestEventAction } from "../../../redux/actions/guestActions";
import { ErrorNotification } from "../../../components";
import moment from "moment";

const initialState = {
  name: "",
  description: "",
  start: "",
  end: "",
};

const CreateEventModal = ({ eventStart, eventEnd, open, handleModalClose }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);

  useEffect(() => {
    if (eventStart && eventEnd) {
      setValues({
        ...values,
        start: eventStart,
        end: eventEnd,
      });
    }
  }, [eventStart, eventEnd]);

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle task create
  const handleEventCreate = async (e) => {
    e.preventDefault();

    // Check if the form is valid
    if (values.name.length === 0 || values.description.length === 0) {
      ErrorNotification("Please fill in all fields");
      return;
    }

    // Convert start and end dates mongoose Date format with the same time
    values.start = moment(values.start).toDate();
    values.end = moment(values.end).toDate();

    // If user is admin, dispatch action to create event
    if (user?.role === "admin") {
      dispatch(createAdminEventAction(values));
      handleModalClose();
    } else if (user?.role === "manager") {
      dispatch(createManagerEventAction(values));
      handleModalClose();
    } else if (user?.role === "member") {
      dispatch(createMemberEventAction(values));
      handleModalClose();
    } else if (user?.role === "guest") {
      dispatch(createGuestEventAction(values));
      handleModalClose();
    }
  };

  return (
    <>
      <Modal
        title={
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Create Event
          </h2>
        }
        visible={open}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <Button key="back" onClick={handleModalClose}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleEventCreate}
            loading={false}
          >
            Create
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* Event name */}
          <Form.Item label="Name">
            <Input
              name="name"
              placeholder="Enter event name"
              onChange={handleFormChange}
              value={values.name}
              rules={[{ required: true }]}
            />
          </Form.Item>

          {/* Event description */}
          <Form.Item label="Description">
            <Input.TextArea
              name="description"
              placeholder="Enter event description"
              onChange={handleFormChange}
              value={values.description}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>

          {/* Event start time */}
          {eventStart && (
            <Form.Item label="Start">
              <DatePicker
                name="start"
                showTime={{ format: "HH:mm" }}
                value={values.start}
                onChange={(date, dateString) => {
                  setValues({
                    ...values,
                    start: date,
                  });
                }}
                autoFocus
                popupStyle={{
                  height: "auto",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                style={{
                  width: "100%",
                }}
                popupAlign={{
                  offset: [0, 0],
                }}
              />
            </Form.Item>
          )}

          {/* Event end time */}
          {eventEnd && (
            <Form.Item label="End">
              <DatePicker
                name="end"
                showTime={{ format: "HH:mm" }}
                value={values.end}
                onChange={(date, dateString) => {
                  setValues({
                    ...values,
                    end: date,
                  });
                }}
                autoFocus
                popupStyle={{
                  height: "auto",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                style={{
                  width: "100%",
                }}
                popupAlign={{
                  offset: [0, 0],
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default CreateEventModal;
