import React from "react";

import { Row, Col, Progress } from "antd";
import { useSelector } from "react-redux";

const TotalActiveProjects = () => {
  const { analytics } = useSelector((state) => state.dash);

  return (
    <div className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius hp-bg-color-black-0 hp-bg-color-dark-100 hp-p-16 hp-card-2">
      <Row gutter={16} align="middle">
        <Col span={24} className="hp-text-center">
          <div
            id="chart"
            className="hp-bg-color-warning-4 hp-bg-color-dark-90 hp-border-radius hp-d-flex-center-full hp-mb-18"
          >
            <Progress
              type="circle"
              percent={Math.round(
                ((analytics?.totalProjects - analytics?.activeProjects) /
                  analytics?.totalProjects) *
                  100
              )}
              format={(percent) => `${percent} %`}
              width={72}
              style={{
                lineHeight: "6rem",
              }}
              strokeColor="#FFC700"
            />
          </div>

          <h3 className="hp-mb-0">
            {analytics?.totalProjects - analytics?.activeProjects}
          </h3>
          <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-50">
            Completed Projects
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default TotalActiveProjects;
