import React, { useState } from "react";
import { Modal, Button, Form, Select } from "antd";
import { changeTeamMemberRole } from "../../../api/admin";
import { SuccessNotification, ErrorNotification } from "../../../components";

const initialValues = {
  currentRole: "",
  newRole: "",
};

const ChangeRoleModal = ({
  open,
  setOpen,
  changeCurrentRole,
  fetchTeamMembers,
}) => {
  const [values, setValues] = useState(initialValues);
  const [processing, setProcessing] = useState(false);

  // Handle role change
  const handleRoleChange = (e) => {
    e.preventDefault();
    setProcessing(true);

    changeTeamMemberRole(changeCurrentRole?._id, values)
      .then((res) => {
        if (res.status === 200) {
          SuccessNotification(res.data.message);
          setOpen(false);
          fetchTeamMembers();
          setProcessing(false);
        }
      })
      .catch((err) => {
        // Error
        setProcessing(false);
        ErrorNotification("Error changing role");
        console.log("TEAM_ROLE_CHANGE_ERROR", err);
        setProcessing(false);
      });
  };

  return (
    <>
      <Modal
        title={
          <h3
            style={{
              textAlign: "center",
            }}
          >
            Change {changeCurrentRole?.name}'s role
          </h3>
        }
        visible={open}
        onOk={handleRoleChange}
        closable={true}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleRoleChange}
            loading={processing}
          >
            Change
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* Role */}
          <Form.Item label="Role">
            <Select
              name="newRole"
              placeholder="Select role"
              onChange={(value) =>
                setValues({
                  ...values,
                  newRole: value,
                  currentRole: changeCurrentRole?.role.toLowerCase(),
                })
              }
              value={values.newRole}
            >
              {changeCurrentRole?.role === "Member" && (
                <>
                  <Select.Option value="admin">Admin</Select.Option>
                  <Select.Option value="manager">Manager</Select.Option>
                </>
              )}
              {changeCurrentRole?.role === "Manager" && (
                <>
                  <Select.Option value="admin">Admin</Select.Option>
                  <Select.Option value="member">Member</Select.Option>
                </>
              )}
              {changeCurrentRole?.role === "Admin" && (
                <>
                  <Select.Option value="manager">Manager</Select.Option>
                  <Select.Option value="member">Member</Select.Option>
                </>
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangeRoleModal;
