import api from "./index";

// Create a blog
export const createBlog = (blog) => {
  return api.post("/blog", blog);
};

// Get all blogs
export const getAllBlogs = () => {
  return api.get("/blogs");
};

// Get a blog by ID
export const getBlogById = (id) => {
  return api.get(`/blog/${id}`);
};

// Update a blog by ID
export const updateBlogById = (id, blog) => {
  return api.put(`/blog/${id}`, blog);
};

// Delete a blog by ID
export const deleteBlogById = (id) => {
  return api.delete(`/blog/${id}`);
};

// Upload blog cover image
export const uploadBlogCoverImage = async (values, config) => {
  return await api.post(`/blog/cover-image`, values, config);
};

// Upload blog content image
export const uploadBlogContentImage = async (values, config) => {
  return await api.post(`/blog/content-image`, values, config);
};
