import React, { useState, useEffect } from "react";
import { ParentTable, ParentTableHead } from "../../../components";
import { Card, Row, Col, Tag, Spin } from "antd";
import { getAllLeads } from "../../../api/superAdmin";

const LeadsList = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllLeads()
      .then((res) => {
        setLeads(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <>
      <Card
        className="hp-border-color-black-40 hp-mb-32 hp-analytics-project-table-card hp-project-table-card"
        style={{ height: "100%" }}
      >
        <Row>
          <Col span={24}>
            <Row justify="space-between">
              <h3 className="hp-mb-16">All Leads</h3>
            </Row>

            {/* All leads table */}
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(80vh - 50px)",
                }}
              >
                <Spin />
              </div>
            ) : (
              <ParentTable>
                <ParentTableHead>
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Country</th>
                    <th scope="col">State</th>
                    <th scope="col">City</th>
                    <th scope="col">Visit Date</th>
                    <th scope="col">Country Code</th>
                    <th scope="col">IP</th>
                  </tr>
                </ParentTableHead>
                <tbody>
                  {leads &&
                    leads?.map((lead) => (
                      <tr key={lead?._id} style={{ cursor: "pointer" }}>
                        <td>{lead?.email}</td>
                        <td>
                          <Tag color="blue">{lead?.meta?.country_name}</Tag>
                        </td>
                        <td>{lead?.meta?.state}</td>
                        <td>
                          <Tag color="blue">{lead?.meta?.city}</Tag>
                        </td>
                        <td>{lead?.createdAt.split("T")[0]}</td>
                        <td>{lead?.meta?.country_code}</td>
                        <td>
                          <Tag color="red">{lead?.meta?.IPv4}</Tag>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </ParentTable>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default LeadsList;
