import React from "react";
import { Row, Col } from "antd";

import OverviewCard from "./OverviewCard";

const MilestoneOverviewCard = ({ stats, values }) => {
  // If percent complete is NaN, then set it to 0
  const percentComplete = isNaN(
    (stats?.completedTasks / stats?.totalTasks) * 100
  )
    ? 0
    : (stats?.completedTasks / stats?.totalTasks) * 100;

  return (
    <>
      <Row gutter={32} className="joy-mstn-ovw">
        <Col xl={24}>
          <OverviewCard
            status={values?.status}
            percentComplete={percentComplete}
            values={values}
          />
        </Col>
      </Row>
    </>
  );
};

export default MilestoneOverviewCard;
