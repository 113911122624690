import moment from "moment";

// Custom day cell component
export const CustomDayCell = ({ event }) => {
  return <p>{event.title.charAt(0).toUpperCase() + event.title.slice(1)}</p>;
};

// Custom week cell component
export const CustomWeekCell = ({ event }) => {
  return (
    <span>
      {!event.allDay && <p>&nbsp;</p>}
      {/* Capitalize Title */}
      <p>{event.title.charAt(0).toUpperCase() + event.title.slice(1)}</p>
    </span>
  );
};

// Custom month cell component
export const CustomMonthCell = ({ event }) => {
  return (
    <span>
      {/* Capitalize Title */}
      <p>{event.title.charAt(0).toUpperCase() + event.title.slice(1)}</p>
    </span>
  );
};

// Custom agenda event component format
export const CustomAgendaEvent = ({ event }) => {
  return (
    <span
      style={{
        cursor: "pointer",
      }}
    >
      <em style={{ color: "royalblue" }}>
        {event.title.charAt(0).toUpperCase() + event.title.slice(1)}
      </em>
      <p>{event.desc}</p>
    </span>
  );
};

// Custom event agenda time
export const CustomAgendaTime = ({ event }) => {
  const startTime = moment(event.start).format("h:mm a");
  const endTime = moment(event.end).format("h:mm a");

  return (
    <span>
      {!event.allDay ? (
        <p>
          {startTime} - {endTime}
        </p>
      ) : (
        <p>All day</p>
      )}
    </span>
  );
};
