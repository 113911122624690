import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form, Input } from "antd";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getChatById, updateGroupChatById } from "../../../api/chat";
import { SuccessNotification, ErrorNotification } from "../../../components";

// Initial state
const initialValues = {
  groupName: "",
  users: [],
};

const GroupChatUpdateModal = ({
  open,
  handleModalClick,
  members,
  groupChatId,
  currentUser,
  fetchChats,
  handleSelectedChat,
}) => {
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  // Fetch group chat by id
  const fetchGroupChatById = useCallback(async () => {
    setLoading(true);
    getChatById(groupChatId)
      .then((res) => {
        // Remove current user from users array
        const users = res.data.users.filter(
          (user) => user._id !== currentUser._id
        );

        // Extract only name, email and _id from users array
        const modUsers = users.map((user) => ({
          name: user.name,
          email: user.email,
          _id: user._id,
        }));

        setValues({
          groupName: res.data.chatName,
          users: modUsers,
        });

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        ErrorNotification(err.response?.data.message);
      });
  }, [groupChatId, currentUser]);

  // Fetch group chat on mount
  useEffect(() => {
    if (open) {
      fetchGroupChatById();
    }
  }, [fetchGroupChatById, open]);

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle group update
  const handleGroupUpdate = async (e) => {
    e.preventDefault();
    // Filter members array to get only the user id
    const membersId = values.users.map((member) => member._id);
    values.users = membersId;

    // Update group chat
    updateGroupChatById(groupChatId, values)
      .then((res) => {
        // Fetch all chats
        fetchChats();
        // Set selected chat
        handleSelectedChat(res.data);
        // Close modal
        handleModalClick();
        setValues(initialValues);
        SuccessNotification(`Group ${res.data.chatName} updated successfully`);
      })
      .catch((err) => {
        ErrorNotification(err.response?.data.message);
      });
  };

  return (
    !loading && (
      <>
        <Modal
          title="Update Group Chat"
          visible={open}
          onCancel={handleModalClick}
          footer={[
            <Button key="back" onClick={handleModalClick}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleGroupUpdate}
              loading={loading}
            >
              Update
            </Button>,
          ]}
        >
          <Form
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            {/* Group name */}
            <Form.Item label="Group Name">
              <Input
                name="groupName"
                value={values.groupName ? values.groupName : ""}
                onChange={handleFormChange}
                placeholder="Group name"
              />
            </Form.Item>

            {/* Group members */}
            <Form.Item label="Members">
              <Autocomplete
                multiple
                id="tags-outlined"
                options={members.filter(
                  (member) =>
                    !values.users?.some((user) => user._id === member._id)
                )}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option._id === value._id}
                filterSelectedOptions
                value={values.users ? values.users : []}
                onChange={(event, member) => {
                  setValues({
                    ...values,
                    users: member,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Add members"
                  />
                )}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  );
};

export default GroupChatUpdateModal;
