import React, { useState } from "react";
import { Col, Row, Button, Input, Modal, Progress } from "antd";
import { useSelector } from "react-redux";
import { RiInformationLine } from "react-icons/ri";
import { ErrorNotification } from "../../../../../components";
import { ChecklistHeader, ChecklistItems } from "./checklistComponents";
import {
  addItemToChecklist,
  updateItemOfChecklist,
  deleteItemOfChecklist,
  deleteChecklistOfTask,
  updateChecklistName,
} from "../../../../../api/checklist";

const ChecklistDisplay = ({ values, setValues, taskId }) => {
  const { user } = useSelector((state) => state.auth);
  const [createLoading, setCreateLoading] = useState(false);
  const [itemName, setItemName] = useState("");
  const [showItemInput, setShowItemInput] = useState(false);
  const [showInputListId, setShowInputListId] = useState(null);
  // Item edit
  const [editMode, setEditMode] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemName, setEditItemName] = useState("");
  const [editItemLoading, setEditItemLoading] = useState(false);
  // Checklist name edit
  const [editChecklistNameMode, setEditChecklistNameMode] = useState(false);
  const [editChecklistId, setEditChecklistId] = useState(null);
  const [editChecklistName, setEditChecklistName] = useState("");
  const [editChecklistNameLoading, setEditChecklistNameLoading] =
    useState(false);

  // Handle add an item
  const handleAddItem = (cid) => {
    setCreateLoading(true);
    const body = {
      itemName: itemName,
    };

    addItemToChecklist(cid, body)
      .then((res) => {
        setValues({
          ...values,
          //   Update only the items of the checklist with the same id
          checklists: values.checklists.map((checklist) => {
            if (checklist._id === cid) {
              return {
                ...checklist,
                items: [...checklist.items, res.data],
              };
            }
            return checklist;
          }),
        });
        setItemName("");
        setShowItemInput(false);
        setShowInputListId(null);
        setCreateLoading(false);
      })
      .catch((error) => {
        setCreateLoading(false);
        console.log("ADD_ITEM_TO_CHECKLIST_ERROR", error);
      });
  };

  // Handle update an item
  const handleUpdateItem = (cid, iid, body) => {
    setEditItemLoading(true);
    updateItemOfChecklist(cid, iid, body)
      .then((res) => {
        setValues({
          ...values,
          //   Update only the items of the checklist with the same id
          checklists: values.checklists.map((checklist) => {
            if (checklist._id === cid) {
              return {
                ...checklist,
                items: checklist.items.map((item) => {
                  if (item._id === iid) {
                    return res.data;
                  }
                  return item;
                }),
              };
            }
            return checklist;
          }),
        });

        setEditMode(false);
        setEditItemId(null);
        setEditItemName("");
        setEditItemLoading(false);
      })
      .catch((error) => {
        setEditItemLoading(false);
        console.log("UPDATE_ITEM_OF_CHECKLIST_ERROR", error);
      });
  };

  // Handle delete an item
  const handleDeleteItem = (cid, iid) => {
    setValues({
      ...values,
      // Update only the items of the checklist with the same id
      checklists: values.checklists.map((checklist) => {
        if (checklist._id === cid) {
          return {
            ...checklist,
            items: checklist.items.filter((item) => item._id !== iid),
          };
        }
        return checklist;
      }),
    });

    deleteItemOfChecklist(cid, iid)
      .then(() => {})
      .catch((error) => {
        console.log("DELETE_ITEM_OF_CHECKLIST_ERROR", error);
      })
      .then(() => {
        setEditMode(false);
        setEditItemId(null);
        setEditItemName("");
      });
  };

  // Handle delete a checklist
  const handleDeleteChecklist = (cid) => {
    // Confirm delete checklist
    Modal.confirm({
      title: (
        <h5 className="hp-mb-0 hp-font-weight-500">
          Are you sure you want to delete this checklist?
        </h5>
      ),
      icon: (
        <span className="remix-icon">
          <RiInformationLine />
        </span>
      ),
      okText: "Yes",
      cancelText: "No",
      content: (
        <p className="hp-p1-body hp-text-color-black-80">
          Deleting a checklist is permanent and there is no way to get it back.
        </p>
      ),
      onOk: async () => {
        try {
          setValues({
            ...values,
            checklists: values.checklists.filter(
              (checklist) => checklist._id !== cid
            ),
          });
          deleteChecklistOfTask(taskId, cid).then(() => {});
        } catch (error) {
          // Show error notification
          ErrorNotification("DELETE_CHECKLIST_OF_TASK_ERROR");
        }
      },
    });
  };

  // Handle update checklist name
  const handleUpdateChecklistName = (cid, body) => {
    setEditChecklistNameLoading(true);
    updateChecklistName(cid, body)
      .then((res) => {
        setValues({
          ...values,
          checklists: values.checklists.map((checklist) => {
            if (checklist._id === cid) {
              return res.data;
            }
            return checklist;
          }),
        });

        setEditChecklistNameMode(false);
        setEditChecklistId(null);
        setEditChecklistName("");
        setEditChecklistNameLoading(false);
      })
      .catch((error) => {
        setEditChecklistNameLoading(false);
        console.log("UPDATE_CHECKLIST_NAME_ERROR", error);
      });
  };

  return (
    <>
      {values.checklists?.length > 0 ? (
        values.checklists?.map((checklist) => (
          <Col
            key={checklist._id}
            style={{
              marginBottom: "2rem",
            }}
          >
            {/* Checklist Header */}
            <ChecklistHeader
              user={user}
              checklist={checklist}
              editChecklistNameMode={editChecklistNameMode}
              setEditChecklistNameMode={setEditChecklistNameMode}
              editChecklistId={editChecklistId}
              setEditChecklistId={setEditChecklistId}
              editChecklistName={editChecklistName}
              setEditChecklistName={setEditChecklistName}
              editChecklistNameLoading={editChecklistNameLoading}
              handleUpdateChecklistName={handleUpdateChecklistName}
              handleDeleteChecklist={handleDeleteChecklist}
            />

            {/* Progress */}
            <Row
              style={{
                marginBottom: "1rem",
              }}
            >
              <Col span={24}>
                <Progress
                  percent={
                    checklist.items.length > 0
                      ? (checklist.items.filter((item) => item.done).length /
                          checklist.items.length) *
                        100
                      : 0
                  }
                  status={
                    checklist.items.length > 0
                      ? checklist.items.filter((item) => item.done).length /
                          checklist.items.length >
                        0
                        ? "success"
                        : "active"
                      : "exception"
                  }
                  showInfo={false}
                />
              </Col>
            </Row>

            {/* Checklist Items */}
            <ChecklistItems
              checklist={checklist}
              // Edit Item
              editMode={editMode}
              setEditMode={setEditMode}
              editItemId={editItemId}
              setEditItemId={setEditItemId}
              editItemName={editItemName}
              setEditItemName={setEditItemName}
              editItemLoading={editItemLoading}
              handleUpdateItem={handleUpdateItem}
              // Delete Item
              handleDeleteItem={handleDeleteItem}
            />

            {/* Add or Cancel item */}
            <Row>
              {showItemInput && showInputListId === checklist._id ? (
                <Col
                  span={24}
                  style={{
                    marginLeft: "1.4rem",
                  }}
                >
                  <Input.TextArea
                    placeholder="Add an item"
                    autoSize={{
                      minRows: 2,
                      maxRows: 3,
                    }}
                    size="small"
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                    style={{
                      marginBottom: "1rem",
                    }}
                  />
                  {/* Add & Cancel buttons */}
                  <Row>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => handleAddItem(checklist._id)}
                      style={{
                        marginRight: "0.7rem",
                      }}
                      disabled={itemName === ""}
                      loading={createLoading}
                    >
                      Add
                    </Button>
                    <Button
                      size="small"
                      onClick={() => setShowItemInput(false)}
                    >
                      Cancel
                    </Button>
                  </Row>
                </Col>
              ) : (
                <Button
                  type="primary"
                  size="small"
                  style={{
                    marginLeft: "1.5rem",
                  }}
                  onClick={() => {
                    setShowItemInput(true);
                    setShowInputListId(checklist._id);
                  }}
                >
                  Add an item
                </Button>
              )}
            </Row>
          </Col>
        ))
      ) : (
        <Col
          style={{
            marginBottom: "1rem",
          }}
        >
          <Row>
            <h4>No checklist added </h4>
          </Row>
        </Col>
      )}
    </>
  );
};

export default ChecklistDisplay;
