import styled from "styled-components";

export const ListCardParent = styled.div`
  width: 18vw !important;
  background-color: #ebecf0;
  margin-right: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
`;

export const ListTitleContainer = styled.div`
  padding: 1rem 1rem 0;
`;

export const ListCardContainer = styled.div`
  overflow-x: auto;
  max-height: 55vh;
  padding: 0 1rem;
`;

export const ListCard = styled.div`
  overflow-y: hidden;
  margin: 0.5rem 0;
`;
