import React from "react";

import { Row, Col, Progress } from "antd";
import { useSelector } from "react-redux";

const TotalFilesCard = () => {
  const { analytics } = useSelector((state) => state.dash);

  return (
    <div className="hp-border-1 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius hp-bg-color-black-0 hp-bg-color-dark-100 hp-p-8 hp-card-1">
      <Row gutter={16} align="middle">
        <Col span={24} className="hp-text-center">
          <div
            id="chart"
            className="hp-bg-color-dark-90 hp-border-radius hp-d-flex-center-full hp-mb-12"
            style={{
              backgroundColor: "#ffdbb7",
            }}
          >
            <Progress
              type="circle"
              percent={Math.round((analytics?.sizeInMB / 2000) * 100)}
              format={(percent) => `${percent} %`}
              width={72}
              style={{
                lineHeight: "6rem",
              }}
              strokeColor="#FFA54C"
            />
          </div>

          <h3 className="hp-mb-0">
            {analytics?.sizeInMB ? analytics?.sizeInMB : 0} MB / 2 GB
          </h3>
          <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-50">
            Files Uploaded
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default TotalFilesCard;
