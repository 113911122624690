import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Statistic, Tag } from "antd";
import { BiArrowBack } from "react-icons/bi";
import { ParentTable, ParentTableHead } from "../../../../components";
import { getProjectReportById } from "../../../../api/project";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

const ProjectReport = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [report, setReport] = useState();
  const [team, setTeam] = useState();

  useEffect(() => {
    setLoading(true);
    getProjectReportById(id)
      .then((res) => {
        setReport(res.data.report);
        // Combine the team members and managers
        const teamMembers = res.data.report.details.members || [];
        const teamManagers = res.data.report.details.managers || [];
        setTeam([...teamMembers, ...teamManagers]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  // Handle export to pdf
  const handleExportToPdf = () => {
    setExportLoading(true);
    let domElement = document.getElementById("pj-report");
    htmlToImage
      .toPng(domElement)
      .then(function (dataUrl) {
        const pdf = new jsPDF();
        // Fit the image to the page width
        pdf.addImage(dataUrl, "PNG", 0, 0, pdf.internal.pageSize.width, 0);
        pdf.save(`${report?.name}-${new Date().toLocaleTimeString()}.pdf`);
        setExportLoading(false);
      })
      .catch(function (error) {
        setExportLoading(false);
        console.error("oops, something went wrong!", error);
      });
  };

  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <>
      <Row>
        <Col span={24} xs={8} sm={16} md={24} lg={32}>
          <Row
            justify="space-between"
            style={{
              marginBottom: "20px",
            }}
          >
            <BiArrowBack
              style={{ fontSize: "2em", cursor: "pointer" }}
              onClick={() => history.goBack()}
            />

            <Button
              type="primary"
              style={{
                marginRight: "15px",
              }}
              onClick={() => handleExportToPdf()}
              loading={exportLoading}
            >
              Export to PDF
            </Button>
          </Row>
        </Col>
      </Row>

      {loading ? (
        <div
          className="clock-loader"
          style={{ top: "calc(40vh - 100px)" }}
        ></div>
      ) : (
        <>
          {/* ------------------ Project Metadata ------------------ */}
          <Row id="pj-report">
            <Col span={24}>
              <Card>
                <h2
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "3rem",
                  }}
                >
                  {report?.name} - Report
                </h2>

                <Row
                  gutter={16}
                  style={{
                    textAlign: "center",
                    marginBottom: "3rem",
                  }}
                >
                  {/* Status */}
                  <Col span={6}>
                    <Statistic
                      title="Status"
                      value={report?.status}
                      valueStyle={{
                        color:
                          report?.status === "Completed"
                            ? "#389e0d"
                            : report?.status === "In Progress"
                            ? "blue"
                            : report?.status === "On Hold"
                            ? "orange"
                            : "red",
                      }}
                    />
                  </Col>

                  {/* Start Date */}
                  <Col span={6}>
                    <Statistic
                      title="Start Date"
                      value={new Date(report?.startDate).toLocaleDateString(
                        "en-US",
                        dateFormat
                      )}
                    />
                  </Col>

                  {/* End Date */}
                  <Col span={6}>
                    <Statistic
                      title="End Date"
                      value={new Date(report?.endDate).toLocaleDateString(
                        "en-US",
                        dateFormat
                      )}
                    />
                  </Col>

                  {/* Days Left*/}
                  <Col span={6}>
                    <Statistic
                      title="Days Left"
                      value={report?.stats?.daysLeft}
                    />
                  </Col>
                </Row>

                <Row
                  gutter={16}
                  style={{
                    textAlign: "center",
                    marginBottom: "3rem",
                  }}
                >
                  {/* Total Milestones */}
                  <Col span={12}>
                    <Statistic
                      title="Total Milestones"
                      value={
                        report?.stats?.totalMilestones
                          ? report?.stats?.totalMilestones
                          : 0
                      }
                    />
                  </Col>

                  {/* Total Tasks */}
                  <Col span={12}>
                    <Statistic
                      title="Total Tasks"
                      value={
                        report?.stats?.totalTasks
                          ? report?.stats?.totalTasks
                          : 0
                      }
                    />
                  </Col>
                </Row>

                {/* ------------------ Project Team ------------------ */}
                <div
                  style={{
                    marginBottom: "3rem",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "bold",
                      marginBottom: "1rem",
                      textAlign: "center",
                    }}
                  >
                    Project Team
                  </h3>
                  <ParentTable>
                    <ParentTableHead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Role</th>
                      </tr>
                    </ParentTableHead>
                    <tbody>
                      {team &&
                        team.map((member) => (
                          <tr key={member._id} style={{ cursor: "pointer" }}>
                            <td>
                              <h5
                                style={{
                                  color: "#0070f3",
                                  fontSize: "0.9rem",
                                }}
                              >
                                {member.name}
                              </h5>
                            </td>

                            <td>
                              <Tag
                                color={
                                  member?.role === "Admin"
                                    ? "purple"
                                    : member?.role === "Manager"
                                    ? "blue"
                                    : member?.role === "Member"
                                    ? "orange"
                                    : "volcano"
                                }
                              >
                                {member?.role}
                              </Tag>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </ParentTable>
                </div>

                {/* ------------------ Project Milestones ------------------ */}
                <div
                  style={{
                    marginBottom: "3rem",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "bold",
                      marginBottom: "1rem",
                      textAlign: "center",
                    }}
                  >
                    Milestones
                  </h3>
                  <ParentTable>
                    <ParentTableHead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Start date</th>
                        <th scope="col">End date</th>
                        <th scope="col">Days left</th>
                        <th scope="col">Assigned to</th>
                      </tr>
                    </ParentTableHead>
                    <tbody>
                      {report?.details?.milestones &&
                        []
                          .concat(...report?.details?.milestones)
                          .map((milestone) => (
                            <tr
                              key={milestone._id}
                              style={{ cursor: "pointer" }}
                            >
                              <td>
                                <h5
                                  style={{
                                    color: "#0070f3",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  {milestone.name}
                                </h5>
                              </td>
                              <td>
                                <Tag
                                  color={
                                    milestone.status === "Completed"
                                      ? "#389e0d"
                                      : milestone.status === "In Progress"
                                      ? "blue"
                                      : milestone.status === "On Hold"
                                      ? "yellow"
                                      : "red"
                                  }
                                >
                                  {milestone.status}
                                </Tag>
                              </td>

                              <td>
                                {new Date(
                                  milestone.startDate
                                ).toLocaleDateString("en-US", dateFormat)}
                              </td>
                              <td>
                                {new Date(milestone.endDate).toLocaleDateString(
                                  "en-US",
                                  dateFormat
                                )}
                              </td>
                              <td>
                                {Math.floor(
                                  (new Date(milestone.endDate).getTime() -
                                    new Date().getTime()) /
                                    (1000 * 60 * 60 * 24) >
                                    0
                                    ? Math.floor(
                                        (new Date(milestone.endDate).getTime() -
                                          new Date().getTime()) /
                                          (1000 * 60 * 60 * 24)
                                      )
                                    : 0
                                )}
                              </td>
                              <td>
                                {milestone.assignedTo
                                  .map((assignedTo) => assignedTo.name)
                                  .join(", ")}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </ParentTable>
                </div>

                {/* ------------------ Project Tasks ------------------ */}
                <div
                  style={{
                    marginBottom: "3rem",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "bold",
                      marginBottom: "1rem",
                      textAlign: "center",
                    }}
                  >
                    Tasks
                  </h3>
                  <ParentTable>
                    <ParentTableHead>
                      <tr>
                        <th scope="col">Milestone</th>
                        <th scope="col">Name</th>
                        <th scope="col">Stage</th>
                        <th scope="col">Priority</th>
                        <th scope="col">Start date</th>
                        <th scope="col">End date</th>
                        <th scope="col">Days left</th>
                        <th scope="col">Assigned to</th>
                      </tr>
                    </ParentTableHead>
                    <tbody>
                      {report?.details?.tasks &&
                        [].concat(...report?.details?.tasks).map((task) => (
                          <tr key={task._id} style={{ cursor: "pointer" }}>
                            <td>
                              <h5
                                style={{
                                  color: "#0070f3",
                                  fontSize: "0.9rem",
                                }}
                              >
                                {task.milestoneName}
                              </h5>
                            </td>
                            <td>
                              <h5
                                style={{
                                  color: "#0070f3",
                                  fontSize: "0.9rem",
                                }}
                              >
                                {task.name}
                              </h5>
                            </td>
                            <td>
                              <Tag
                                color={
                                  task.stage === "Done"
                                    ? "#389e0d"
                                    : task.stage === "In Progress"
                                    ? "blue"
                                    : task.stage === "Review"
                                    ? "yellow"
                                    : "volcano"
                                }
                              >
                                {task.stage}
                              </Tag>
                            </td>
                            <td>
                              <Tag
                                color={
                                  task.priority === "Critical"
                                    ? "red"
                                    : task.priority === "High"
                                    ? "orange"
                                    : task.priority === "Medium"
                                    ? "yellow"
                                    : "#389e0d"
                                }
                              >
                                {task.priority}
                              </Tag>
                            </td>
                            <td>
                              {task.startDate !== "Not set"
                                ? new Date(task.startDate).toLocaleDateString(
                                    "en-US",
                                    dateFormat
                                  )
                                : "Not set"}
                            </td>
                            <td>
                              {task.endDate !== "Not set"
                                ? new Date(task.endDate).toLocaleDateString(
                                    "en-US",
                                    dateFormat
                                  )
                                : "Not set"}
                            </td>
                            <td>
                              {task.endDate !== "Not set"
                                ? Math.floor(
                                    (new Date(task.endDate).getTime() -
                                      new Date().getTime()) /
                                      (1000 * 60 * 60 * 24) >
                                      0
                                      ? Math.floor(
                                          (new Date(task.endDate).getTime() -
                                            new Date().getTime()) /
                                            (1000 * 60 * 60 * 24)
                                        )
                                      : 0
                                  )
                                : "Not set"}
                            </td>
                            <td>
                              {task.assignedTo
                                .map((assignedTo) => assignedTo.name)
                                .join(", ")}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </ParentTable>
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProjectReport;
