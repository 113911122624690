import React, { useState } from "react";
import { Row, Button, Dropdown, Upload, message } from "antd";
import { useParams } from "react-router";
import { ImAttachment } from "react-icons/im";
import { RiLoaderLine, RiUploadCloud2Line } from "react-icons/ri";
import { uploadTaskAttachment } from "../../../../../api/task";

const Attachments = ({ taskId, values, setValues }) => {
  const { id, mid } = useParams();
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    // Attachment can be jpeg, png, gif, pdf, doc, docx, xls, xlsx, ppt, pptx, txt, svg
    const attachment = [
      "jpeg",
      "png",
      "gif",
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "txt",
      "svg",
    ];

    const isAttachment = attachment.includes(
      file.name.split(".")[file.name.split(".").length - 1]
    );
    if (!isAttachment) {
      message.error("The file type is not supported.");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Attachment must smaller than 5MB!");
    }

    return isAttachment && isLt5M;
  };

  // Handle upload attachment
  const handleUploadImage = async (options) => {
    const { onError, file } = options;
    const fmData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("image", file);

    // Make request to upload image
    try {
      setLoading(true);
      await uploadTaskAttachment(id, mid, taskId, fmData, config)
        .then((res) => {
          // Insert new attachment to the beginning of the array
          const newAttachments = [res.data, ...values.attachments];
          // Set the parent state to update the attachment list
          setValues({
            ...values,
            attachments: newAttachments,
          });

          setLoading(false);
          message.success("Attachment uploaded successfully");
        })
        .catch((err) => {
          onError(err);
          setLoading(false);
          message.error("Error updating attachment");
        });
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Row
      style={{
        marginBottom: "0.7rem",
      }}
    >
      <Dropdown
        getPopupContainer={(trigger) => trigger.parentNode}
        overlay={
          <>
            {/* Upload task attachment */}
            <Upload
              name="file"
              listType="picture-card"
              customRequest={handleUploadImage}
              showUploadList={false}
              beforeUpload={beforeUpload}
              multiple={false}
              accept=".jpeg, .png, .gif, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .svg"
            >
              <div>
                {loading ? (
                  <RiLoaderLine
                    className="remix-icon-loading remix-icon-spin remix-icon"
                    size={24}
                  />
                ) : (
                  <RiUploadCloud2Line size={24} />
                )}
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </>
        }
      >
        <Button
          shape="round"
          style={{
            background: "linear-gradient(to right, #839cff, #b1f6ff)",
          }}
          icon={
            <ImAttachment
              style={{
                marginRight: "0.5rem",
              }}
            />
          }
          size={"small"}
        >
          Attachments
        </Button>
      </Dropdown>
    </Row>
  );
};

export default Attachments;
