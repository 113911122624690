import { combineReducers } from "redux";

// Reducers
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import adminReducer from "./adminReducer";
import managerReducer from "./managerReducer";
import memberReducer from "./memberReducer";
import userReducer from "./userReducer";
import guestReducer from "./guestReducer";
import projectReducer from "./projectReducer";
import chatReducer from "./chatReducer";
import customizeReducer from "./customizeReducer";
import dashboardReducer from "./dashboardReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  dash: dashboardReducer,
  alert: alertReducer,
  admin: adminReducer,
  manager: managerReducer,
  guest: guestReducer,
  project: projectReducer,
  member: memberReducer,
  user: userReducer,
  chat: chatReducer,
  customize: customizeReducer,
});

export default rootReducer;
