import { DASHBOARD_TYPES } from "../constants/actionTypes";

// Initial state
const initialState = { notifications: [] };

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_TYPES.GET_ANALYTICS:
      return { ...state, analytics: action?.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
