import {
  Home,
  User,
  Show,
  Send,
  Ticket,
  Discount,
  Buy,
  Category,
} from "react-iconly";
import { FaBlogger, FaTags } from "react-icons/fa";

const superAdmin = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Home set="curved" className="remix-icon" />,
    navLink: "/super-admin/dashboard",
  },
  {
    id: "admins",
    title: "Admins",
    icon: <User set="curved" className="remix-icon" />,
    navLink: "/super-admin/admins",
  },
  {
    id: "visitors",
    title: "Visitors",
    icon: <Show set="curved" className="remix-icon" />,
    navLink: "/super-admin/visitors",
  },
  {
    id: "leads",
    title: "Leads",
    icon: <Send set="curved" className="remix-icon" />,
    navLink: "/super-admin/leads",
  },
  {
    id: "categories",
    title: "Categories",
    icon: <Category set="curved" className="remix-icon" />,
    navLink: "/super-admin/categories",
  },
  {
    id: "tags",
    title: "Tags",
    icon: <FaTags set="curved" className="remix-icon" />,
    navLink: "/super-admin/tags",
  },
  {
    id: "blogs",
    title: "Blogs",
    icon: <FaBlogger set="curved" className="remix-icon" />,
    navLink: "/super-admin/blogs",
  },
  {
    id: "orders",
    title: "Orders",
    icon: <Buy set="curved" className="remix-icon" />,
    navLink: "/admin/orders",
  },
  {
    id: "plans",
    title: "Plans",
    icon: <Ticket set="curved" className="remix-icon" />,
    navLink: "/super-admin/plans",
  },
  {
    id: "coupons",
    title: "Coupons",
    icon: <Discount set="curved" className="remix-icon" />,
    navLink: "/super-admin/coupons",
  },
];

export default superAdmin;
