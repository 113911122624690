import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import App from "./App";

import "./assets/icons/remixicon.css";
import "./assets/less/netraga-theme.less";

// Redux
import { DataProvider } from "./redux/store";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const browserHistory = createBrowserHistory();

ReactDOM.render(
  <DataProvider>
    <Router history={browserHistory}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </Router>
  </DataProvider>,
  document.getElementById("root")
);
