import React from "react";
import { Row, Button, Col, Dropdown, Tooltip, DatePicker } from "antd";
import { useSelector } from "react-redux";
import { BsClockHistory } from "react-icons/bs";
import moment from "moment";

const Timeline = ({ values, setValues, handleUpdateTask }) => {
  const { milestone } = useSelector((state) => state.project);

  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Handle timeline change
  const handleChange = (dateString) => {
    // Set the parent state
    setValues({
      ...values,
      startDate:
        dateString.length > 0
          ? dateString[0]
          : moment(values.startDate).format("YYYY/MM/DD"),
      endDate:
        dateString.length > 0
          ? dateString[1]
          : moment(values.endDate).format("YYYY/MM/DD"),
    });

    // Update the task with the new start and end date
    handleUpdateTask(
      "startDate",
      dateString.length > 0
        ? dateString[0]
        : moment(values.startDate).format("YYYY/MM/DD")
    );
    handleUpdateTask(
      "endDate",
      dateString.length > 0
        ? dateString[1]
        : moment(values.endDate).format("YYYY/MM/DD")
    );
  };

  return (
    <Row
      style={{
        marginBottom: "0.7rem",
      }}
    >
      <Dropdown
        getPopupContainer={(trigger) => trigger.parentNode}
        overlay={
          <>
            <Tooltip
              placement="topLeft"
              overlayStyle={{ maxWidth: "500px" }}
              title={
                <>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Milestone Timeline{" "}
                  </div>
                  <div>
                    {new Date(milestone?.startDate).toLocaleDateString(
                      "en-US",
                      dateFormat
                    )}
                    {" - "}
                    {new Date(milestone?.endDate).toLocaleDateString(
                      "en-US",
                      dateFormat
                    )}
                  </div>
                </>
              }
            >
              <Row>
                <Col span={24}>
                  {values?.startDate ? (
                    <DatePicker.RangePicker
                      name="dateRange"
                      onChange={(date, dateString) => {
                        handleChange(dateString);
                      }}
                      defaultValue={[values.startDate, values.endDate]}
                      autoFocus
                      popupStyle={{
                        height: "auto",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      style={{
                        width: "100%",
                      }}
                      popupAlign={{
                        offset: [0, 0],
                      }}
                    />
                  ) : (
                    <DatePicker.RangePicker
                      name="dateRange"
                      onChange={(date, dateString) => {
                        handleChange(dateString);
                      }}
                      autoFocus
                      popupStyle={{
                        height: "auto",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      style={{
                        width: "100%",
                      }}
                      popupAlign={{
                        offset: [0, 0],
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Tooltip>
          </>
        }
      >
        <Button
          shape="round"
          style={{
            background: "linear-gradient(to right, #839cff, #b1f6ff)",
          }}
          icon={
            <BsClockHistory
              style={{
                marginRight: "0.5rem",
              }}
            />
          }
          size={"small"}
        >
          Timeline
        </Button>
      </Dropdown>
    </Row>
  );
};

export default Timeline;
