import React, { useState, useEffect } from "react";
import {
  Row,
  Modal,
  Button,
  Radio,
  Form,
  Input,
  Select,
  DatePicker,
  Tooltip,
} from "antd";
import { getAllTeamOfProject } from "../../../api/project";
import { createMilestone } from "../../../api/milestone";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { SuccessNotification, ErrorNotification } from "../..";
import { setCurrentProjectAction } from "../../../redux/actions/projectActions";
import moment from "moment";

const initialState = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  status: "",
  assignedTo: [],
};

const CreateMilestoneModal = ({ open, handleModalClick, fetchProject }) => {
  const { id } = useParams(); // Project ID
  const dispatch = useDispatch();
  const { currentProject } = useSelector((state) => state?.project);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialState);
  const [members, setMembers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);

  // Fetch all team members
  const fetchProjectMembers = async () => {
    setLoading(true);
    const { data } = await getAllTeamOfProject(id);
    setMembers(data.members);
    setManagers(data.managers);
    setLoading(false);
  };

  useEffect(() => {
    fetchProjectMembers();
    dispatch(setCurrentProjectAction(id));
  }, []);

  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Handle form changes
  const handleFormChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setCreateLoading(true);

    // Set start and end date from date range
    values.startDate = dateRange[0];
    values.endDate = dateRange[1];

    // Make sure all the values are set
    if (
      values.name === "" ||
      values.startDate === "" ||
      values.endDate === "" ||
      values.status === ""
    ) {
      setCreateLoading(false);
      ErrorNotification("Please fill in all the required fields");
      return;
    }

    // Name length validation
    if (values.name.length > 100) {
      setCreateLoading(false);
      ErrorNotification("Milestone name cannot be more than 100 characters");
      return;
    }
    // Description length validation
    if (values.description.length > 500) {
      setCreateLoading(false);
      ErrorNotification(
        "Milestone description cannot be more than 500 characters"
      );
      return;
    }

    createMilestone(id, values)
      .then((res) => {
        setCreateLoading(false);
        SuccessNotification(`${res.data.name} added successfully`);
      })
      .then(() => {
        fetchProject();
        handleModalClick();
      })
      .catch((err) => {
        setCreateLoading(false);
        ErrorNotification(err.response.data.message);
        console.log("CREATE_MILESTONE_ERROR", err);
      });
  };

  return (
    <>
      <Modal
        title={
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Create Milestone
          </h2>
        }
        visible={open}
        width={750}
        style={{
          top: "0.5rem",
        }}
        centered
        onOk={handleModalClick}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleFormSubmit}
            loading={createLoading}
          >
            Create
          </Button>,
        ]}
      >
        {loading ? (
          <div className="clock-loader"></div>
        ) : (
          <>
            <Form
              labelCol={{
                span: 5,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              {/* Milestone name */}
              <Form.Item label="Name" required>
                <Input
                  name="name"
                  placeholder="Milestone name"
                  onChange={handleFormChange}
                  value={values.name}
                  rules={[{ required: true }]}
                />
              </Form.Item>

              {/* Milestone description */}
              <Form.Item label="Description">
                <Input.TextArea
                  name="description"
                  placeholder="Milestone description"
                  onChange={handleFormChange}
                  value={values.description}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>

              {/* Milestone start and end date */}
              <Form.Item label="Timeline" required>
                <Tooltip
                  placement="topLeft"
                  overlayStyle={{ maxWidth: "500px" }}
                  title={
                    <>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Project Timeline{" "}
                      </div>
                      <div>
                        {new Date(
                          currentProject?.project?.startDate
                        ).toLocaleDateString("en-US", dateFormat)}
                        {" - "}
                        {new Date(
                          currentProject?.project?.endDate
                        ).toLocaleDateString("en-US", dateFormat)}
                      </div>
                    </>
                  }
                >
                  <Row>
                    <DatePicker.RangePicker
                      name="dateRange"
                      onChange={(date, dateString) => {
                        setDateRange(dateString);
                      }}
                      autoFocus
                      popupStyle={{
                        height: "auto",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      style={{
                        width: "100%",
                      }}
                      popupAlign={{
                        offset: [0, 0],
                      }}
                    />
                  </Row>
                </Tooltip>
              </Form.Item>

              {/* Milestone status */}
              <Form.Item label="Status" required>
                <Radio.Group
                  name="status"
                  onChange={(e) => handleFormChange(e)}
                  value={values.status}
                >
                  <Radio.Button value="In Progress">In Progress</Radio.Button>
                  <Radio.Button value="Completed">Completed</Radio.Button>
                  <Radio.Button value="On Hold">On Hold</Radio.Button>
                  <Radio.Button value="Cancelled">Cancelled</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {/* Add milestone members */}
              {members && (
                <Form.Item label="Members">
                  <Select
                    mode="multiple"
                    showSearch
                    name="assignedTo"
                    placeholder={
                      members.length === 0
                        ? "No members available"
                        : "Select members"
                    }
                    onChange={(member) => {
                      setValues({
                        ...values,
                        assignedTo: member,
                      });
                    }}
                    value={values.assignedTo}
                    disabled={members.length === 0}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {members.map((member) => (
                      <Select.Option key={member._id} value={member._id}>
                        {member.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              {/* Add milestone managers */}
              {managers && (
                <Form.Item label="Managers">
                  <Select
                    mode="multiple"
                    showSearch
                    name="managers"
                    placeholder={
                      managers.length === 0
                        ? "No managers available"
                        : "Select managers"
                    }
                    onChange={(manager) => {
                      setValues({
                        ...values,
                        managers: manager,
                      });
                    }}
                    value={values.managers}
                    disabled={managers.length === 0}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {managers.map((manager) => (
                      <Select.Option key={manager._id} value={manager._id}>
                        {manager.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default CreateMilestoneModal;
