import React from "react";
import { Row, Col } from "antd";

const Timeline = ({ values }) => {
  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <>
      <Row>
        <h4>
          Timeline{" "}
        </h4>
      </Row>
      <Row>
        {/* Start Date */}
        <Col span={24}>
          <p>
            <strong>Start date: </strong>
            {values.startDate !== ""
              ? new Date(values.startDate).toLocaleDateString(
                  "en-US",
                  dateFormat
                )
              : "Not set"}
          </p>
        </Col>
        {/* End Date */}
        <Col span={24}>
          <p>
            <strong>End date: </strong>
            {values.endDate !== ""
              ? new Date(values.endDate).toLocaleDateString("en-US", dateFormat)
              : "Not set"}
          </p>
        </Col>
        {/* Days Left */}
        <Col span={24}>
          <p>
            <strong>Days left: </strong>
            {values.endDate !== ""
              ? Math.floor(
                  (new Date(values.endDate).getTime() - new Date().getTime()) /
                    (1000 * 60 * 60 * 24) >
                    0
                    ? Math.floor(
                        (new Date(values.endDate).getTime() -
                          new Date().getTime()) /
                          (1000 * 60 * 60 * 24)
                      )
                    : 0
                )
              : "N/A"}
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Timeline;
