import React, { useEffect, useState } from "react";
import { EventCalendar } from "../../../components";
import { Layout, Row, Col, Card } from "antd";
import { getMemberEventsAction } from "../../../redux/actions/memberActions";
import { useSelector, useDispatch } from "react-redux";

const { Content } = Layout;

const MemberCalendar = () => {
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.member);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMemberEvents();
  }, []);

  // Fetch all member events
  const fetchMemberEvents = async () => {
    setLoading(true);
    // Dispatch the get member events action
    dispatch(getMemberEventsAction(setLoading));
  };

  return (
    <>
      <Layout className="hp-calendar hp-mb-32">
        <Content>
          <Row>
            <Col span={24}>
              <Row
                style={{
                  marginTop: "-1rem",
                  marginBottom: "0.6rem",
                }}
              >
                <h3 className="hp-mb-16">My Calendar</h3>
              </Row>
              <Card
                style={{
                  marginTop: "-1rem",
                  marginBottom: "0.6rem",
                }}
              >
                <Row>
                  <Col flex="1 1" className="hp-py-24">
                    <EventCalendar
                      events={events}
                      fetchEvents={fetchMemberEvents}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};
export default MemberCalendar;
