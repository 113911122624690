import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Tag, Input } from "antd";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { getTasksOfMilestoneAction } from "../../../redux/actions/projectActions";
import { TaskLeftCol, TaskRightCol } from "./components";
import {
  getTaskById,
  updateTaskById,
  updateTaskAssignees,
} from "../../../api/task";
import { ErrorNotification } from "../../../components";
import moment from "moment";

const initialState = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  estimatedHours: "",
  stage: "",
  priority: "",
  assignedTo: [],
  checklists: [],
  attachments: [],
  comments: [],
  logs: [],
};

const ViewTaskModal = ({ open, handleModalClick, taskId }) => {
  const { id, mid } = useParams(); // Project ID & Milestone ID
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  // Edit task name
  const [editTaskNameMode, setEditTaskNameMode] = useState(false);
  const [editTaskId, setEditTaskId] = useState(null);
  const [editTaskName, setEditTaskName] = useState("");
  // Update task loading
  const [updateTaskLoading, setUpdateTaskLoading] = useState(false);

  useEffect(() => {
    fetchTask();
  }, []);

  // Get task by id
  const fetchTask = async () => {
    try {
      setLoading(true);
      const { data } = await getTaskById(taskId);
      data.startDate = moment(data.startDate);
      data.endDate = moment(data.endDate);

      setValues(data);

      // Modify the data to match the format of react-select
      const modifiedData = data.assignedTo.map((member) => ({
        name: member.name,
        email: member.email,
        _id: member._id,
      }));

      setValues({
        ...data,
        assignedTo: modifiedData,
      });
      setLoading(false);
    } catch (error) {
      console.log("GET_TASK_BY_ID_ERROR", error);
    }
  };

  // Handle set value with update API call
  const handleUpdateTask = (field, newValue) => {
    setUpdateTaskLoading(true);

    if (field === "assignedTo") {
      // Update the task with the new assignee/s
      updateTaskAssignees(id, mid, taskId, { assignedTo: newValue })
        .then(() => {
          setUpdateTaskLoading(false);
          dispatch(getTasksOfMilestoneAction(mid, setLoading));
        })
        .catch((error) => {
          setUpdateTaskLoading(false);
          console.log("UPDATE_TASK_ASSIGNEES_ERROR", error);
        });
    } else {
      let updatedValues = {
        ...values,
        assignedTo: values.assignedTo.map((assignee) => assignee._id),
        [field]: newValue,
      };
      // Make the API call to update the task
      updateTaskById(id, mid, taskId, updatedValues)
        .then(() => {
          setUpdateTaskLoading(false);
          dispatch(getTasksOfMilestoneAction(mid, setLoading));
        })
        .catch((err) => {
          setUpdateTaskLoading(false);
          console.log("UPDATE_TASK_ERROR", err);
        });
    }
  };

  // Handle edit task name
  const handleEditTaskName = () => {
    // Task name should be less than 80 characters
    if (editTaskName.length > 80) {
      return ErrorNotification("Task name must be less than 80 characters");
    }

    setValues({
      ...values,
      name: editTaskName,
    });

    handleUpdateTask("name", editTaskName);
    setEditTaskNameMode(false);
    setEditTaskId(null);
    setEditTaskName("");
  };

  return (
    <>
      <Modal
        title={
          <Row justify="space-between">
            {/* Task Name */}
            {editTaskNameMode && editTaskId === taskId ? (
              <>
                <Col span={20}>
                  <Input.TextArea
                    autoSize={{
                      minRows: 2,
                      maxRows: 2,
                    }}
                    style={{
                      marginBottom: "0.2rem",
                    }}
                    value={editTaskName}
                    onChange={(e) => {
                      setEditTaskName(e.target.value);
                    }}
                  />
                </Col>

                <Col
                  span={4}
                  style={{
                    marginLeft: "0.4rem",
                    marginTop: "0.8rem",
                    marginBottom: "0.8rem",
                  }}
                >
                  <Button
                    type="primary"
                    size="small"
                    style={{
                      marginRight: "0.7rem",
                    }}
                    disabled={editTaskName === ""}
                    onClick={() => {
                      handleEditTaskName();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditTaskNameMode(false);
                      setEditTaskId(null);
                      setEditTaskName("");
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </>
            ) : (
              <h4
                onClick={() => {
                  setEditTaskNameMode(true);
                  setEditTaskId(taskId);
                  setEditTaskName(values.name);
                }}
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {values.name}
              </h4>
            )}

            {/* Task Priority */}
            <Tag
              style={{
                fontWeight: "bold",
                height: "20px",
                marginRight: "4rem",
                marginTop: "0.6rem",
              }}
              color={
                values.priority === "Critical"
                  ? "red"
                  : values.priority === "High"
                  ? "orange"
                  : values.priority === "Medium"
                  ? "yellow"
                  : "blue"
              }
            >
              {values.priority}
            </Tag>
          </Row>
        }
        visible={open}
        onCancel={handleModalClick}
        onOk={handleModalClick}
        width={1000}
        centered
        style={{
          top: "0.5rem",
        }}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Close
          </Button>,
        ]}
      >
        {loading ? (
          <div className="clock-loader"></div>
        ) : (
          <>
            <Row justify="space-between">
              <Col span={15}>
                <TaskLeftCol
                  values={values}
                  setValues={setValues}
                  handleUpdateTask={handleUpdateTask}
                  taskId={taskId}
                  updateTaskLoading={updateTaskLoading}
                />
              </Col>

              <Col span={6}>
                <TaskRightCol
                  values={values}
                  setValues={setValues}
                  handleUpdateTask={handleUpdateTask}
                  taskId={taskId}
                  handleModalClick={handleModalClick}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default ViewTaskModal;
