import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  ListCardParent,
  ListTitleContainer,
  ListCardContainer,
  ListCard,
} from "./style";

import Title from "./title";
import TodoCard from "./card";
import InputContainer from "./inputContainer";

const List = ({
  list,
  index,
  createModalOpen,
  setCreateModalOpen,
  handleViewTodoClick,
}) => {
  return (
    <Draggable draggableId={list.id} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <ListCardParent {...provided.dragHandleProps}>
            {/* --------------- LIST TITLE ---------------  */}
            <ListTitleContainer>
              <Title title={list.title} listId={list.id} />
            </ListTitleContainer>
            <ListCardContainer>
              <Droppable droppableId={list.id} type="task">
                {/* ---------------  CARD ---------------  */}
                {(provided) => (
                  <ListCard
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {list.cards.map((card, index) => (
                      <TodoCard
                        key={card.id}
                        card={card}
                        index={index}
                        handleViewTodoClick={handleViewTodoClick}
                      />
                    ))}
                    {provided.placeholder}
                  </ListCard>
                )}
              </Droppable>
            </ListCardContainer>
            {/* ---------------  ADD TASK BUTTON ---------------  */}
            <InputContainer
              listId={list.id}
              type="card"
              createModalOpen={createModalOpen}
              setCreateModalOpen={setCreateModalOpen}
            />
          </ListCardParent>
        </div>
      )}
    </Draggable>
  );
};

export default List;
