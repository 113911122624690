import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  getTasksOfMilestoneAction,
  getAssigneesOfTaskAction,
} from "../../../../../redux/actions/projectActions";
import { KanbanContainer } from "../../../../../components";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { Row, Col } from "antd";

const Kanban = ({ history }) => {
  const { id, mid } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTaskAssignees();
    dispatch(getTasksOfMilestoneAction(mid, setLoading));
  }, []);

  // Fetch task assignees
  const fetchTaskAssignees = async () => {
    setLoading(true);
    dispatch(getAssigneesOfTaskAction(id, mid));
  };

  return (
    <>
      {loading ? (
        <div
          className="clock-loader"
          style={{ top: "calc(50vh - 100px)" }}
        ></div>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <BiArrowBack
                style={{
                  fontSize: "2em",
                  cursor: "pointer",
                  marginTop: "-3em",
                }}
                onClick={() => history.goBack()}
              />
              <div
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <KanbanContainer />
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Kanban;
