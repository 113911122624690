import { PROJECT_TYPES } from "../constants/actionTypes";

// Initial state
const initialState = {};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_TYPES.GET_TASKS:
      return {
        ...state,
        milestone: action?.payload.milestone,
        tasks: action?.payload.tasks,
        kanbanObj: action?.payload.kanbanObj,
      };
    case PROJECT_TYPES.CURRENT_PROJECT:
      return { ...state, currentProject: action?.payload };
    case PROJECT_TYPES.GET_TASK_ASSIGNEES:
      return { ...state, taskAssignees: action?.payload };
    default:
      return state;
  }
};

export default projectReducer;
