import React, { useState, useEffect } from "react";
import { EventCalendar } from "../../../components";
import { Layout, Row, Col, Card } from "antd";
import {
  getAdminEventsAction,
  updateAdminTutStatusAction,
} from "../../../redux/actions/adminActions";
import { useSelector, useDispatch } from "react-redux";
// Joyride
import Joyride, { STATUS } from "react-joyride";
import { calendarSteps } from "../../../helpers/joyride/calendarSteps";

const { Content } = Layout;

const AdminCalendar = () => {
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.admin);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const [tutFinished, setTutFinished] = useState(
    JSON.parse(localStorage.getItem("joyride"))?.calendar
  );

  useEffect(() => {
    fetchAdminEvents();
  }, []);

  // Fetch all admin events
  const fetchAdminEvents = async () => {
    setLoading(true);
    // Dispatch the get admin projects action
    dispatch(getAdminEventsAction(setLoading));
  };

  // Handle joyride finish
  const handleJoyrideCallback = (data) => {
    if (data.status === STATUS.FINISHED) {
      setTutFinished(true);
      const pageToUpdate = {
        calendar: true,
      };

      // If user is admin
      if (user?.role === "admin") {
        dispatch(updateAdminTutStatusAction(pageToUpdate));
      }

      // Get the joyride object from local storage
      const joyrideObj = JSON.parse(localStorage.getItem("joyride"));

      // Update the joyride object
      joyrideObj.calendar = true;

      // Set the joyride object to local storage
      localStorage.setItem("joyride", JSON.stringify(joyrideObj));
    }
  };

  return (
    <>
      {/* <Joyride
        steps={calendarSteps}
        run={!tutFinished}
        continuous
        showProgress={true}
        showSkipButton={false}
        // Run only once
        callback={(data) => {
          handleJoyrideCallback(data);
        }}
        disableScrolling
        disableOverlayClose
        disableCloseOnEsc
        spotlightClicks
      /> */}
      <Layout className="hp-calendar hp-mb-32">
        <Content>
          <Row>
            <Col span={24}>
              <Row
                style={{
                  marginTop: "-1rem",
                  marginBottom: "0.6rem",
                }}
              >
                <h3 className="hp-mb-16">My Calendar</h3>
              </Row>
              <Card
                className="joy-cal"
                style={{
                  marginTop: "-1rem",
                  marginBottom: "0.6rem",
                }}
              >
                <Row>
                  <Col
                    flex="1 1"
                    className="hp-py-24"
                    style={{
                      overflowY: "auto",
                      height: "calc(100vh - 145px)",
                    }}
                  >
                    <EventCalendar
                      events={events}
                      fetchEvents={fetchAdminEvents}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};
export default AdminCalendar;
