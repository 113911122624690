import api from "./index";

// Check current user
export const currentMember = () => {
  return api.post("/currentMember");
};

// Get all projects of a member
export const getAllMemberProjects = async () => {
  return await api.get("/member/projects");
};

// Get a project by id of a member
export const getMemberProjectById = async (id) => {
  return await api.get(`/member/project/${id}`);
};

// Get all milestones of a project of a member
export const getAllMemberProjectMilestones = async (id) => {
  return await api.get(`/member/project/${id}/milestones`);
};

// Get a milestone by id of a member
export const getMemberProjectMilestoneById = async (id, mid) => {
  return await api.get(`/member/project/${id}/milestone/${mid}`);
};

// Get all tasks of a milestone of a member
export const getAllMemberProjectMilestoneTasks = async (id, mid) => {
  return await api.get(`/member/project/${id}/milestone/${mid}/tasks`);
};

// Get all team members of a member
export const getAllMemberTeamMembers = async () => {
  return await api.get("/member/team");
};

// Search member team members
export const searchMemberTeamMembers = async (query) => {
  return await api.get(`/member/team/search?keyword=${query}`);
};

// Create member event
export const createMemberEvent = async (values) => {
  return await api.post("/member/events", values);
};

// Get all events of a member
export const getMemberEvents = async () => {
  return await api.get("/member/events");
};

// Update member event by id
export const updateMemberEventById = async (id, values) => {
  return await api.put(`/member/event/${id}`, values);
};

// Delete member event by id
export const deleteMemberEventById = async (id) => {
  return await api.delete(`/member/event/${id}`);
};

// Get member analytics
export const getMemberAnalytics = async () => {
  return await api.get("/member/analytics");
};
