import React from "react";

const Title = ({ title }) => {
  return (
    <>
      <h3
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        {title}
      </h3>
    </>
  );
};

export default Title;
