import React from "react";
import { Col, Row, Switch, Select } from "antd";
import { useSelector } from "react-redux";

const { Option } = Select;

const ProjectSection = ({
  dailyAdminProjectReport,
  setDailyAdminProjectReport,
  weeklyAdminProjectReport,
  setWeeklyAdminProjectReport,
  dailyManagerProjectReport,
  setDailyManagerProjectReport,
  weeklyManagerProjectReport,
  setWeeklyManagerProjectReport,
  pjtDeadlineReminder,
  setPjtDeadlineReminder,
  pjtDeadlineReminderTo,
  setPjtDeadlineReminderTo,
  pjtDeadlineReminderDays,
  setPjtDeadlineReminderDays,
}) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <>
      <h4
        style={{
          marginBottom: "1.5rem",
        }}
      >
        Project
      </h4>
      {/* Project  */}
      {/* Daily Admin Project Report */}
      {user?.role === "admin" && (
        <Row
          align="middle"
          style={{
            marginBottom: "30px",
          }}
        >
          <Col
            sm={15}
            span={12}
            className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
          >
            Send daily project report email to admin (Mon - Fri)
          </Col>

          <Switch
            className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
            checked={dailyAdminProjectReport}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={(checked) => setDailyAdminProjectReport(checked)}
          />
        </Row>
      )}

      {/* Weekly Admin Project Report */}
      {user?.role === "admin" && (
        <Row
          align="middle"
          style={{
            marginBottom: "30px",
          }}
        >
          <Col
            sm={15}
            span={12}
            className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
          >
            Send weekly project report email to admin (Sunday)
          </Col>

          <Switch
            className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
            checked={weeklyAdminProjectReport}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={(checked) => setWeeklyAdminProjectReport(checked)}
          />
        </Row>
      )}

      {/* Daily Manager Project Report */}
      <Row
        align="middle"
        style={{
          marginBottom: "30px",
        }}
      >
        <Col
          sm={15}
          span={12}
          className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
        >
          Send daily project report email to managers (Mon - Fri)
        </Col>

        <Switch
          className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
          checked={dailyManagerProjectReport}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={(checked) => setDailyManagerProjectReport(checked)}
        />
      </Row>

      {/* Weekly Managers Project Report */}
      <Row
        align="middle"
        style={{
          marginBottom: "30px",
        }}
      >
        <Col
          sm={15}
          span={12}
          className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
        >
          Send weekly project report email to managers (Sunday)
        </Col>

        <Switch
          className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
          checked={weeklyManagerProjectReport}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={(checked) => setWeeklyManagerProjectReport(checked)}
        />
      </Row>

      {/* Project Deadline Reminder */}
      <Row
        align="middle"
        style={{
          marginBottom: "20px",
        }}
      >
        <Col
          sm={15}
          span={12}
          className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
        >
          Project deadline reminder
        </Col>

        <Switch
          className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
          checkedChildren="On"
          unCheckedChildren="Off"
          checked={pjtDeadlineReminder}
          onChange={(checked) => setPjtDeadlineReminder(checked)}
        />
      </Row>

      {pjtDeadlineReminder && (
        <Row
          align="middle"
          justify="space-between"
          style={{
            marginBottom: "20px",
          }}
        >
          <Col
            sm={15}
            span={16}
            className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
          >
            Send deadline reminders to
          </Col>

          {/* Multiple Select with 1 Day, 3 Days, 7 Days Options */}
          <Col sm={9} span={8}>
            <Select
              mode="multiple"
              style={{
                width: "700px",
              }}
              disabled={!pjtDeadlineReminder}
              onChange={(value) => setPjtDeadlineReminderTo(value)}
              value={pjtDeadlineReminderTo}
            >
              <Option value="admin">Admin</Option>
              <Option value="managers">Managers</Option>
              <Option value="members">Members</Option>
            </Select>
          </Col>
        </Row>
      )}

      {pjtDeadlineReminder && (
        <Row
          align="middle"
          justify="space-between"
          style={{
            marginBottom: "40px",
          }}
        >
          <Col
            sm={15}
            span={16}
            className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
          >
            Deadline email reminder frequency
          </Col>

          {/* Multiple Select with 1 Day, 3 Days, 7 Days Options */}
          <Col sm={9} span={8}>
            <Select
              mode="multiple"
              style={{
                width: "700px",
              }}
              disabled={!pjtDeadlineReminder}
              onChange={(value) => setPjtDeadlineReminderDays(value)}
              value={pjtDeadlineReminderDays}
            >
              <Option value="1">1 Day before</Option>
              <Option value="3">3 Days before</Option>
              <Option value="7">7 Days before</Option>
            </Select>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProjectSection;
