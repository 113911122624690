import api from "./index";

// Create a comment for a task
export const createCommentForTask = (tid, comment) => {
  return api.post(`/comment/task/${tid}`, comment);
};

// Edit a comment by id
export const editCommentById = (cid, comment) => {
  return api.put(`/comment/${cid}`, comment);
};

// Delete comment of a task
export const deleteCommentOfTask = (tid, cid) => {
  return api.delete(`/comment/task/${tid}/${cid}`);
};

// Create a comment for a task
export const createCommentForTodo = (tid, comment) => {
  return api.post(`/comment/todo/${tid}`, comment);
};

// Delete comment of a task
export const deleteCommentOfTodo = (tid, cid) => {
  return api.delete(`/comment/todo/${tid}/${cid}`);
};
