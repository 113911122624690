import React, { useState, useEffect } from "react";
import {
  CreateProjectModal,
  EditProjectModal,
  SuccessNotification,
  ErrorNotification,
} from "../../../components";
import {
  Row,
  Col,
  Tag,
  Button,
  Modal,
  Tooltip,
  Dropdown,
  Menu,
  Input,
} from "antd";
import {
  RiInformationLine,
  RiEyeFill,
  RiEdit2Fill,
  RiDeleteBin6Fill,
  RiBarChartHorizontalFill,
} from "react-icons/ri";
import { HiDuplicate } from "react-icons/hi";
import { FiMoreVertical } from "react-icons/fi";
import { BiSearchAlt } from "react-icons/bi";
import { SettingOutlined } from "@ant-design/icons";
import { getAdminProjectsAction } from "../../../redux/actions/adminActions";
import { deleteProjectById, duplicateProject } from "../../../api/project";
import { useSelector, useDispatch } from "react-redux";
// Joyride
import Joyride, { STATUS } from "react-joyride";
import { updateAdminTutStatusAction } from "../../../redux/actions/adminActions";

const ProjectsList = ({ history }) => {
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.admin);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [editProjectId, setEditProjectId] = useState(null);
  // Projects search
  const [searchTerm, setSearchTerm] = useState("");

  const [tutFinished, setTutFinished] = useState(
    JSON.parse(localStorage.getItem("joyride"))?.projList
  );

  // Fetch all admin projects
  const fetchAdminProjects = async () => {
    setLoading(true);
    // Dispatch the get admin projects action
    dispatch(getAdminProjectsAction(setLoading));
  };

  useEffect(() => {
    fetchAdminProjects();
  }, []);

  // Handle create project modal open/close
  const handleCreateProjectClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  // Handle update project modal open/close
  const handleUpdateProjectClick = (id) => {
    setEditProjectId(id);
    setUpdateModalOpen(!updateModalOpen);
  };

  // Handle project delete
  const handleProjectDelete = (id) => {
    // Confirm delete
    Modal.confirm({
      title: "Are you sure you want to delete this project?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          // Delete event
          await deleteProjectById(id);
          // Fetch all admin projects
          fetchAdminProjects();
          // Show success notification
          SuccessNotification("Project deleted successfully");
        } catch (error) {
          // Show error notification
          ErrorNotification("Error deleting project");
        }
      },
    });
  };

  // Handle project view
  const handleProjectView = (id) => {
    history.push(`/admin/projects/view/${id}`);
  };

  // Handle view project report
  const handleViewProjectReport = (id) => {
    history.push(`/admin/project/${id}/report`);
  };

  // Handle view all projects tasks
  const handleViewProjectsTasks = () => {
    history.push(`/admin/projects/tasks`);
  };

  // Handle view projects gantt
  const handleViewProjectsGantt = () => {
    history.push(`/admin/projects/gantt`);
  };

  // Handle duplicate project
  const handleDuplicateProject = (id) => {
    // Confirm duplicate
    Modal.confirm({
      title: (
        <h5 className="hp-mb-0 hp-font-weight-500">
          Are you sure you want to duplicate this project?
        </h5>
      ),
      icon: (
        <span className="remix-icon">
          <RiInformationLine />
        </span>
      ),
      okText: "Yes",
      cancelText: "No",
      content: (
        <p className="hp-p1-body hp-text-color-black-80">
          A duplicate will create a copy of the project and all its contents
        </p>
      ),
      onOk: async () => {
        try {
          // Duplicate project
          await duplicateProject(id);
          // Fetch all admin projects
          fetchAdminProjects();
          // Show success notification
          SuccessNotification("Project duplicated successfully");
        } catch (error) {
          // Show error notification
          ErrorNotification("Error duplicating project");
        }
      },
    });
  };

  // Filtered projects by search term
  const filteredProjects = (searchTerm) => {
    return projects.filter((project) => {
      return (
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  // Create project joyride
  const steps = [
    {
      target: ".joy-proj-list-admin",
      content: (
        <div>
          <h4>This is where all your projects would be listed</h4>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
      styles: {
        options: {
          width: 400,
          zIndex: 1000,
        },
      },
    },
    {
      target: ".joy-create-proj-admin",
      content: (
        <div>
          <p>Click here to create a project</p>
        </div>
      ),
      disableBeacon: true,
      placement: "left",
      styles: {
        options: {
          width: 400,
          zIndex: 1000,
        },
      },
      locale: {
        last: "Okay",
      },
    },
  ];

  // Handle joyride finish
  const handleJoyrideCallback = (data) => {
    if (data.status === STATUS.FINISHED) {
      setTutFinished(true);
      const pageToUpdate = {
        projList: true,
      };

      // If user is admin
      if (user?.role === "admin") {
        dispatch(updateAdminTutStatusAction(pageToUpdate));
      }

      // Get the joyride object from local storage
      const joyrideObj = JSON.parse(localStorage.getItem("joyride"));

      // Update the joyride object
      joyrideObj.projList = true;

      // Set the joyride object to local storage
      localStorage.setItem("joyride", JSON.stringify(joyrideObj));
    }
  };

  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <>
      {/* Create Project Modal */}
      {createModalOpen && (
        <CreateProjectModal
          open={createModalOpen}
          handleModalClick={handleCreateProjectClick}
          history={history}
        />
      )}

      {/* Update Project Modal */}
      {updateModalOpen && (
        <EditProjectModal
          open={updateModalOpen}
          handleModalClick={handleUpdateProjectClick}
          history={history}
          projectId={editProjectId}
        />
      )}

      {/* {!loading && (
        <Joyride
          steps={steps}
          run={!tutFinished}
          continuous
          showProgress={true}
          showSkipButton={false}
          // Run only once
          callback={(data) => {
            handleJoyrideCallback(data);
          }}
          disableScrolling
          disableOverlayClose
          disableCloseOnEsc
          spotlightClicks
        />
      )} */}
      <Col>
        <Row>
          <Col span={24} xs={8} sm={16} md={24} lg={32}>
            <Row>
              <Col span={3}>
                <h3
                  style={{
                    transform: "translateX(1rem)",
                  }}
                >
                  My Projects
                </h3>
              </Col>

              <Col
                span={10}
                xs={8}
                sm={16}
                md={24}
                lg={32}
                style={{
                  textAlign: "right",
                  transform: "translateY(-2.5rem)",
                }}
              >
                {/* Search icon with input projects */}
                <Input
                  placeholder="Search projects"
                  prefix={<BiSearchAlt />}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  style={{
                    width: "20rem",
                    marginRight: "35px",
                  }}
                />

                {/* View All Tasks Button */}
                <Button
                  size="small"
                  onClick={handleViewProjectsTasks}
                  style={{
                    marginRight: "25px",
                  }}
                >
                  View All Tasks
                </Button>

                {/* View All Tasks Button */}
                <Button
                  size="small"
                  onClick={handleViewProjectsGantt}
                  style={{
                    marginRight: "50px",
                  }}
                >
                  Gantt Chart
                </Button>

                {/* Create Project Button */}
                <Button
                  type="primary"
                  size="small"
                  onClick={handleCreateProjectClick}
                  className={user?.role === "admin" && "joy-create-proj-admin"}
                  style={{
                    marginRight: "15px",
                  }}
                >
                  Create Project
                </Button>

                {/* Project Settings */}
                <Tooltip
                  placement="topLeft"
                  title="Project Settings"
                  className="hp-ml-16"
                >
                  <SettingOutlined
                    // Increase size
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      cursor: "pointer",
                      fontSize: "1.5rem",
                      transform: "translateY(0.2rem) translateX(-0.2rem)",
                    }}
                    onClick={() => {
                      history.push("/admin/project/settings");
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      {/* All projects table */}
      {loading ? (
        <div className="clock-loader"></div>
      ) : (
        <>
          <Col
            style={{
              overflowY: "auto",
              height: "calc(100vh - 200px)",
            }}
            className="joy-proj-list-admin"
          >
            {projects?.length ? (
              filteredProjects(searchTerm).map((project) => (
                <Row key={project._id} style={{ marginBottom: "1rem" }}>
                  <Col xs={8} sm={16} md={24} lg={32}>
                    <div className="hp-border-2 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius hp-bg-color-black-0 hp-bg-color-dark-100 hp-p-12">
                      <Row
                        style={{
                          transform: "translateY(1.5rem)",
                        }}
                      >
                        {/* Project name */}
                        <Col sm={24} md={3}>
                          <h5
                            style={{
                              color: "#0070f3",
                            }}
                          >
                            {project?.name}
                          </h5>
                        </Col>

                        {/* Project status */}
                        <Col sm={24} md={3}>
                          <Tag
                            color={
                              project.status === "Completed"
                                ? "#389e0d"
                                : project.status === "In Progress"
                                ? "blue"
                                : project.status === "On Hold"
                                ? "yellow"
                                : "red"
                            }
                          >
                            {project.status}
                          </Tag>
                        </Col>

                        {/* Project timeline */}
                        <Col sm={24} md={7}>
                          <p
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            <b>Timeline:</b>{" "}
                            <i>
                              {new Date(project.startDate).toLocaleDateString(
                                "en-US",
                                dateFormat
                              )}{" "}
                            </i>
                            <i>
                              -{" "}
                              {new Date(project.endDate).toLocaleDateString(
                                "en-US",
                                dateFormat
                              )}
                            </i>
                          </p>
                        </Col>

                        {/* Project actions */}
                        <Col
                          span={8}
                          xs={8}
                          sm={16}
                          md={24}
                          lg={32}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            transform: "translateY(-3rem)",
                          }}
                        >
                          <Row>
                            {/* Generate report */}
                            <Button
                              type="primary"
                              shape="round"
                              size="small"
                              style={{
                                marginRight: "8rem",
                              }}
                              onClick={() =>
                                handleViewProjectReport(project._id)
                              }
                            >
                              Generate Report
                            </Button>

                            {/* View */}
                            <Button
                              type="primary"
                              size="small"
                              style={{
                                marginRight: "15px",
                              }}
                              onClick={() => handleProjectView(project._id)}
                            >
                              View Project
                            </Button>

                            {/* 3 dots more option */}
                            <Dropdown
                              trigger={["click"]}
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    key={0}
                                    onClick={() =>
                                      handleUpdateProjectClick(project._id)
                                    }
                                    icon={
                                      <RiEdit2Fill
                                        size={16}
                                        style={{ marginRight: "14px" }}
                                      />
                                    }
                                  >
                                    Edit
                                  </Menu.Item>
                                  <Menu.Item
                                    key={1}
                                    onClick={() => handleViewProjectsTasks()}
                                    icon={
                                      <RiEyeFill
                                        size={16}
                                        style={{ marginRight: "14px" }}
                                      />
                                    }
                                  >
                                    View All Tasks
                                  </Menu.Item>
                                  <Menu.Item
                                    key={2}
                                    onClick={() => handleViewProjectsGantt()}
                                    icon={
                                      <RiBarChartHorizontalFill
                                        size={16}
                                        style={{ marginRight: "14px" }}
                                      />
                                    }
                                  >
                                    View Gantt Chart
                                  </Menu.Item>
                                  <Menu.Item
                                    key={3}
                                    onClick={() =>
                                      handleDuplicateProject(project._id)
                                    }
                                    icon={
                                      <HiDuplicate
                                        size={16}
                                        style={{ marginRight: "14px" }}
                                      />
                                    }
                                  >
                                    Duplicate Project
                                  </Menu.Item>
                                  <Menu.Item
                                    key={4}
                                    onClick={() =>
                                      handleProjectDelete(project?._id)
                                    }
                                    icon={
                                      <RiDeleteBin6Fill
                                        size={16}
                                        style={{ marginRight: "14px" }}
                                      />
                                    }
                                  >
                                    Delete
                                  </Menu.Item>
                                </Menu>
                              }
                            >
                              <Button
                                size="small"
                                style={{
                                  border: "none",
                                }}
                              >
                                <FiMoreVertical
                                  className="remix-icon"
                                  size={24}
                                />
                              </Button>
                            </Dropdown>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              ))
            ) : (
              <Col
                span={24}
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                <h3>No projects created yet</h3>
              </Col>
            )}
          </Col>
        </>
      )}
    </>
  );
};

export default ProjectsList;
