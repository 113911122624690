import React from "react";
import "./message.css";
import { format } from "timeago.js";

const Message = ({ own, message }) => {
  return (
    <div className={own ? "message own" : "message"}>
      <div className="messageTop">
        {!own && (
          <img
            className="messageImg"
            src={message.sender?.picture}
            alt="default user"
          />
        )}
        {/* Message text bubble with user name and text */}
        <div className="messageText">
          {!own && <div className="messageName">{message.sender?.name}</div>}
          <div
            className="messageText"
            style={{
              // If message is too big, move to next line
              wordWrap: "break-word",
            }}
          >
            {message?.text}
          </div>
        </div>
      </div>
      <div className="messageBottom">{format(message.createdAt)}</div>
    </div>
  );
};

export default Message;
