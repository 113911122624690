import api from "./index";

// Check current user
export const currentUser = () => {
  return api.post("/currentUser");
};

// Get an user by ID
export const getUserById = (id) => {
  return api.get(`/user/id/${id}`);
};

// Create a todo
export const createTodo = (todo) => {
  return api.post("/todo", todo);
};

// Get all todos of a user
export const getAllUserTodos = () => {
  return api.get("/todos");
};

// Get a todo by ID
export const getTodoById = (id) => {
  return api.get(`/todo/${id}`);
};

// Update a todo by ID
export const updateTodoById = (id, todo) => {
  return api.put(`/todo/${id}`, todo);
};

// Delete a todo by ID
export const deleteTodoById = (id) => {
  return api.delete(`/todo/${id}`);
};

// Delete an user account
export const deleteUserAccount = (id, value) => {
  return api.post(`/user/account/${id}`, value);
};

// Upload a todo attachment
export const uploadTodoAttachment = async (id, values, config) => {
  return await api.post(`/todo/${id}/attachment`, values, config);
};

// Update an user profile
export const updateUserProfile = (values) => {
  return api.put("/user/profile/update", values);
};
