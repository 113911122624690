import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Tag, Input } from "antd";
import { useDispatch } from "react-redux";
import { getTodosOfUserAction } from "../../../redux/actions/userActions";
import { updateTodoById, getTodoById } from "../../../api/user";
import { TodoLeftCol, TodoRightCol } from "./components";
import { ErrorNotification } from "../../../components";
import moment from "moment";

const initialState = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  stage: "",
  priority: "",
  checklists: [],
  attachments: [],
  comments: [],
  logs: [],
};

const ViewTodoModal = ({ open, handleModalClick, todoId }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  // Edit todo name
  const [editTodoNameMode, setEditTodoNameMode] = useState(false);
  const [editTodoId, setEditTodoId] = useState(null);
  const [editTodoName, setEditTodoName] = useState("");
  // Update todo loading
  const [updateTodoLoading, setUpdateTodoLoading] = useState(false);

  useEffect(() => {
    fetchTodo();
  }, []);

  // Get todo by id
  const fetchTodo = async () => {
    try {
      setLoading(true);
      const { data } = await getTodoById(todoId);
      data.startDate = moment(data.startDate);
      data.endDate = moment(data.endDate);

      setValues(data);
      setLoading(false);
    } catch (error) {
      console.log("GET_TODO_BY_ID_ERROR", error);
    }
  };

  // Handle update todo with update API call
  const handleUpdateTodo = (field, newValue) => {
    setUpdateTodoLoading(true);
    let updatedValues = {
      ...values,
      [field]: newValue,
    };

    // Make the API call to update the task
    updateTodoById(todoId, updatedValues)
      .then(() => {
        setUpdateTodoLoading(false);
        dispatch(getTodosOfUserAction());
      })
      .catch((err) => {
        setUpdateTodoLoading(false);
        console.log("UPDATE_TODO_ERROR", err);
      });
  };

  // Handle edit todo name
  const handleEditTodoName = () => {
    // Todo name should be less than 80 characters
    if (editTodoName.length > 80) {
      return ErrorNotification("Todo name must be less than 80 characters");
    }

    setValues({
      ...values,
      name: editTodoName,
    });

    handleUpdateTodo("name", editTodoName);
    setEditTodoNameMode(false);
    setEditTodoId(null);
    setEditTodoName("");
  };

  return (
    <>
      <Modal
        title={
          <Row justify="space-between">
            {/* Todo Name */}
            {editTodoNameMode && editTodoId === todoId ? (
              <>
                <Col span={20}>
                  <Input.TextArea
                    autoSize={{
                      minRows: 2,
                      maxRows: 2,
                    }}
                    style={{
                      marginBottom: "0.2rem",
                    }}
                    value={editTodoName}
                    onChange={(e) => {
                      setEditTodoName(e.target.value);
                    }}
                  />
                </Col>

                <Col
                  span={4}
                  style={{
                    marginLeft: "0.4rem",
                    marginTop: "0.8rem",
                    marginBottom: "0.8rem",
                  }}
                >
                  <Button
                    type="primary"
                    size="small"
                    style={{
                      marginRight: "0.7rem",
                    }}
                    disabled={editTodoName === ""}
                    onClick={() => {
                      handleEditTodoName();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditTodoNameMode(false);
                      setEditTodoId(null);
                      setEditTodoName("");
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </>
            ) : (
              <h4
                onClick={() => {
                  setEditTodoNameMode(true);
                  setEditTodoId(todoId);
                  setEditTodoName(values.name);
                }}
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {values.name}
              </h4>
            )}

            {/* Todo Priority */}
            <Tag
              style={{
                fontWeight: "bold",
                height: "20px",
                marginRight: "4rem",
                marginTop: "0.6rem",
              }}
              color={
                values.priority === "Critical"
                  ? "red"
                  : values.priority === "High"
                  ? "orange"
                  : values.priority === "Medium"
                  ? "yellow"
                  : "blue"
              }
            >
              {values.priority}
            </Tag>
          </Row>
        }
        visible={open}
        onCancel={handleModalClick}
        onOk={handleModalClick}
        width={1000}
        centered
        style={{
          top: "0.5rem",
        }}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Close
          </Button>,
        ]}
      >
        {loading ? (
          <div className="clock-loader"></div>
        ) : (
          <>
            <Row justify="space-between">
              <Col span={15}>
                <TodoLeftCol
                  values={values}
                  setValues={setValues}
                  handleUpdateTodo={handleUpdateTodo}
                  todoId={todoId}
                  updateTodoLoading={updateTodoLoading}
                />
              </Col>

              <Col span={6}>
                <TodoRightCol
                  values={values}
                  setValues={setValues}
                  handleUpdateTodo={handleUpdateTodo}
                  todoId={todoId}
                  handleModalClick={handleModalClick}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default ViewTodoModal;
