import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button, Divider } from "antd";
import { useHistory } from "react-router";
import {
  getProjectSettings,
  saveOrUpdateProjectSettings,
} from "../../../../api/projectSettings";
import {
  ProjectSection,
  TaskSection,
  ChatSection,
  SuccessNotification,
  ErrorNotification,
} from "../../../../components";

const ProjectSettings = () => {
  const history = useHistory();
  // Project
  const [dailyAdminProjectReport, setDailyAdminProjectReport] = useState(true);
  const [weeklyAdminProjectReport, setWeeklyAdminProjectReport] =
    useState(true);
  const [dailyManagerProjectReport, setDailyManagerProjectReport] =
    useState(true);
  const [weeklyManagerProjectReport, setWeeklyManagerProjectReport] =
    useState(true);
  const [pjtDeadlineReminder, setPjtDeadlineReminder] = useState(false);
  const [pjtDeadlineReminderTo, setPjtDeadlineReminderTo] = useState([]);
  const [pjtDeadlineReminderDays, setPjtDeadlineReminderDays] = useState([]);
  // Task
  const [tskAssignEmail, setTskAssignEmail] = useState(false);
  const [tskStatusChngEmail, setTskStatusChngEmail] = useState(false);
  const [tskDeadlineReminder, setTskDeadlineReminder] = useState(false);
  const [tskDeadlineReminderDays, setTskDeadlineReminderDays] = useState([]);
  // Chat
  const [mbrChatDisabled, setMbrChatDisabled] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getProjectSettings()
      .then((res) => {
        if (res.data?.projectSettings) {
          // ------------ Project ------------
          setDailyAdminProjectReport(
            res.data.projectSettings.dailyAdminProjectReport
          );
          setWeeklyAdminProjectReport(
            res.data.projectSettings.weeklyAdminProjectReport
          );
          setDailyManagerProjectReport(
            res.data.projectSettings.dailyManagerProjectReport
          );
          setWeeklyManagerProjectReport(
            res.data.projectSettings.weeklyManagerProjectReport
          );
          setPjtDeadlineReminder(
            res.data.projectSettings.projectDeadlineReminder
          );
          setPjtDeadlineReminderTo(
            res.data.projectSettings.projectDeadlineReminderTo
          );
          setPjtDeadlineReminderDays(
            res.data.projectSettings.projectDeadlineReminderDays
          );
          // ------------  Task ------------
          setTskDeadlineReminder(res.data.projectSettings.taskDeadlineReminder);
          setTskDeadlineReminderDays(
            res.data.projectSettings.taskDeadlineReminderDays
          );
          setTskAssignEmail(
            res.data.projectSettings.taskAssignEmailToAssignees
          );
          setTskStatusChngEmail(
            res.data.projectSettings.taskStatusEmailToAssignees
          );
          // ------------  Chat ------------
          setMbrChatDisabled(res.data.projectSettings.memberChatDisabled);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        ErrorNotification(err);
      });
  }, []);

  // Handle save or update project settings
  const handleSaveOrUpdateProjectSettings = () => {
    const settings = {
      // Project
      dailyAdminProjectReport: dailyAdminProjectReport,
      weeklyAdminProjectReport: weeklyAdminProjectReport,
      dailyManagerProjectReport: dailyManagerProjectReport,
      weeklyManagerProjectReport: weeklyManagerProjectReport,
      projectDeadlineReminder: pjtDeadlineReminder,
      projectDeadlineReminderTo: pjtDeadlineReminderTo,
      projectDeadlineReminderDays: pjtDeadlineReminderDays,
      // Task
      taskAssignEmailToAssignees: tskAssignEmail,
      taskStatusEmailToAssignees: tskStatusChngEmail,
      taskDeadlineReminder: tskDeadlineReminder,
      taskDeadlineReminderDays: tskDeadlineReminderDays,
      // Chat
      memberChatDisabled: mbrChatDisabled,
    };

    // If the deadline reminder is true then the reminder days and reminder to must be required
    if (
      pjtDeadlineReminder &&
      (pjtDeadlineReminderDays.length === 0 ||
        pjtDeadlineReminderTo.length === 0)
    ) {
      ErrorNotification("Please select reminder days and reminder to");
      return;
    }

    saveOrUpdateProjectSettings(settings)
      .then(() => {
        SuccessNotification("Project settings saved successfully");
      })
      .catch(() => {
        ErrorNotification("Error saving project settings");
      });
  };

  return (
    <>
      <Card
        className="hp-border-color-black-40 hp-mb-32 hp-analytics-project-table-card hp-project-table-card joy-admin-team-inv"
        style={{
          overflowY: "auto",
          height: "calc(100vh - 120px)",
        }}
      >
        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          {/* Header Buttons */}
          <Col span={24} xs={8} sm={16} md={24} lg={32}>
            <Row justify="space-between">
              {/* Heading */}
              <Col span={17}>
                <h3
                  style={{
                    marginBottom: "0.5rem",
                  }}
                >
                  Project Settings
                </h3>
                <p className="hp-p1-body hp-mb-0">
                  Configure your project, milestone & task email reminders here.
                </p>
              </Col>

              {/* Go Back Button */}
              <Col
                span={7}
                style={{
                  textAlign: "right",
                }}
              >
                {/* Save Button */}
                <Button
                  size="small"
                  onClick={() => handleSaveOrUpdateProjectSettings()}
                  style={{
                    width: "150px",
                    marginRight: "1rem",
                    backgroundColor: "#00B87C",
                    color: "#fff",
                  }}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  type="primary"
                  style={{
                    marginRight: "15px",
                  }}
                  onClick={() => history.goBack()}
                >
                  Go Back
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider className="hp-border-color-black-40 hp-border-color-dark-80" />

        {loading ? (
          <div
            className="clock-loader"
            style={{ top: "calc(40vh - 100px)" }}
          ></div>
        ) : (
          <>
            <Row>
              <Col span={12}>
                {/* Project Settings Section */}
                <ProjectSection
                  dailyAdminProjectReport={dailyAdminProjectReport}
                  setDailyAdminProjectReport={setDailyAdminProjectReport}
                  weeklyAdminProjectReport={weeklyAdminProjectReport}
                  setWeeklyAdminProjectReport={setWeeklyAdminProjectReport}
                  dailyManagerProjectReport={dailyManagerProjectReport}
                  setDailyManagerProjectReport={setDailyManagerProjectReport}
                  weeklyManagerProjectReport={weeklyManagerProjectReport}
                  setWeeklyManagerProjectReport={setWeeklyManagerProjectReport}
                  pjtDeadlineReminder={pjtDeadlineReminder}
                  setPjtDeadlineReminder={setPjtDeadlineReminder}
                  pjtDeadlineReminderTo={pjtDeadlineReminderTo}
                  setPjtDeadlineReminderTo={setPjtDeadlineReminderTo}
                  pjtDeadlineReminderDays={pjtDeadlineReminderDays}
                  setPjtDeadlineReminderDays={setPjtDeadlineReminderDays}
                />

                <Divider className="hp-border-color-black-40 hp-border-color-dark-80" />

                {/* Task Settings Section */}
                <TaskSection
                  tskAssignEmail={tskAssignEmail}
                  setTskAssignEmail={setTskAssignEmail}
                  tskStatusChngEmail={tskStatusChngEmail}
                  setTskStatusChngEmail={setTskStatusChngEmail}
                  tskDeadlineReminder={tskDeadlineReminder}
                  setTskDeadlineReminder={setTskDeadlineReminder}
                  tskDeadlineReminderDays={tskDeadlineReminderDays}
                  setTskDeadlineReminderDays={setTskDeadlineReminderDays}
                />

                <Divider className="hp-border-color-black-40 hp-border-color-dark-80" />

                {/* Chat Settings Section */}
                <ChatSection
                  mbrChatDisabled={mbrChatDisabled}
                  setMbrChatDisabled={setMbrChatDisabled}
                />
              </Col>
            </Row>
          </>
        )}
      </Card>
    </>
  );
};

export default ProjectSettings;
