import api from "./index";

// Create a milestone
export const createMilestone = async (pid, values) => {
  return await api.post(`/project/${pid}/milestone`, values);
};

// Get a milestone by id
export const getMilestoneById = async (mid) => {
  return await api.get(`/milestone/${mid}`);
};

// Update a milestone by id
export const updateMilestoneById = async (pid, mid, values) => {
  return await api.put(`/project/${pid}/milestone/${mid}`, values);
};

// Delete a milestone by id
export const deleteMilestoneById = async (pid, mid) => {
  return await api.delete(`/project/${pid}/milestone/${mid}`);
};

// Get all members of a milestone
export const getAllMembersOfMilestone = async (id, mid) => {
  return await api.get(`/project/${id}/milestone/${mid}/members`);
};
