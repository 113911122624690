import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Select } from "antd";
import { useSelector } from "react-redux";
import { getTeamMembers } from "../../../api/admin";
import { getManagerTeamMembers } from "../../../api/manager";
import { updateProjectById } from "../../../api/project";
import { SuccessNotification, ErrorNotification } from "../..";

const initialState = {
  assignedTo: [],
  managers: [],
};

const UpdateProjectTeamModal = ({
  open,
  handleModalClick,
  currentMembers,
  currentManagers,
  projectId,
  fetchProject,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [alreadySetMembers, setAlreadySetMembers] = useState([]);
  const [alreadySetManagers, setAlreadySetManagers] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    setLoading(true);
    if (user?.role === "admin") {
      const { data } = await getTeamMembers();
      setMembers(data.members);
      setManagers(data.managers);
    } else if (user?.role === "manager") {
      const { data } = await getManagerTeamMembers();
      setMembers(data.members);
      setManagers(data.managers);
    }

    // Set current managers and members to values
    setValues({
      ...values,
      assignedTo: currentMembers,
      managers: currentManagers,
    });

    // Modify the data to match the format of select
    const modifiedAssignedTo = currentMembers.map((member) => ({
      label: member.name,
      email: member.email,
      value: member._id,
    }));

    const modifiedManagers = currentManagers.map((manager) => ({
      label: manager.name,
      email: manager.email,
      value: manager._id,
    }));

    setAlreadySetMembers(modifiedAssignedTo);
    setAlreadySetManagers(modifiedManagers);
    setLoading(false);
  };

  // Handle task create
  const handleEventUpdate = async (e) => {
    e.preventDefault();
    setUpdateLoading(true);

    const modifiedAssignedTo = values.assignedTo.map((member) => member._id);
    values.assignedTo = modifiedAssignedTo;

    const modifiedManagers = values.managers.map((manager) => manager._id);
    values.managers = modifiedManagers;

    // Update project
    updateProjectById(projectId, values)
      .then((res) => {
        SuccessNotification(`${res.data.name} updated successfully`);
        fetchProject();
        handleModalClick();
        setUpdateLoading(false);
      })
      .catch((error) => {
        setUpdateLoading(false);
        ErrorNotification("Error updating project");
        console.log("UPDATE_PROJECT_ERROR", error);
      });
  };

  return (
    <>
      <Modal
        title={
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Add/Update Project Team
          </h2>
        }
        visible={open}
        onOk={handleModalClick}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleEventUpdate}
            loading={updateLoading}
          >
            Update
          </Button>,
        ]}
      >
        {loading ? (
          <div className="clock-loader"></div>
        ) : (
          <>
            <Form
              labelCol={{
                span: 5,
              }}
              wrapperCol={{
                span: 16,
              }}
            >
              {members?.length ? (
                <Form.Item label="Members">
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select members"
                    labelInValue={true}
                    value={alreadySetMembers}
                    onChange={(value) => {
                      setAlreadySetMembers(value);

                      const modifiedMembers = value.map((member) => ({
                        _id: member.value,
                        name: member.label,
                      }));

                      setValues({
                        ...values,
                        assignedTo: modifiedMembers,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {members.map((member) => (
                      <Select.Option
                        key={member._id}
                        value={member._id}
                        label={member.name}
                      >
                        {member.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item label="Members">
                  <Select
                    placeholder="No members available"
                    disabled={members?.length === 0}
                  ></Select>
                </Form.Item>
              )}

              {managers?.length ? (
                <Form.Item label="Managers">
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select managers"
                    labelInValue={true}
                    value={alreadySetManagers}
                    onChange={(value) => {
                      setAlreadySetManagers(value);

                      const modifiedManagers = value.map((manager) => ({
                        _id: manager.value,
                        name: manager.label,
                      }));

                      setValues({
                        ...values,
                        managers: modifiedManagers,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {managers.map((manager) => (
                      <Select.Option
                        key={manager._id}
                        value={manager._id}
                        label={manager.name}
                      >
                        {manager.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item label="Managers">
                  <Select
                    placeholder="No managers available"
                    disabled={managers?.length === 0}
                  ></Select>
                </Form.Item>
              )}
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default UpdateProjectTeamModal;
