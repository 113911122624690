import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import { DashboardAnalytics } from "../../../components";
import { getMemberAnalyticsAction } from "../../../redux/actions/memberActions";

const MemberDashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMemberAnalytics();
  }, []);

  // Fetch all member analytics
  const fetchMemberAnalytics = async () => {
    setLoading(true);
    // Dispatch the get admin analytics action
    dispatch(getMemberAnalyticsAction(setLoading));
  };

  return (
    <>
      {loading ? (
        <div
          className="clock-loader"
          style={{ top: "calc(50vh - 100px)" }}
        ></div>
      ) : (
        <>
          <Row gutter={[32, 0]}>
            <Col span={24}>
              <h3>Welcome, {user?.name.split(" ")[0]} 👋</h3>
            </Col>

            <DashboardAnalytics />
          </Row>
        </>
      )}
    </>
  );
};

export default MemberDashboard;
