import api from "./index";

// Create checklist for a task
export const createChecklistForTask = (tid, values) => {
  return api.post(`/checklist/task/${tid}`, values);
};

// Add an item to a checklist
export const addItemToChecklist = (cid, values) => {
  return api.post(`/checklist/item/${cid}`, values);
};

// Update an item of a checklist
export const updateItemOfChecklist = (cid, iid, values) => {
  return api.put(`/checklist/item/${cid}/${iid}`, values);
};

// Delete an item of a checklist
export const deleteItemOfChecklist = (cid, iid) => {
  return api.delete(`/checklist/item/${cid}/${iid}`);
};

// Delete a checklist of a task
export const deleteChecklistOfTask = (tid, cid) => {
  return api.delete(`/checklist/task/${tid}/${cid}`);
};

// Update a checklist name
export const updateChecklistName = (cid, values) => {
  return api.put(`/checklist/name/${cid}`, values);
};

// Create checklist for a todo
export const createChecklistForTodo = (tid, values) => {
  return api.post(`/checklist/todo/${tid}`, values);
};

// Delete a checklist of a todo
export const deleteChecklistOfTodo = (tid, cid) => {
  return api.delete(`/checklist/todo/${tid}/${cid}`);
};
