import api from "./index";

// Check if the user is admin
export const currentAdmin = async () => {
  return await api.post("/currentAdmin", {});
};

// Get all projects of the admin
export const getAdminProjects = async () => {
  return await api.get("/admin/projects");
};

// Send team invite
export const sendTeamInvite = async (values) => {
  return await api.post("/admin/team/invite", values);
};

// Accept team invite
export const acceptTeamInvite = async (values) => {
  return await api.post("/admin/team/account/activate", values);
};

// Get all team members
export const getTeamMembers = async () => {
  return await api.get("/admin/team/members");
};

// Search for team members using query string of name or email
export const searchTeamMembers = async (query) => {
  return await api.get(`/admin/team/search?keyword=${query}`);
};

// Create admin event
export const createAdminEvent = async (values) => {
  return await api.post("/admin/events", values);
};

// Get all admin events
export const getAdminEvents = async () => {
  return await api.get("/admin/events");
};

// Update admin event by id
export const updateAdminEventById = async (id, values) => {
  return await api.put(`/admin/event/${id}`, values);
};

// Delete admin event by id
export const deleteAdminEventById = async (id) => {
  return await api.delete(`/admin/event/${id}`);
};

// Get admin analytics
export const getAdminAnalytics = async () => {
  return await api.get("/admin/analytics");
};

// Get admin subscription
export const getAdminSubscription = async () => {
  return await api.get("/admin/subscription");
};

// Get admin tutorial status
export const getAdminTutStatus = async () => {
  return await api.get("/admin/tut/status");
};

// Update admin tutorial status
export const updateAdminTutStatus = async (page) => {
  return await api.post("/admin/tut/status", page);
};

// Get team analytics
export const getTeamAnalytics = async () => {
  return await api.get("/admin/team/analytics");
};

// Get admin all projects tasks
export const getAdminAllProjectsTasks = async () => {
  return await api.get("/admin/projects/tasks");
};

// Change team member role
export const changeTeamMemberRole = async (tid, values) => {
  return await api.put(`/admin/team/role/${tid}`, values);
};
