import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Card, Row, Col } from "antd";

import List from "./list";
import { useSelector, useDispatch } from "react-redux";
import { CreateTodoModal, ViewTodoModal } from "..";
import { updateTodoStatusAction } from "../../redux/actions/userActions";

const TodosBoard = ({ loading }) => {
  const dispatch = useDispatch();
  const { kanbanObj } = useSelector((state) => state.user);
  const [data, setData] = useState(kanbanObj);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewTodoId, setViewTodoId] = useState();

  useEffect(() => {
    setData(kanbanObj);
  }, [kanbanObj]);

  // Handle create todo modal open/close
  const handleCreateTodoClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  // Handle view todo modal open/close
  const handleViewTodoClick = (tid) => {
    setViewTodoId(tid);
    setViewModalOpen(!viewModalOpen);
  };

  // On drag end
  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (type === "list") {
      const newListIds = data.listIds;

      newListIds.splice(source.index, 1);
      newListIds.splice(destination.index, 0, draggableId);

      const newState = {
        ...data,
        listIds: newListIds,
      };
      setData(newState);
      return;
    }

    const sourceList = data.lists[source.droppableId];
    const destinationList = data.lists[destination.droppableId];
    const draggingCard = sourceList.cards.filter(
      (card) => card.id === draggableId
    )[0];

    if (source.droppableId === destination.droppableId) {
      sourceList.cards.splice(source.index, 1);
      destinationList.cards.splice(destination.index, 0, draggingCard);

      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [sourceList.id]: destinationList,
        },
      };
      setData(newState);
    } else {
      sourceList.cards.splice(source.index, 1);
      destinationList.cards.splice(destination.index, 0, draggingCard);

      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [sourceList.id]: sourceList,
          [destinationList.id]: destinationList,
        },
      };

      // Dispatch update todo status action
      dispatch(
        updateTodoStatusAction(
          newState,
          newState.lists[sourceList.id].id,
          newState.lists[destinationList.id].id,
          draggingCard.id
        )
      );
      setData(newState);
    }
  };

  return (
    <>
      {/* Create Todo Modal */}
      {createModalOpen && (
        <CreateTodoModal
          open={createModalOpen}
          handleModalClick={handleCreateTodoClick}
        />
      )}

      {/* View Todo Modal */}
      {viewModalOpen && (
        <ViewTodoModal
          open={viewModalOpen}
          handleModalClick={handleViewTodoClick}
          todoId={viewTodoId}
        />
      )}

      <div
        style={{
          opacity: loading ? 0.5 : 1,
          pointerEvents: loading ? "none" : "all",
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="app" type="list" direction="horizontal">
            {(provided) => (
              <Card
                style={{
                  height: "calc(86vh - 100px)",
                }}
              >
                <Col span={24}>
                  <Row ref={provided.innerRef} {...provided.droppableProps}>
                    {data?.listIds.map((listId, index) => {
                      const list = data.lists[listId];

                      return (
                        <List
                          createModalOpen={createModalOpen}
                          setCreateModalOpen={setCreateModalOpen}
                          handleViewTodoClick={handleViewTodoClick}
                          list={list}
                          key={listId}
                          index={index}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </Row>
                </Col>
              </Card>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default TodosBoard;
