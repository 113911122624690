// Global Actions
export const AUTH_TYPES = {
  AUTH: "AUTH",
  LOGOUT: "LOGOUT",
  IS_USER: "IS_USER",
  IS_SUPER_ADMIN: "IS_SUPER_ADMIN",
  IS_ADMIN: "IS_ADMIN",
  IS_MANAGER: "IS_MANAGER",
  IS_MEMBER: "IS_MEMBER",
  IS_GUEST: "IS_GUEST",
};
