import { GUEST_TYPES } from "../constants/guestTypes";

// Initial state
const initialState = {};

const guestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GUEST_TYPES.GET_GUEST_EVENTS:
      return {
        events: action.payload.events,
      };
    default:
      return state;
  }
};

export default guestReducer;
