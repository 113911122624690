import React, { useState, useEffect } from "react";
import {
  ParentTable,
  ParentTableHead,
  SuccessNotification,
  ErrorNotification,
} from "../../../components";
import { Card, Row, Col, Tag, Spin, Menu, Modal, Dropdown, Button } from "antd";
import { FiMoreVertical } from "react-icons/fi";
import { RiEyeFill, RiDeleteBin6Line } from "react-icons/ri";
import { getAllAdmins, deleteUserAccount } from "../../../api/superAdmin";

const AdminList = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = () => {
    setLoading(true);
    getAllAdmins()
      .then((res) => {
        setAdmins(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Fetch all admins error", err);
      });
  };

  // Handle admin view
  const handleAdminView = (id) => {};

  // Handle delete user account
  const handleDeleteUserAccount = (id) => {
    // Confirm delete
    Modal.confirm({
      title: "Are you sure you want to delete this user account?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteUserAccount({ userId: id });
          // Fetch all admins
          fetchAdmins();
          // Show success notification
          SuccessNotification("User deleted successfully");
        } catch (error) {
          // Show error notification
          ErrorNotification("Error deleting user");
        }
      },
    });
  };

  return (
    <>
      <Card
        className="hp-border-color-black-40 hp-mb-32 hp-analytics-project-table-card hp-project-table-card"
        style={{ height: "100%" }}
      >
        <Row>
          <Col span={24}>
            <Row justify="space-between">
              <h3 className="hp-mb-16">All Admins</h3>
            </Row>

            {/* All admins table */}
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(80vh - 50px)",
                }}
              >
                <Spin />
              </div>
            ) : (
              <ParentTable>
                <ParentTableHead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Join Date</th>
                    <th scope="col">Plan</th>
                    <th scope="col">No. of Projects</th>
                    <th scope="col">Team Size</th>
                    <th scope="col">Action</th>
                  </tr>
                </ParentTableHead>
                <tbody>
                  {admins &&
                    admins?.map((admin) => (
                      <tr key={admin._id}>
                        <td
                          onClick={() => handleAdminView(admin._id)}
                          style={{ cursor: "pointer" }}
                        >
                          {admin.name}
                        </td>
                        <td>
                          <Tag color="blue">{admin.email}</Tag>
                        </td>
                        <td>{admin.createdAt.split("T")[0]}</td>
                        <td>{admin.currentPlan}</td>
                        <td>{admin.projects.length}</td>
                        <td>{admin.members.length + admin.managers.length}</td>
                        <td style={{ cursor: "pointer" }}>
                          {/* 3 dots more option */}
                          <Dropdown
                            trigger={["click"]}
                            overlay={
                              <Menu>
                                <Menu.Item
                                  key={1}
                                  onClick={() => handleAdminView(admin._id)}
                                  icon={
                                    <RiEyeFill
                                      size={16}
                                      style={{ marginRight: "14px" }}
                                    />
                                  }
                                >
                                  View Admin
                                </Menu.Item>

                                {/* Delete User Account */}
                                <Menu.Item
                                  key={2}
                                  onClick={() =>
                                    handleDeleteUserAccount(admin.user)
                                  }
                                  icon={
                                    <RiDeleteBin6Line
                                      size={16}
                                      style={{ marginRight: "14px" }}
                                    />
                                  }
                                >
                                  Delete Account
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <Button
                              size="small"
                              style={{
                                border: "none",
                              }}
                            >
                              <FiMoreVertical
                                className="remix-icon"
                                size={24}
                              />
                            </Button>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </ParentTable>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default AdminList;
