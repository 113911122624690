import React, { useState } from "react";
import { Row, Col, Form, Button, Input } from "antd";
import LeftContent from "./leftContent";
import { Link, useLocation } from "react-router-dom";
import { acceptTeamInvite } from "../../../api/admin";
import { SuccessNotification, ErrorNotification } from "../../../components";

const initialData = {
  name: "",
  password: "",
  confirmPassword: "",
};

const TeamInviteAccept = () => {
  const [values, setValues] = useState(initialData);
  const [success, setSuccess] = useState(false);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  // Handle form change
  const handleFormChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, password, confirmPassword } = values;

    // Validate all fields
    if (!name || !password || !confirmPassword) {
      ErrorNotification("Please fill all fields");
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      ErrorNotification("Passwords don't match");
      return;
    } else {
      acceptTeamInvite({ token, name, password })
        .then(() => {
          // Success
          SuccessNotification("Successfully! Log in to continue");
          setSuccess(true);
        })
        .catch((err) => {
          // Error
          ErrorNotification(err.response.data.type[0].message);
          console.log("TEAM_ACCEPT_ERROR", err);
        });
    }
  };

  return (
    <>
      <Row gutter={[32, 0]} className="hp-authentication-page">
        <LeftContent />
        <Col md={12}>
          <Row className="hp-h-100" align="middle" justify="center">
            <Col
              xxl={11}
              xl={15}
              lg={20}
              md={20}
              sm={24}
              className="hp-px-sm-8 hp-pt-24 hp-pb-48"
            >
              <h1>{!success && <h2>Join Team</h2>}</h1>

              {!success ? (
                <Form
                  layout="vertical"
                  name="basic"
                  className="hp-mt-sm-16 hp-mt-32"
                >
                  {/* Name */}
                  <Form.Item
                    label="Name :"
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                  >
                    <Input
                      id="validating"
                      name="name"
                      placeholder="Enter your name"
                      value={values.name}
                      onChange={handleFormChange}
                    />
                  </Form.Item>

                  {/* Password */}
                  <Form.Item
                    label="Password :"
                    className="hp-mb-8"
                    rules={[
                      { required: true, message: "Please enter your password" },
                    ]}
                  >
                    <Input.Password
                      id="warning2"
                      name="password"
                      value={values.password}
                      placeholder="Enter password"
                      onChange={handleFormChange}
                    />
                  </Form.Item>

                  {/* Confirm Password */}
                  <Form.Item
                    label="Confirm password :"
                    className="hp-mb-8"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password",
                      },
                    ]}
                  >
                    <Input.Password
                      id="warning2"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      placeholder="Re-enter password"
                      onChange={handleFormChange}
                    />
                  </Form.Item>

                  {/* Submit Button */}
                  <Form.Item className="hp-mt-16 hp-mb-8">
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                    >
                      Join
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Col className="hp-form-info">
                  <h1>Joined Successfully</h1>

                  <Link
                    className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                    to="/login"
                  >
                    {" "}
                    Login
                  </Link>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TeamInviteAccept;
