import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Button } from "antd";

const PaymentCancel = () => {
  return (
    <Row className="hp-bg-color-primary-4 hp-bg-color-dark-90 hp-text-center">
      <Col className="hp-error-content hp-py-32" span={24}>
        <Row className="hp-h-100" align="middle" justify="center">
          <Col>
            <h2 className="h1 hp-mb-sm-0 hp-mb-16">Payment failed</h2>
            <p className="hp-mb-32 hp-p1-body">Payment was not successful</p>

            <Link to="/profile/subscription">
              <Button type="primary">Go Back</Button>
            </Link>
          </Col>
        </Row>
      </Col>

      <Col span={24} className="hp-py-24">
        <p className="hp-mb-0 hp-badge-text">
          COPYRIGHT {new Date().getFullYear()} Netraga Pvt. Ltd
        </p>
      </Col>
    </Row>
  );
};

export default withRouter(PaymentCancel);
