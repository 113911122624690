import api from "./index";

// Create a chat
export const createChat = async (values) => {
  return await api.post("/chat", values);
};

// Get all chats
export const getChats = async () => {
  return await api.get("/chats");
};

// Get a chat by id
export const getChatById = async (chatId) => {
  return await api.get(`/chat/group/${chatId}`);
};

// Create a group chat
export const createGroupChat = async (values) => {
  return await api.post("/chat/group", values);
};

// Update a group chat by id
export const updateGroupChatById = async (chatId, values) => {
  return await api.put(`/chat/group/${chatId}`, values);
};
