import { USER_TYPES } from "../constants/userTypes";

// Initial state
const initialState = {};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_TYPES.GET_USER_TODOS:
      return action?.payload;
    default:
      return state;
  }
};

export default userReducer;
