import React, { useState, useEffect } from "react";
import { ParentTable, ParentTableHead } from "../../../components";
import { Card, Row, Col, Button, Modal, Spin } from "antd";
import { RiEditFill, RiDeleteBin2Fill } from "react-icons/ri";
import { getAllTags, deleteTagById } from "../../../api/tag";
import {
  SuccessNotification,
  ErrorNotification,
  TagCreateModal,
  TagUpdateModal,
} from "../../../components";

const TagsList = () => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [tagId, setTagId] = useState("");

  // Handle create tag modal
  const handleCreateModalClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  // Handle update tag modal
  const handleUpdateModalClick = (id) => {
    setTagId(id);
    setUpdateModalOpen(!updateModalOpen);
  };

  // Fetch all tags
  const fetchTags = async () => {
    setLoading(true);
    const { data } = await getAllTags();
    setTags(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  // Handle tag delete
  const handleTagDelete = (id) => {
    // Confirm delete
    Modal.confirm({
      title: "Are you sure you want to delete this tag?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteTagById(id);
          // Fetch all tags
          fetchTags();
          // Show success notification
          SuccessNotification("Tag deleted successfully");
        } catch (error) {
          // Show error notification
          ErrorNotification("Error deleting tag");
        }
      },
    });
  };

  return (
    <>
      {/* Create Tag Modal */}
      {createModalOpen && (
        <TagCreateModal
          open={createModalOpen}
          handleModalClick={handleCreateModalClick}
          fetchTags={fetchTags}
        />
      )}

      {/* Update Tag Modal */}
      {updateModalOpen && (
        <TagUpdateModal
          open={updateModalOpen}
          handleModalClick={handleUpdateModalClick}
          fetchTags={fetchTags}
          tagId={tagId}
        />
      )}

      <Card
        className="hp-border-color-black-40 hp-mb-32 hp-analytics-project-table-card hp-project-table-card"
        style={{
          overflowY: "auto",
          height: "calc(100vh - 150px)",
        }}
      >
        <Row>
          <Col span={24}>
            <Row justify="space-between">
              <h3 className="hp-mb-16">Tags List</h3>
              <Button type="primary" onClick={handleCreateModalClick}>
                Create Tag
              </Button>
            </Row>

            {/* All tags table */}
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(80vh - 50px)",
                }}
              >
                <Spin />
              </div>
            ) : (
              <ParentTable>
                <ParentTableHead>
                  <tr>
                    <th scope="col">Tag name</th>
                    <th scope="col">Action</th>
                  </tr>
                </ParentTableHead>
                <tbody>
                  {tags?.length > 0 ? (
                    tags.map((tag) => (
                      <tr key={tag._id}>
                        <td>{tag?.name}</td>
                        <td style={{ cursor: "pointer" }}>
                          {/* Edit Icon */}
                          <RiEditFill
                            onClick={() => handleUpdateModalClick(tag._id)}
                          />
                          {/* Space between icons */}
                          <span style={{ margin: "0 10px" }} />
                          {/* Delete Icon */}
                          <RiDeleteBin2Fill
                            onClick={() => handleTagDelete(tag._id)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No tags found</td>
                    </tr>
                  )}
                </tbody>
              </ParentTable>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default TagsList;
