import React from "react";

import { Row, Col } from "antd";
import {
  UpgradePlanCardOneBg,
  TotalProjectsCard,
  TotalActiveProjects,
  TeamSizeCard,
  ManageTeamCard,
  TaskCard,
  TotalMessagesCard,
  TotalFilesCard,
  DownloadCard,
} from "./components";
import { useSelector } from "react-redux";

const DashboardAnalytics = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Col span={24} className="hp-mt-32">
      <Row gutter={[32, 0]}>
        <Col flex="1" className="hp-overflow-hidden">
          <Row gutter={[32, 32]}>
            {/* Upgrade Plan Card */}
            {user?.role === "admin" && user?.currentPlan === "Free" && (
              <Col span={24}>
                <UpgradePlanCardOneBg />
              </Col>
            )}

            {/* Total Projects Card */}
            <Col md={8} span={12} className="hp-overflow-hidden">
              <TotalProjectsCard />
            </Col>

            {/* Total Active Projects */}
            <Col md={8} span={12} className="hp-overflow-hidden">
              <TotalActiveProjects />
            </Col>

            {/* Team Size Card */}
            {user?.role !== "guest" && (
              <Col sm={24} md={8} span={12} className="hp-overflow-hidden">
                <TeamSizeCard />
              </Col>
            )}

            {/* Manage Team Card */}
            <Col span={24}>
              <ManageTeamCard />
            </Col>

            {/* Total Messages Card */}
            {user?.role !== "member" && user?.role !== "guest" && (
              <Col sm={24} md={12} span={12} className="hp-overflow-hidden">
                <TotalMessagesCard />
              </Col>
            )}

            {/* Total Files Card */}
            {user?.role !== "member" && user?.role !== "guest" && (
              <Col sm={24} md={12} span={12} className="hp-overflow-hidden">
                <TotalFilesCard />
              </Col>
            )}
          </Row>
        </Col>

        <Col className="hp-analytics-col-2">
          {/* Revenue Card */}
          {user?.role !== "member" && user?.role !== "guest" && <TaskCard />}

          {/* Download Card */}
        </Col>
      </Row>
    </Col>
  );
};

export default DashboardAnalytics;
