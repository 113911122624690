import React from "react";
import { Row, Col } from "antd";

import OverviewCard from "./OverviewCard";
import MilestoneCountCard from "./MilestoneCountCard";
import TaskCountCard from "./TaskCountCard";
import CompletedMilestonesCard from "./CompletedMilestonesCard";
import CompletedTasksCard from "./CompletedTasksCard";

const ProjectOverviewCard = ({ stats, values }) => {
  // If percent complete is NaN, then set it to 0
  const percentComplete = isNaN((stats.completedTasks / stats.totalTasks) * 100)
    ? 0
    : (stats.completedTasks / stats.totalTasks) * 100;

  // Completed milestones
  const completedMilestones = values?.milestones?.filter(
    (milestone) => milestone.status === "Completed"
  );

  // Total milestones
  const totalMilestones = values?.milestones?.length;

  return (
    <>
      {/* Row 1 */}
      <Row justify="space-between">
        <Col xl={24}>
          <OverviewCard
            status={values.status}
            percentComplete={percentComplete}
            values={values}
          />
        </Col>
      </Row>

      {/* Row 2 */}
      <Row>
        <Col xl={6} sm={12} xs={24}>
          <MilestoneCountCard milestones={values.milestones} />
        </Col>

        <Col xl={6} sm={12} xs={24}>
          <CompletedMilestonesCard
            completedMilestones={completedMilestones}
            totalMilestones={totalMilestones}
          />
        </Col>

        <Col xl={6} sm={12} xs={24}>
          <TaskCountCard tasks={stats.totalTasks} />
        </Col>

        <Col xl={6} sm={12} xs={24}>
          <CompletedTasksCard
            percentComplete={percentComplete}
            completedTasks={stats.completedTasks}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProjectOverviewCard;
