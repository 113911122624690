import React from "react";
import { InputContainerContent } from "./style";

const InputContainer = ({ createModalOpen, setCreateModalOpen }) => {
  return (
    <>
      <InputContainerContent>
        <button onClick={() => setCreateModalOpen(!createModalOpen)}>
          {"+ Add Todo"}
        </button>
      </InputContainerContent>
    </>
  );
};

export default InputContainer;
