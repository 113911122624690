import React, { useState, useEffect } from "react";
import { getAllGuestProjects } from "../../../api/guest";
import { Button, Row, Col, Tag } from "antd";

const GuestProjectsList = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  // Fetch all projects
  const fetchAllGuestProjects = async () => {
    setLoading(true);
    const { data } = await getAllGuestProjects();
    setProjects(data.projects);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllGuestProjects();
  }, []);

  // Handle project view
  const handleProjectView = (id) => {
    history.push(`/guest/projects/view/${id}`);
  };

  // Handle view project report
  const handleViewProjectReport = (id) => {
    history.push(`/guest/project/${id}/report`);
  };

  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <>
      <Col>
        <Row>
          <Col span={24} xs={8} sm={16} md={24} lg={32}>
            <Row justify="space-between">
              <Col span={16} xs={8} sm={16} md={24} lg={32}>
                <h3
                  style={{
                    transform: "translateX(1rem)",
                  }}
                >
                  My Projects
                </h3>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* All projects table */}
        {loading ? (
          <div
            className="clock-loader"
            style={{ top: "calc(45vh - 100px)" }}
          ></div>
        ) : (
          <>
            {projects?.length ? (
              <Col
                style={{
                  overflowY: "auto",
                  height: "calc(100vh - 200px)",
                }}
              >
                {projects &&
                  projects?.map((project) => (
                    <Row key={project._id} style={{ marginBottom: "1rem" }}>
                      <Col xs={8} sm={16} md={24} lg={32}>
                        <div className="hp-border-2 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius hp-bg-color-black-0 hp-bg-color-dark-100 hp-p-12">
                          <Row
                            style={{
                              transform: "translateY(1.5rem)",
                            }}
                          >
                            {/* Project name */}
                            <Col sm={24} md={3}>
                              <h5
                                style={{
                                  color: "#0070f3",
                                }}
                              >
                                {project?.name}
                              </h5>
                            </Col>

                            {/* Project status */}
                            <Col sm={24} md={3}>
                              <Tag
                                color={
                                  project.status === "Completed"
                                    ? "#389e0d"
                                    : project.status === "In Progress"
                                    ? "blue"
                                    : project.status === "On Hold"
                                    ? "yellow"
                                    : "red"
                                }
                              >
                                {project.status}
                              </Tag>
                            </Col>

                            {/* Project timeline */}
                            <Col sm={24} md={7}>
                              <p
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                <b>Timeline:</b>{" "}
                                <i>
                                  {new Date(
                                    project.startDate
                                  ).toLocaleDateString(
                                    "en-US",
                                    dateFormat
                                  )}{" "}
                                </i>
                                <i>
                                  -{" "}
                                  {new Date(project.endDate).toLocaleDateString(
                                    "en-US",
                                    dateFormat
                                  )}
                                </i>
                              </p>
                            </Col>

                            {/* Project actions */}
                            <Col
                              span={8}
                              xs={8}
                              sm={16}
                              md={24}
                              lg={32}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                transform: "translateY(-3rem)",
                              }}
                            >
                              <Row>
                                {/* Generate report */}
                                <Button
                                  type="primary"
                                  shape="round"
                                  size="small"
                                  style={{
                                    marginRight: "8rem",
                                  }}
                                  onClick={() =>
                                    handleViewProjectReport(project._id)
                                  }
                                >
                                  View Report
                                </Button>

                                {/* View */}
                                <Button
                                  type="primary"
                                  size="small"
                                  style={{
                                    marginRight: "15px",
                                  }}
                                  onClick={() => handleProjectView(project._id)}
                                >
                                  View Project
                                </Button>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  ))}
              </Col>
            ) : (
              <Col
                span={24}
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                <h3>You are not a part of any project</h3>
              </Col>
            )}
          </>
        )}
      </Col>
    </>
  );
};

export default GuestProjectsList;
