import React from "react";
import { Col, Row, Input, Button } from "antd";
import { BsCardChecklist } from "react-icons/bs";

const ChecklistHeader = ({
  user,
  checklist,
  // Name
  editChecklistNameMode,
  setEditChecklistNameMode,
  editChecklistId,
  setEditChecklistId,
  editChecklistName,
  setEditChecklistName,
  editChecklistNameLoading,
  handleUpdateChecklistName,
  // Delete Checklist
  handleDeleteChecklist,
}) => {
  return (
    <>
      <Row
        justify="space-between"
        style={{
          marginBottom: "1rem",
        }}
      >
        <Col span={20}>
          <Row>
            <BsCardChecklist
              size={18}
              style={{
                marginRight: "0.7rem",
                marginTop: "0.2rem",
              }}
            />
            {editChecklistNameMode && editChecklistId === checklist._id ? (
              <>
                <Col span={20}>
                  <Input.TextArea
                    autoSize={{
                      minRows: 2,
                      maxRows: 2,
                    }}
                    style={{
                      marginBottom: "0.2rem",
                    }}
                    value={editChecklistName}
                    onChange={(e) => {
                      setEditChecklistName(e.target.value);
                    }}
                  />
                </Col>

                <Col
                  span={12}
                  style={{
                    marginLeft: "1.6rem",
                    marginTop: "0.8rem",
                    marginBottom: "0.8rem",
                  }}
                >
                  <Button
                    type="primary"
                    size="small"
                    style={{
                      marginRight: "0.7rem",
                    }}
                    disabled={editChecklistName === ""}
                    onClick={() => {
                      const body = {
                        name: editChecklistName,
                      };
                      handleUpdateChecklistName(checklist._id, body);
                    }}
                    loading={editChecklistNameLoading}
                  >
                    Save
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditChecklistNameMode(false);
                      setEditChecklistName("");
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </>
            ) : (
              <h4
                onClick={() => {
                  setEditChecklistNameMode(true);
                  setEditChecklistId(checklist._id);
                  setEditChecklistName(checklist.name);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                {checklist?.name}{" "}
              </h4>
            )}
          </Row>
        </Col>

        {/* Delete Checklist */}
        {(user?.role === "admin" || user?.role === "manager") && (
          <Col span={4}>
            <Button
              size="small"
              type="danger"
              onClick={() => {
                handleDeleteChecklist(checklist._id);
              }}
            >
              Delete
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ChecklistHeader;
