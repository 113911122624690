import React, { useEffect, useState } from "react";
import { EventCalendar } from "../../../components";
import { Layout, Row, Col, Card } from "antd";
import { getManagerEventsAction } from "../../../redux/actions/managerActions";
import { useSelector, useDispatch } from "react-redux";

const { Content } = Layout;

const ManagerCalendar = () => {
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.manager);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchManagerEvents();
  }, []);

  // Fetch all manager events
  const fetchManagerEvents = async () => {
    setLoading(true);
    // Dispatch the get manager events action
    dispatch(getManagerEventsAction(setLoading));
  };

  return (
    <>
      <Layout className="hp-calendar hp-mb-32">
        <Content>
          <Row>
            <Col span={24}>
              <Row
                style={{
                  marginTop: "-1rem",
                  marginBottom: "0.6rem",
                }}
              >
                <h3 className="hp-mb-16">My Calendar</h3>
              </Row>
              <Card
                style={{
                  marginTop: "-1rem",
                  marginBottom: "0.6rem",
                }}
              >
                <Row>
                  <Col flex="1 1" className="hp-py-24">
                    <EventCalendar
                      events={events}
                      fetchEvents={fetchManagerEvents}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};
export default ManagerCalendar;
