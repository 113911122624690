import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Checkbox } from "antd";
import { useHistory } from "react-router-dom";
import { getAllProjectsForGantt } from "../../../../api/project";
import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";

const ProjectsGantt = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [showProjects, setShowProjects] = useState(true);
  const [view, setView] = React.useState(ViewMode.Month);

  // Fetch all projects for gantt
  const fetchAllProjectsForGantt = async () => {
    setLoading(true);
    const res = await getAllProjectsForGantt();

    // For each object in the res.data, format the start and end date to
    const formattedProjects = res.data.map((item) => {
      const fStart = new Date(item.start);
      const fEnd = new Date(item.end);

      const formattedStart = new Date(
        fStart.getFullYear(),
        fStart.getMonth(),
        fStart.getDate()
      );
      const formattedEnd = new Date(
        fEnd.getFullYear(),
        fEnd.getMonth(),
        fEnd.getDate()
      );
      return {
        ...item,
        start: formattedStart,
        end: formattedEnd,
      };
    });
    setProjects(formattedProjects);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllProjectsForGantt();
  }, []);

  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleExpanderClick = (task) => {
    setProjects(projects.map((t) => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };

  const onViewModeChange = (viewMode) => {
    setView(viewMode);
  };

  return (
    <>
      <Row
        style={{
          marginTop: "-1rem",
          marginBottom: "0.6rem",
        }}
      >
        {/* Header Buttons */}
        <Col span={24} xs={8} sm={16} md={24} lg={32}>
          <Row justify="space-between">
            {/* Heading */}
            <Col span={17}>
              <h3
                style={{
                  marginBottom: "0.2rem",
                  transform: "translateX(1rem)",
                }}
              >
                Gantt Chart
              </h3>
            </Col>

            {/* Go Back Button */}
            <Col
              span={3}
              style={{
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                style={{
                  marginRight: "15px",
                }}
                onClick={() => history.goBack()}
              >
                Go Back
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {loading ? (
        <div
          className="clock-loader"
          style={{ top: "calc(50vh - 100px)" }}
        ></div>
      ) : (
        <>
          <Card>
            <Row justify="space-between">
              <Col
                style={{
                  marginTop: "-0.6rem",
                  marginBottom: "0.6rem",
                }}
              >
                <Checkbox
                  checked={showProjects}
                  onChange={(e) => setShowProjects(!showProjects)}
                >
                  Show Projects List
                </Checkbox>
              </Col>
              <Col
                style={{
                  marginTop: "-1rem",
                  marginBottom: "0.6rem",
                }}
              >
                <Button
                  onClick={() => onViewModeChange(ViewMode.QuarterDay)}
                  size="small"
                  style={{
                    marginRight: "15px",
                  }}
                >
                  Quarter Day
                </Button>
                <Button
                  onClick={() => onViewModeChange(ViewMode.HalfDay)}
                  size="small"
                  style={{
                    marginRight: "15px",
                  }}
                >
                  Half Day
                </Button>
                <Button
                  onClick={() => onViewModeChange(ViewMode.Day)}
                  size="small"
                  style={{
                    marginRight: "15px",
                  }}
                >
                  Day
                </Button>
                <Button
                  onClick={() => onViewModeChange(ViewMode.Week)}
                  size="small"
                  style={{
                    marginRight: "15px",
                  }}
                >
                  Week
                </Button>
                <Button
                  onClick={() => onViewModeChange(ViewMode.Month)}
                  size="small"
                >
                  Month
                </Button>
              </Col>
            </Row>

            {/* Gantt Chart */}
            <Col
              style={{
                overflowY: "auto",
                height: "calc(100vh - 230px)",
              }}
            >
              {projects?.length > 0 ? (
                <Gantt
                  tasks={projects}
                  viewMode={view}
                  onExpanderClick={handleExpanderClick}
                  listCellWidth={showProjects ? "135px" : ""}
                  // Style
                  columnWidth={columnWidth}
                  ganttHeight={"calc(100vh - 300px)"}
                  rowHeight={40}
                  fontSize={14}
                  barFill={60}
                  barBackgroundColor={"#4e8be6"}
                  barCornerRadius={5}
                  arrowColor={"#000105"}
                  arrowIndent={20}
                  todayColor={"#d6e5fe"}
                />
              ) : (
                <h2
                  style={{
                    position: "absolute",
                    top: "30%",
                    left: "33%",
                  }}
                >
                  Create a project to see Gantt chart
                </h2>
              )}
            </Col>
          </Card>
        </>
      )}
    </>
  );
};

export default ProjectsGantt;
