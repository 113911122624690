import superAdmin from "./superAdmin";
import admin from "./admin";
import manager from "./manager";
import member from "./member";
import guest from "./guest";

const superAdminNav = [...superAdmin];
const adminNav = [...admin];
const managerNav = [...manager];
const memberNav = [...member];
const guestNav = [...guest];

export { superAdminNav, adminNav, managerNav, memberNav, guestNav };
