import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { getAllMemberProjectMilestones } from "../../../../api/member";
import { MilestoneListCard, ViewMilestoneModal } from "../../../../components";

const MemberMilestoneList = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewMilestoneId, setViewMilestoneId] = useState();

  useEffect(() => {
    handleGetMemberMilestones();
  }, []);

  // Handle view milestone modal open/close
  const handleViewMilestoneClick = (mid) => {
    setViewModalOpen(!viewModalOpen);
    setViewMilestoneId(mid);
  };

  // Handle get member milestones
  const handleGetMemberMilestones = async () => {
    setLoading(true);
    try {
      const { data } = await getAllMemberProjectMilestones(id);
      setMilestones(data.milestones);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("GET_MEMBER_PROJECT_MILESTONES_ERROR", error);
    }
  };

  return !loading && milestones ? (
    <>
      {viewModalOpen && (
        <ViewMilestoneModal
          open={viewModalOpen}
          handleModalClick={handleViewMilestoneClick}
          mid={viewMilestoneId}
        />
      )}
      <MilestoneListCard
        milestones={milestones}
        handleMilestoneView={handleViewMilestoneClick}
      />
    </>
  ) : (
    <div className="loader"></div>
  );
};

export default MemberMilestoneList;
