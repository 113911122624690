import { useEffect, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  MemberRoute,
  ManagerRoute,
  AdminRoute,
  SuperAdminRoute,
  GuestRoute,
  ProfileRoute,
} from "./routes";
import { isUserAction } from "./redux/actions/authActions";
import "./App.scss";

import {
  // -------------- Auth views --------------
  Signup,
  AccountActivation,
  Login,
  ForgotPassword,
  NewPassword,
  Terms,
  Privacy,
  AppSumo,
  // -------------- Super Admin views --------------
  SuperAdminDashboard,
  // Admins
  AdminList,
  // Visitors
  VisitorList,
  // Leads
  LeadsList,
  // Blogs
  BlogsList,
  BlogCreate,
  BlogUpdate,
  // Plans
  PlansList,
  PlanCreate,
  PlanUpdate,
  // Coupons
  CouponsList,
  CouponCreate,
  CouponUpdate,
  // Categories
  CategoriesList,
  // Tags
  TagsList,
  // -------------- Admin views --------------
  AdminDashboard,
  // Projects
  ProjectsList,
  ProjectDetail,
  ProjectReport,
  ProjectSettings,
  AllProjectsTasks,
  ProjectsGantt,
  // Tasks (Kanban)
  Kanban,
  // Calendar
  AdminCalendar,
  // Team
  TeamList,
  TeamInviteAccept,
  TeamReport,
  // Chat
  AdminChat,
  // Subscription
  SubscriptionSuccess,
  PaymentCancel,
  // -------------- Manager views --------------
  ManagerDashboard,
  // Projects
  ManagerProjectsList,
  // Calendar
  ManagerCalendar,
  // -------------- Member views --------------
  MemberDashboard,
  // Projects
  MemberProjectsList,
  MemberProjectDetail,
  // Calendar
  MemberCalendar,
  // Chat
  MemberChat,
  // -------------- Guest views --------------
  GuestDashboard,
  // Projects
  GuestProjectsList,
  // Calendar
  GuestCalendar,
  // -------------- User views --------------
  Todos,
  // -------------- Profile Views --------------
  // Profile
  MyProfile,
  MySubscription,
  MySecurity,
  MyPassword,
  // -------------- Wildcard --------------
  RandomPageRedirect,
} from "./views";

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state?.auth);

  // If local storage "allgin" is true and is on the auth pages page, redirect to dashboard
  useEffect(() => {
    if (
      localStorage.getItem("allgin") === "true" &&
      (history.location.pathname === "/" ||
        history.location.pathname === "/signup" ||
        history.location.pathname === "/login")
    ) {
      // Dispatch the user action
      dispatch(isUserAction({ history }));
    }
  }, [dispatch, history]);

  return (
    <div className="App">
      <Suspense
        fallback={
          <>
            <div className="boxes">
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </>
        }
      >
        <Switch>
          {/*************** Common Routes ***************/}
          <Route exact path="/" component={!user && Login} />
          <Route exact path="/login" component={!user && Login} />
          <Route exact path="/signup" component={!user && Signup} />
          <Route
            exact
            path="/account/activate"
            component={!user && AccountActivation}
          />
          <Route
            exact
            path="/forgot-password"
            component={!user && ForgotPassword}
          />
          <Route exact path="/new-password" component={!user && NewPassword} />
          <Route exact path="/privacy" component={!user && Privacy} />
          <Route exact path="/terms" component={!user && Terms} />
          <Route
            exact
            path="/appsumo/signup/:code"
            component={!user && AppSumo}
          />
          {/*************** Super Admin Routes ***************/}
          <SuperAdminRoute
            exact
            path="/super-admin/dashboard"
            component={SuperAdminDashboard}
          />
          {/* Admins */}
          <SuperAdminRoute
            exact
            path="/super-admin/admins"
            component={AdminList}
          />
          {/* Visitors */}
          <SuperAdminRoute
            exact
            path="/super-admin/visitors"
            component={VisitorList}
          />
          {/* Leads */}
          <SuperAdminRoute
            exact
            path="/super-admin/leads"
            component={LeadsList}
          />
          {/* Blogs */}
          <SuperAdminRoute
            exact
            path="/super-admin/blogs"
            component={BlogsList}
          />
          <SuperAdminRoute
            exact
            path="/super-admin/blog/create"
            component={BlogCreate}
          />
          <SuperAdminRoute
            exact
            path="/super-admin/blog/:id/update"
            component={BlogUpdate}
          />
          {/* Plans */}
          <SuperAdminRoute
            exact
            path="/super-admin/plans"
            component={PlansList}
          />
          <SuperAdminRoute
            exact
            path="/super-admin/plans/create"
            component={PlanCreate}
          />
          <SuperAdminRoute
            exact
            path="/super-admin/plans/update/:id"
            component={PlanUpdate}
          />
          {/* Coupons */}
          <SuperAdminRoute
            exact
            path="/super-admin/coupons"
            component={CouponsList}
          />
          <SuperAdminRoute
            exact
            path="/super-admin/coupons/create"
            component={CouponCreate}
          />
          <SuperAdminRoute
            exact
            path="/super-admin/coupons/update/:id"
            component={CouponUpdate}
          />
          {/* Categories */}
          <SuperAdminRoute
            exact
            path="/super-admin/categories"
            component={CategoriesList}
          />
          {/* Tags */}
          <SuperAdminRoute
            exact
            path="/super-admin/tags"
            component={TagsList}
          />

          {/*************** Admin Routes ***************/}
          <AdminRoute
            exact
            path="/admin/dashboard"
            component={AdminDashboard}
          />
          {/* Project */}
          <AdminRoute exact path="/admin/projects" component={ProjectsList} />
          <AdminRoute
            exact
            path="/admin/projects/view/:id"
            component={ProjectDetail}
          />
          <AdminRoute
            exact
            path="/admin/project/:id/report"
            component={ProjectReport}
          />
          <AdminRoute
            exact
            path="/admin/project/settings"
            component={ProjectSettings}
          />
          <AdminRoute
            exact
            path="/admin/projects/tasks"
            component={AllProjectsTasks}
          />
          <AdminRoute
            exact
            path="/admin/projects/gantt"
            component={ProjectsGantt}
          />

          {/* Task (Kanban) */}
          <AdminRoute
            exact
            path="/admin/projects/view/:id/milestone/:mid/task/kanban"
            component={Kanban}
          />
          {/* Calendar */}
          <AdminRoute exact path="/admin/calendar" component={AdminCalendar} />
          {/* Todos */}
          <AdminRoute exact path="/admin/to-dos" component={Todos} />
          {/* Team */}
          <AdminRoute exact path="/admin/team" component={TeamList} />
          <AdminRoute exact path="/admin/team/report" component={TeamReport} />
          {/* Chat */}
          <AdminRoute exact path="/admin/chat" component={AdminChat} />
          <Route exact path="/team/join" component={TeamInviteAccept} />
          {/* Subscription */}
          <AdminRoute exact path="/success" component={SubscriptionSuccess} />
          <AdminRoute exact path="/cancel" component={PaymentCancel} />

          {/*************** Manager Routes ***************/}
          <ManagerRoute
            exact
            path="/manager/dashboard"
            component={ManagerDashboard}
          />
          {/* Project */}
          <ManagerRoute
            exact
            path="/manager/projects"
            component={ManagerProjectsList}
          />
          <ManagerRoute
            exact
            path="/manager/projects/view/:id"
            component={ProjectDetail}
          />
          <ManagerRoute
            exact
            path="/manager/project/:id/report"
            component={ProjectReport}
          />
          <ManagerRoute
            exact
            path="/manager/project/settings"
            component={ProjectSettings}
          />
          <ManagerRoute
            exact
            path="/manager/projects/tasks"
            component={AllProjectsTasks}
          />
          <ManagerRoute
            exact
            path="/manager/projects/gantt"
            component={ProjectsGantt}
          />
          {/* Task (Kanban) */}
          <ManagerRoute
            exact
            path="/manager/projects/view/:id/milestone/:mid/task/kanban"
            component={Kanban}
          />
          {/* Calendar */}
          <ManagerRoute
            exact
            path="/manager/calendar"
            component={ManagerCalendar}
          />
          {/* Todos */}
          <ManagerRoute exact path="/manager/to-dos" component={Todos} />
          {/* Team */}
          <ManagerRoute exact path="/manager/team" component={TeamList} />
          <ManagerRoute
            exact
            path="/manager/team/report"
            component={TeamReport}
          />
          {/* Chat */}
          <ManagerRoute exact path="/manager/chat" component={AdminChat} />

          {/*************** Member Routes ***************/}
          <MemberRoute
            exact
            path="/member/dashboard"
            component={MemberDashboard}
          />
          {/* Project */}
          <MemberRoute
            exact
            path="/member/projects"
            component={MemberProjectsList}
          />
          <MemberRoute
            exact
            path="/member/projects/view/:id"
            component={MemberProjectDetail}
          />
          {/* Task (Kanban) */}
          <MemberRoute
            exact
            path="/member/projects/view/:id/milestone/:mid/task/kanban"
            component={Kanban}
          />
          {/* Calendar */}
          <MemberRoute
            exact
            path="/member/calendar"
            component={MemberCalendar}
          />
          {/* Todos */}
          <MemberRoute exact path="/member/to-dos" component={Todos} />
          {/* Chat */}
          {!user?.memberChatDisabled && (
            <MemberRoute exact path="/member/chat" component={MemberChat} />
          )}

          {/*************** Guest Routes ***************/}
          <GuestRoute
            exact
            path="/guest/dashboard"
            component={GuestDashboard}
          />
          {/* Project */}
          <GuestRoute
            exact
            path="/guest/projects"
            component={GuestProjectsList}
          />
          <GuestRoute
            exact
            path="/guest/projects/view/:id"
            component={ProjectDetail}
          />
          <GuestRoute
            exact
            path="/guest/project/:id/report"
            component={ProjectReport}
          />
          {/* Task (Kanban) */}
          <GuestRoute
            exact
            path="/guest/projects/view/:id/milestone/:mid/task/kanban"
            component={Kanban}
          />
          {/* Calendar */}
          <GuestRoute exact path="/guest/calendar" component={GuestCalendar} />
          {/* Todos */}
          <GuestRoute exact path="/guest/to-dos" component={Todos} />
          {/* Chat */}
          <GuestRoute exact path="/guest/chat" component={AdminChat} />

          {/*************** Profile Routes ***************/}
          <ProfileRoute
            exact
            path="/profile/information"
            component={MyProfile}
          />
          <ProfileRoute
            exact
            path="/profile/subscription"
            component={MySubscription}
          />
          <ProfileRoute exact path="/profile/security" component={MySecurity} />
          <ProfileRoute exact path="/profile/password" component={MyPassword} />

          {/* Wildcard */}
          <Route path={"*"} component={RandomPageRedirect} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
