import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Radio,
  Form,
  Input,
  Select,
  DatePicker,
  Tooltip,
} from "antd";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getAllTeamOfProject } from "../../../api/project";
import { getMilestoneById, updateMilestoneById } from "../../../api/milestone";
import { setCurrentProjectAction } from "../../../redux/actions/projectActions";
import { SuccessNotification, ErrorNotification } from "../..";
import moment from "moment";

const initialState = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  stage: "",
  priority: "",
  assignedTo: [],
};

const EditMilestoneModal = ({ open, handleModalClick, mid, fetchProject }) => {
  const { id } = useParams(); // Project ID
  const dispatch = useDispatch();
  const { currentProject } = useSelector((state) => state?.project);
  const [values, setValues] = useState(initialState);
  const [members, setMembers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [alreadySetMembers, setAlreadySetMembers] = useState([]);
  const [alreadySetManagers, setAlreadySetManagers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    fetchProjectMembers();
    fetchMilestone();
    dispatch(setCurrentProjectAction(id));
  }, []);

  // Fetch all team members
  const fetchProjectMembers = async () => {
    setLoading(true);
    const { data } = await getAllTeamOfProject(id);
    setMembers(data.members);
    setManagers(data.managers);
    setLoading(false);
  };

  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Get milestone by id
  const fetchMilestone = async () => {
    setLoading(true);

    try {
      const { data } = await getMilestoneById(mid);
      // Change date format
      data.milestone.startDate = moment(
        data.milestone.startDate.split("T")[0],
        "YYYY/MM/DD"
      );
      data.milestone.endDate = moment(
        data.milestone.endDate.split("T")[0],
        "YYYY/MM/DD"
      );
      setValues(data.milestone);

      // Modify the data to match the format of select
      const modifiedAssignedTo = data.milestone.assignedTo.map((member) => ({
        label: member.name,
        email: member.email,
        value: member._id,
      }));

      // Modify the data to match the format of select
      const modifiedManagers = data.milestone.managers.map((manager) => ({
        label: manager.name,
        email: manager.email,
        value: manager._id,
      }));

      setAlreadySetMembers(modifiedAssignedTo);
      setAlreadySetManagers(modifiedManagers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("GET_MILESTONE_BY_ID_ERROR", error);
    }
  };

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setUpdateLoading(true);

    // Set start and end date format
    if (dateRange.length > 0) {
      values.startDate = dateRange[0];
      values.endDate = dateRange[1];
    } else if (values.startDate && values.endDate) {
      values.startDate = moment(values.startDate).format("YYYY/MM/DD");
      values.endDate = moment(values.endDate).format("YYYY/MM/DD");
    }

    // Make sure all the values are set
    if (
      values.name === "" ||
      values.startDate === "" ||
      values.endDate === "" ||
      values.status === ""
    ) {
      setUpdateLoading(false);
      ErrorNotification("Please fill in all the required fields");
      return;
    }

    // Name length validation
    if (values.name.length > 100) {
      setUpdateLoading(false);
      ErrorNotification("Milestone name cannot be more than 100 characters");
      return;
    }
    // Description length validation
    if (values.description.length > 500) {
      setUpdateLoading(false);
      ErrorNotification(
        "Milestone description cannot be more than 500 characters"
      );
      return;
    }

    // Modify values.assignedTo to be an array of member ids
    const modifiedAssignedTo = values.assignedTo.map((member) => member._id);
    values.assignedTo = modifiedAssignedTo;

    // Modify values.managers to be an array of manager ids
    const modifiedManagers = values.managers.map((manager) => manager._id);
    values.managers = modifiedManagers;

    // Update milestone
    updateMilestoneById(id, mid, values)
      .then((res) => {
        setUpdateLoading(false);
        SuccessNotification(`${res.data.name} updated successfully`);
      })
      .then(() => {
        fetchProject();
        handleModalClick();
      })
      .catch((err) => {
        setUpdateLoading(false);
        ErrorNotification(err.response.data.message);
        console.log("UPDATE_MILESTONE_ERROR", err);
      });
  };

  return (
    <>
      <Modal
        title={
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Edit Milestone
          </h2>
        }
        visible={open}
        width={750}
        style={{
          top: "0.5rem",
        }}
        closable={false}
        onOk={handleModalClick}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleFormSubmit}
            loading={updateLoading}
          >
            Update
          </Button>,
        ]}
      >
        {loading ? (
          <div className="clock-loader"></div>
        ) : (
          <>
            <Form
              labelCol={{
                span: 5,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              {/* Milestone name */}
              <Form.Item label="Name" required>
                <Input
                  name="name"
                  placeholder="Milestone name"
                  onChange={handleFormChange}
                  value={values.name}
                  rules={[{ required: true }]}
                />
              </Form.Item>

              {/* Milestone description */}
              <Form.Item label="Description">
                <Input.TextArea
                  name="description"
                  placeholder="Milestone description"
                  onChange={handleFormChange}
                  value={values.description}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>

              {/* Milestone start and end date */}
              {values?.startDate && (
                <Form.Item label="Timeline" required>
                  <Tooltip
                    placement="topLeft"
                    overlayStyle={{ maxWidth: "500px" }}
                    title={
                      <>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Project Timeline{" "}
                        </div>
                        <div>
                          {new Date(
                            currentProject?.project?.startDate
                          ).toLocaleDateString("en-US", dateFormat)}
                          {" - "}
                          {new Date(
                            currentProject?.project?.endDate
                          ).toLocaleDateString("en-US", dateFormat)}
                        </div>
                      </>
                    }
                  >
                    <Row>
                      <Col span={24}>
                        <DatePicker.RangePicker
                          name="dateRange"
                          onChange={(date, dateString) => {
                            setDateRange(dateString);
                          }}
                          defaultValue={[values.startDate, values.endDate]}
                          autoFocus
                          popupStyle={{
                            height: "auto",
                          }}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          style={{
                            width: "100%",
                          }}
                          popupAlign={{
                            offset: [0, 0],
                          }}
                        />
                      </Col>
                    </Row>
                  </Tooltip>
                </Form.Item>
              )}

              {/* Milestone status */}
              <Form.Item label="Status" required>
                <Radio.Group
                  name="status"
                  onChange={(e) => handleFormChange(e)}
                  value={values.status}
                >
                  <Radio.Button value="In Progress">In Progress</Radio.Button>
                  <Radio.Button value="Completed">Completed</Radio.Button>
                  <Radio.Button value="On Hold">On Hold</Radio.Button>
                  <Radio.Button value="Cancelled">Cancelled</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {/* Update milestone members */}
              {members?.length ? (
                <Form.Item label="Members">
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select members"
                    labelInValue={true}
                    value={alreadySetMembers}
                    onChange={(value) => {
                      setAlreadySetMembers(value);

                      const modifiedMembers = value.map((member) => ({
                        _id: member.value,
                        name: member.label,
                      }));

                      setValues({
                        ...values,
                        assignedTo: modifiedMembers,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {members.map((member) => (
                      <Select.Option
                        key={member._id}
                        value={member._id}
                        label={member.name}
                      >
                        {member.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item label="Members">
                  <Select
                    placeholder="No members available"
                    disabled={members?.length === 0}
                  ></Select>
                </Form.Item>
              )}

              {/* Update milestone managers */}
              {managers?.length ? (
                <Form.Item label="Managers">
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select managers"
                    labelInValue={true}
                    value={alreadySetManagers}
                    onChange={(value) => {
                      setAlreadySetManagers(value);

                      const modifiedManagers = value.map((manager) => ({
                        _id: manager.value,
                        name: manager.label,
                      }));

                      setValues({
                        ...values,
                        managers: modifiedManagers,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {managers.map((manager) => (
                      <Select.Option
                        key={manager._id}
                        value={manager._id}
                        label={manager.name}
                      >
                        {manager.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item label="Managers">
                  <Select
                    placeholder="No managers available"
                    disabled={managers?.length === 0}
                  ></Select>
                </Form.Item>
              )}
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default EditMilestoneModal;
