import api from "./index";

// Save or update project settings
export const saveOrUpdateProjectSettings = async (values) => {
  return await api.post("/project-settings", values);
};

// Get project settings
export const getProjectSettings = async () => {
  return await api.get("/project-settings");
};
