import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Input, Button, Checkbox } from "antd";
import LeftContent from "./leftContent";
import { useDispatch } from "react-redux";
import { loginAction } from "../../../redux/actions/authActions";
import { ErrorNotification } from "../../../components";

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Handle email password login
  const handleLoginSubmit = (e) => {
    // Validate email regex
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      ErrorNotification("Please enter a valid email");
      return;
    }

    e.preventDefault();
    // Dispatch email, password and history to action
    dispatch(loginAction({ email, password, history, setLoading }));
  };

  return (
    <>
      <Row gutter={[32, 0]} className="hp-authentication-page">
        <LeftContent />

        <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
          <Row className="hp-h-100" align="middle" justify="center">
            <Col
              xxl={11}
              xl={15}
              lg={20}
              md={20}
              sm={24}
              className="hp-px-sm-8 hp-pt-24 hp-pb-48"
            >
              <h1 className="hp-mb-sm-0">Login</h1>
              <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
                Welcome back, please login to your account.
              </p>

              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                className="hp-mt-sm-16 hp-mt-32"
              >
                <Form.Item
                  label="Email :"
                  className="hp-mb-16"
                  rules={[
                    { required: true, message: "Please enter your email" },
                  ]}
                >
                  <Input
                    id="validating"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Password :"
                  className="hp-mb-8"
                  rules={[
                    { required: true, message: "Please enter your password" },
                  ]}
                >
                  <Input.Password
                    id="warning2"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>

                <Row align="middle" justify="space-between">
                  <Form.Item className="hp-mb-0">
                    <Checkbox name="remember">Remember me</Checkbox>
                  </Form.Item>

                  <Link
                    className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                    to="/forgot-password"
                  >
                    Forgot Password?
                  </Link>
                </Row>

                <Form.Item className="hp-mt-16 hp-mb-8">
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={handleLoginSubmit}
                    loading={loading}
                  >
                    Sign in
                  </Button>
                </Form.Item>
              </Form>

              <Col className="hp-form-info">
                <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                  Don’t you have an account?
                </span>

                <Link
                  className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                  to="/signup"
                >
                  {" "}
                  Create an account
                </Link>
              </Col>

              <Col
                className="hp-other-links hp-mt-24"
                style={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Link
                  to="/privacy"
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                >
                  Privacy Policy
                </Link>

                {/* A dot in between */}
                <span
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                  style={{ margin: "0 8px", fontSize: "12px" }}
                >
                  .
                </span>

                <Link
                  to="/terms"
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                >
                  Term of use
                </Link>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Login;
