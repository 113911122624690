import React from "react";
import { Col, Row, Switch, Select } from "antd";

const { Option } = Select;

const TaskSection = ({
  tskAssignEmail,
  setTskAssignEmail,
  tskStatusChngEmail,
  setTskStatusChngEmail,
  tskDeadlineReminder,
  setTskDeadlineReminder,
  tskDeadlineReminderDays,
  setTskDeadlineReminderDays,
}) => {
  return (
    <>
      <h4
        style={{
          marginBottom: "1.5rem",
        }}
      >
        Task
      </h4>

      {/* Task assign email to users */}
      <Row
        align="middle"
        style={{
          marginBottom: "20px",
        }}
      >
        <Col
          sm={15}
          span={12}
          className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
        >
          Email users when a task is assigned to them
        </Col>

        <Switch
          className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
          checked={tskAssignEmail}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={(checked) => {
            setTskAssignEmail(checked);
          }}
        />
      </Row>

      {/* Task status change email to users */}
      <Row
        align="middle"
        style={{
          marginBottom: "20px",
        }}
      >
        <Col
          sm={15}
          span={12}
          className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
        >
          Email users when a task status changes
        </Col>

        <Switch
          className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
          checked={tskStatusChngEmail}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={(checked) => {
            setTskStatusChngEmail(checked);
          }}
        />
      </Row>

      {/* Task deadline reminder */}
      <Row
        align="middle"
        style={{
          marginBottom: "20px",
        }}
      >
        <Col
          sm={15}
          span={12}
          className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
        >
          Task deadline reminder
        </Col>

        <Switch
          className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
          checked={tskDeadlineReminder}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={(checked) => setTskDeadlineReminder(checked)}
        />
      </Row>

      {tskDeadlineReminder && (
        <Row
          align="middle"
          justify="space-between"
          style={{
            marginBottom: "20px",
          }}
        >
          <Col
            sm={15}
            span={16}
            className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
          >
            Deadline email reminder frequency
          </Col>

          {/* Multiple Select with 1 Day, 3 Days, 7 Days Options */}
          <Col sm={9} span={8}>
            <Select
              mode="multiple"
              style={{
                width: "700px",
              }}
              disabled={!tskDeadlineReminder}
              onChange={(value) => setTskDeadlineReminderDays(value)}
              value={tskDeadlineReminderDays}
            >
              <Option value="1">1 Day before</Option>
              <Option value="3">3 Days before</Option>
              <Option value="7">7 Days before</Option>
            </Select>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TaskSection;
