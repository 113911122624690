// Admin Actions
export const ADMIN_TYPES = {
  GET_ADMIN_PROJECTS: "GET_ADMIN_PROJECTS",
  GET_TEAM_MEMBERS: "GET_TEAM_MEMBERS",
  CREATE_ADMIN_EVENT: "CREATE_ADMIN_EVENT",
  GET_ADMIN_EVENTS: "GET_ADMIN_EVENTS",
  UPDATE_ADMIN_EVENT: "UPDATE_ADMIN_EVENT",
  GET_ADMIN_SUBSCRIPTION: "GET_ADMIN_SUBSCRIPTION",
  GET_ADMIN_TUT_STATUS: "GET_ADMIN_TUT_STATUS",
  UPDATE_ADMIN_TUT_STATUS: "UPDATE_ADMIN_TUT_STATUS",
};
