import { Home, Calendar, People, Edit, Chat, Document } from "react-iconly";

const manager = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Home set="curved" className="remix-icon joy-dash-manager" />,
    navLink: "/manager/dashboard",
  },
  {
    id: "project",
    title: "Projects",
    icon: <Document set="curved" className="remix-icon joy-projects-manager" />,
    navLink: "/manager/projects",
  },
  {
    id: "calendar",
    title: "Calendar",
    icon: <Calendar set="curved" className="remix-icon joy-calendar-manager" />,
    navLink: "/manager/calendar",
  },
  {
    id: "todos",
    title: "My To-Do's",
    icon: <Edit set="curved" className="remix-icon joy-todos-manager" />,
    navLink: "/manager/to-dos",
  },
  {
    id: "chat",
    title: "Chat",
    icon: <Chat set="curved" className="remix-icon joy-chat-manager" />,
    navLink: "/manager/chat",
  },
  {
    id: "team",
    title: "Team",
    icon: <People set="curved" className="remix-icon joy-team-manager" />,
    navLink: "/manager/team",
  },
];

export default manager;
