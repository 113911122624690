import React, { useEffect, useState } from "react";
import { getTodosOfUserAction } from "../../../redux/actions/userActions";
import { TodosBoard } from "../../../components";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { Row, Col, Tooltip } from "antd";
import { InfoSquare } from "react-iconly";

const Todos = ({ history }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getTodosOfUserAction(setLoading));
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <BiArrowBack
            style={{ fontSize: "2em", cursor: "pointer" }}
            onClick={() => history.goBack()}
          />
          <h2
            style={{
              marginTop: "-1.3em",
              marginBottom: "1em",
              textAlign: "center",
              marginLeft: "-2em",
            }}
          >
            My Personal To-Do's{" "}
            <span>
              <Tooltip
                placement="topLeft"
                overlayStyle={{ maxWidth: "500px" }}
                title={
                  <>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      Your personal todo list, <br /> which is not linked to the
                      projects.
                      <br />
                      NOTE: Only visible to you
                    </div>
                  </>
                }
              >
                <InfoSquare set="bold" className="remix-icon" />
              </Tooltip>
            </span>
          </h2>

          {loading ? (
            <div
              className="clock-loader"
              style={{ top: "calc(40vh - 100px)" }}
            ></div>
          ) : (
            <>
              <div
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TodosBoard loading={loading} />
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Todos;
