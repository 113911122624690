import { v4 as uuid } from "uuid";
import { ACTION_TYPES, PROJECT_TYPES } from "../constants/actionTypes";
import { getProjectById } from "../../api/project";
import { getMilestoneById } from "../../api/milestone";
import { updateTaskById, getTaskAssignees } from "../../api/task";

/********************************************
  Get all tasks of a milestone
*********************************************/
export const getTasksOfMilestoneAction =
  (milestoneId, setLoading) => async (dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const res = await getMilestoneById(milestoneId);

      const stageList = ["To do", "In Progress", "Review", "Done"];
      const stageListWithEmptyCards = stageList.reduce((acc, stage) => {
        if (!acc[stage]) {
          acc[stage] = {
            id: uuid(),
            title: stage,
            cards: [],
          };
        }
        return acc;
      }, {});

      // For each task in tasks, add it to the correct stage's cards
      res.data.milestone.tasks.forEach((task) => {
        stageListWithEmptyCards[task.stage].cards.push(task);
      });

      // Rename the group name with a unique id
      const modifiedTaskList = Object.keys(stageListWithEmptyCards).reduce(
        (acc, stage) => {
          acc[stageListWithEmptyCards[stage].id] =
            stageListWithEmptyCards[stage];
          return acc;
        },
        {}
      );

      // Rename key of each task name to title
      const modifiedTaskListWithTitle = Object.keys(modifiedTaskList).reduce(
        (acc, stage) => {
          acc[stage] = {
            ...modifiedTaskList[stage],
            cards: modifiedTaskList[stage].cards.map((task) => ({
              ...task,
              title: task.name,
              id: task._id,
            })),
          };
          return acc;
        },
        {}
      );

      // Extract the stage names from the modifiedTaskListWithTitle
      const stageNames = Object.keys(modifiedTaskListWithTitle);

      const kanbanObj = {
        listIds: stageNames,
        lists: modifiedTaskListWithTitle,
      };

      // Dispatch milestone and tasks
      dispatch({
        type: PROJECT_TYPES.GET_TASKS,
        payload: {
          milestone: res.data.milestone,
          tasks: res.data.milestone.tasks,
          kanbanObj,
        },
      });

      setLoading(false);

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "GET_TASKS_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Update task board action
*********************************************/
export const updateTasksBoardAction = (milestoneId) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Dispatch getTasksOfMilestoneAction action
    dispatch(getTasksOfMilestoneAction(milestoneId));

    // Dispatch a success/error sign up alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "UPDATE_TASKS_BOARD_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Update task status action
*********************************************/
export const updateTaskStatusAction =
  (kanbanObj, src, dest, taskId, mid, id) => async (dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Get the stage of the destination card
      const destStage = kanbanObj.lists[dest].title;

      // Update task stage to the destination stage
      await updateTaskById(id, mid, taskId, { stage: destStage }).then(() => {
        // Dispatch updateTasksBoardAction action
        dispatch(updateTasksBoardAction(mid));
      });

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_TASK_STATUS_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Set current project action
*********************************************/
export const setCurrentProjectAction = (id) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Fetch the project from the server
    const res = await getProjectById(id);

    // Dispatch the current project
    dispatch({
      type: PROJECT_TYPES.CURRENT_PROJECT,
      payload: res.data,
    });

    // Dispatch a success/error sign up alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "SET_CURRENT_PROJECT_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Get assignees of a task
*********************************************/
export const getAssigneesOfTaskAction = (id, mid) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Fetch the project from the server
    const res = await getTaskAssignees(id, mid);

    // Dispatch the current project
    dispatch({
      type: PROJECT_TYPES.GET_TASK_ASSIGNEES,
      payload: res.data,
    });

    // Dispatch a success/error sign up alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "GET_ASSIGNEES_OF_TASK_ACTION_ERROR",
      },
    });
  }
};
