import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { RiLoaderLine, RiAddLine } from "react-icons/ri";
import { Editor } from "react-draft-wysiwyg";
import {
  getBlogById,
  updateBlogById,
  uploadBlogCoverImage,
  uploadBlogContentImage,
} from "../../../api/blog";
import { getAllCategories } from "../../../api/category";
import { getAllTags } from "../../../api/tag";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Upload,
  message,
} from "antd";
import { useParams, withRouter } from "react-router";
import { SuccessNotification, ErrorNotification } from "../..";
import moment from "moment";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const initialState = {
  title: "",
  coverImage: "",
  description: "",
  tags: [],
  content: {},
  category: "",
  publishedDate: "",
  images: [],
};

const BlogUpdateForm = ({ history }) => {
  const { id } = useParams();
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const initialEditorState = () => EditorState.createEmpty();
  const [editorState, setEditorState] = useState(initialEditorState);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  // Get blog by id
  const fetchBlog = async () => {
    setLoading(true);
    try {
      const { data } = await getBlogById(id);

      // Fetch categories
      const cats = await getAllCategories();
      setCategories(cats.data);

      // Fetch tags
      const tags = await getAllTags();
      setTags(tags.data);

      const contentState = convertFromRaw(JSON.parse(data.content));
      // Set editor state
      setEditorState(EditorState.createWithContent(contentState));
      // Convert date to moment object
      data.publishedDate = moment(
        data.publishedDate.split("T")[0],
        "YYYY/MM/DD"
      );
      setValues(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("GET_BLOG_BY_ID_ERROR", error);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  // Handle form changes
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validate the form
    if (!values.title || !values.category) {
      return ErrorNotification("Please fill all the required fields");
    }

    updateBlogById(id, values)
      .then(() => {
        SuccessNotification(`Blog updated successfully`);
        history.push("/super-admin/blogs");
      })
      .catch((error) => {
        ErrorNotification("Error updating blog");
        console.log("UPDATE_BLOG_ERROR", error);
      });
  };

  // Before image upload
  const beforeCoverUpload = (file) => {
    // Image can be jpeg, png, or gif
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPEG/PNG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("Image must smaller than 3MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  // Handle cover image upload
  const handleUploadCoverImage = async (options) => {
    const { onError, file } = options;
    const fmData = new FormData();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    fmData.append("image", file);

    // Make request to upload image
    try {
      setLoading(true);
      const res = await uploadBlogCoverImage(fmData, config);
      setValues({
        ...values,
        coverImage: res.data.url,
      });
      setLoading(false);
    } catch (error) {
      onError(error);
      setLoading(false);
    }
  };

  // Upload content image
  const uploadImageCallBack = (file, callback) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onloadend = async () => {
        const fmData = new FormData();

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        fmData.append("image", file);
        const res = await uploadBlogContentImage(fmData, config);
        setValues({
          ...values,
          images: [...values.images, res.data.url],
        });
        resolve({ data: { link: res.data.url } });
      };
      reader.readAsDataURL(file);
    });
  };

  const uploadButton = (
    <div>
      {loading ? (
        <RiLoaderLine
          className="remix-icon-loading remix-icon-spin remix-icon"
          size={24}
        />
      ) : (
        <RiAddLine size={24} />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Row>
      <Col
        span={24}
        style={{
          overflowY: "auto",
          height: "calc(95vh - 200px)",
        }}
      >
        <Form
          labelCol={{
            span: 2,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          {/* Blog Title */}
          <Form.Item label="Title">
            <Input
              name="title"
              placeholder="Enter title"
              onChange={handleFormChange}
              value={values.title}
              rules={[{ required: true }]}
            />
          </Form.Item>

          {/* Blog cover image */}
          <Form.Item label="Cover Image">
            <Upload
              name="coverImage"
              listType="picture-card"
              customRequest={handleUploadCoverImage}
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeCoverUpload}
              multiple={false}
              accept="image/*,image/gif"
            >
              {values.coverImage ? (
                <img
                  src={values.coverImage}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>

          {/* SEO meta description */}
          <Form.Item label="Meta Description">
            <Input
              name="description"
              placeholder="Enter meta description"
              onChange={handleFormChange}
              value={values.description}
              rules={[{ required: true }]}
            />
          </Form.Item>

          {/* Select drop down with category */}
          <Form.Item label="Category">
            <Select
              name="category"
              placeholder="Select a category"
              onChange={(value) => setValues({ ...values, category: value })}
              value={values.category}
              rules={[{ required: true }]}
            >
              {categories.map((category) => (
                <Select.Option key={category._id} value={category.name}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Tags */}
          <Form.Item label="Tags">
            <Select
              mode="multiple"
              name="tags"
              placeholder="Select tags"
              onChange={(value) => setValues({ ...values, tags: value })}
              value={values.tags}
              rules={[{ required: true }]}
            >
              {tags.map((tag) => (
                <Select.Option key={tag._id} value={tag.name}>
                  {tag.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Publish date */}
          {!loading && (
            <Form.Item label="Published Date">
              <DatePicker
                className="hp-mb-16 hp-mr-16"
                onChange={(date, dateString) =>
                  setValues({ ...values, publishedDate: dateString })
                }
                defaultValue={values.publishedDate}
                autoFocus
                popupStyle={{
                  height: "auto",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                style={{
                  width: "100%",
                }}
                popupAlign={{
                  offset: [0, 0],
                }}
              />
            </Form.Item>
          )}

          {/* Blog Content */}
          {!loading && (
            <Form.Item label="Content">
              <Col
                style={{
                  border: "1px solid #dfe6e9",
                  borderRadius: "8px",
                  padding: "16px",
                  minHeight: "500px",
                }}
              >
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  ariaLabel="draftEditor"
                  onEditorStateChange={(value) => {
                    setEditorState(value);
                    setValues({
                      ...values,
                      content: JSON.stringify(
                        convertToRaw(value.getCurrentContent())
                      ),
                    });
                  }}
                  toolbar={{
                    inline: { inDropdown: true },
                    fontFamily: {
                      options: ["Arial"],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: {
                      uploadCallback: uploadImageCallBack,
                      previewImage: true,
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: "auto",
                        width: "auto",
                      },
                    },
                  }}
                />
              </Col>
            </Form.Item>
          )}

          {/* Submit button */}
          <Form.Item wrapperCol={{ span: 12, offset: 2 }}>
            <Button type="primary" onClick={handleFormSubmit}>
              Update
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default withRouter(BlogUpdateForm);
