import React from "react";
import { Divider } from "antd";
import {
  AssignTo,
  Timeline,
  Checklist,
  Attachments,
  Logs,
  Priority,
  Actions,
} from "./subComponentsRight";

const TaskRightCol = ({
  values,
  setValues,
  handleUpdateTask,
  taskId,
  handleModalClick,
}) => {
  return (
    <>
      <h4>Add to task</h4>
      {/* Assign to */}
      <AssignTo
        assignedTo={values.assignedTo}
        values={values}
        setValues={setValues}
        handleUpdateTask={handleUpdateTask}
      />

      {/* Timeline */}
      <Timeline
        values={values}
        setValues={setValues}
        handleUpdateTask={handleUpdateTask}
      />

      {/* Priority */}
      <Priority
        values={values}
        setValues={setValues}
        handleUpdateTask={handleUpdateTask}
      />

      {/* Checklist */}
      <Checklist taskId={taskId} values={values} setValues={setValues} />

      {/* Attachments */}
      <Attachments taskId={taskId} values={values} setValues={setValues} />

      <Divider />

      {/* Logs */}
      <Logs values={values} />

      {/* Actions */}
      <>
        <Divider />
        <Actions taskId={taskId} handleModalClick={handleModalClick} />
      </>
    </>
  );
};

export default TaskRightCol;
