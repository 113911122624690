import { MANAGER_TYPES } from "../constants/managerTypes";

// Initial state
const initialState = {};

const managerReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANAGER_TYPES.GET_MANAGER_EVENTS:
      return action?.payload;
    default:
      return state;
  }
};

export default managerReducer;
