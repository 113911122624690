import React from "react";
import { Divider } from "antd";
import {
  TodoDescription,
  ChecklistDisplay,
  Timeline,
  Attachments,
  Comments,
} from "./subComponentsLeft";

const TodoLeftCol = ({
  values,
  setValues,
  handleUpdateTodo,
  todoId,
  updateTodoLoading,
}) => {
  return (
    <>
      {/* Todo description */}
      <TodoDescription
        values={values}
        setValues={setValues}
        handleUpdateTodo={handleUpdateTodo}
        updateTodoLoading={updateTodoLoading}
      />

      <Divider />

      {/* Timeline */}
      <Timeline values={values} />

      <Divider />

      {/* Task Checklist */}
      <ChecklistDisplay values={values} setValues={setValues} todoId={todoId} />

      <Divider />

      {/* Attachments */}
      <Attachments values={values} />

      <Divider />

      {/* Comments */}
      <Comments values={values} setValues={setValues} todoId={todoId} />
    </>
  );
};

export default TodoLeftCol;
