import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Tag } from "antd";
import { getMilestoneById } from "../../../api/milestone";
import { MilestoneOverviewCard, TeamListCard, TaskListCard } from "../..";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";

const initialState = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  status: "",
  tasks: [],
  assignedTo: [],
  managers: [],
};

const ViewMilestoneModal = ({ open, handleModalClick, mid }) => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({ totalTasks: 0, completedTasks: 0 });

  // Update milestone loading
  useEffect(() => {
    fetchMilestone();
  }, []);

  // Get milestone by id
  const fetchMilestone = async () => {
    try {
      setLoading(true);
      const { data } = await getMilestoneById(mid);
      data.milestone.startDate = data.milestone.startDate.split("T")[0];
      data.milestone.endDate = data.milestone.endDate.split("T")[0];
      setValues(data.milestone);
      // Set the total tasks and completed tasks
      setStats({
        totalTasks: data.totalTasks,
        completedTasks: data.completedTasks,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("GET_MILESTONE_BY_ID_ERROR", error);
    }
  };

  // Handle task view
  const handleTasksView = () => {
    if (user?.role === "admin") {
      history.push(`/admin/projects/view/${id}/milestone/${mid}/task/kanban`);
    } else if (user?.role === "manager") {
      history.push(`/manager/projects/view/${id}/milestone/${mid}/task/kanban`);
    } else if (user?.role === "member") {
      history.push(`/member/projects/view/${id}/milestone/${mid}/task/kanban`);
    } else if (user?.role === "guest") {
      history.push(`/guest/projects/view/${id}/milestone/${mid}/task/kanban`);
    }
  };

  return (
    <>
      <Modal
        title={
          <Row>
            {/* Milestone Name */}
            <h4
              style={{
                fontSize: "2rem",
                fontWeight: "bold",
                color: "#000",
                margin: "auto",
              }}
            >
              {values.name}
            </h4>

            {/* Milestone Priority */}
            <Tag
              style={{
                fontWeight: "bold",
                height: "20px",
                marginRight: "4rem",
                position: "absolute",
                right: "0",
              }}
              color={
                values.status === "Completed"
                  ? "green"
                  : values.status === "In Progress"
                  ? "blue"
                  : values.status === "On Hold"
                  ? "magenta"
                  : "red"
              }
            >
              {values.status}
            </Tag>
          </Row>
        }
        visible={open}
        onCancel={handleModalClick}
        onOk={handleModalClick}
        width={1250}
        centered
        style={{
          top: "0.5rem",
        }}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Close
          </Button>,
        ]}
      >
        {loading ? (
          <div className="clock-loader"></div>
        ) : (
          <>
            <Row justify="space-between">
              <Col span={24}>
                <MilestoneOverviewCard stats={stats} values={values} />
              </Col>
            </Row>

            <Row>
              <Col span={16}>
                {/* Tasks List Section */}
                <TaskListCard
                  tasks={values.tasks}
                  handleTasksView={handleTasksView}
                />
              </Col>

              <Col span={8}>
                {/* Milestone Team List Section */}
                <TeamListCard
                  members={values.assignedTo}
                  managers={values.managers}
                  fetchUpdate={fetchMilestone}
                  updateType="milestone"
                  milestoneId={mid}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default ViewMilestoneModal;
