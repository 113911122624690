import React, { useState } from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { sendTeamInvite } from "../../api/admin";
import { useSelector } from "react-redux";
import { SuccessNotification, ErrorNotification } from "../../components";

const initialValues = {
  email: "",
  role: "",
};

const MemberInviteModal = ({ open, handleModalClick }) => {
  const { user } = useSelector((state) => state.auth);
  const [values, setValues] = useState(initialValues);
  const [processing, setProcessing] = useState(false);

  // Handle values change
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Handle invite send click
  const handleInviteSendClick = async (e) => {
    e.preventDefault();
    let isAdmin = false;
    if (user?.role === "admin") {
      isAdmin = true;
    }

    // Add isAdmin to values
    values.isAdmin = isAdmin;

    // Check if email is valid
    if (values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setProcessing(true);
      // Send invite
      sendTeamInvite(values)
        .then((res) => {
          if (res.status === 200) {
            // Success
            SuccessNotification(res.data.message);
            handleModalClick();
            setProcessing(false);
          }
        })
        .catch((err) => {
          // Error
          ErrorNotification(err.response.data.type[0].message);
          console.log("TEAM_INVITE_ERROR", err);
          setProcessing(false);
        });
    } else {
      ErrorNotification("Please enter a valid email");
    }
  };

  return (
    <>
      <Modal
        title="Invite a new team member"
        visible={open}
        onOk={handleModalClick}
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleInviteSendClick}
            loading={processing}
          >
            Send
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* Email */}
          <Form.Item label="Email">
            <Input
              name="email"
              placeholder="Enter email"
              onChange={handleChange}
              value={values.email}
              rules={[{ type: "email" }]}
            />
          </Form.Item>

          {/* Role */}
          <Form.Item label="Role">
            <Select
              name="role"
              placeholder="Select role"
              onChange={(value) => setValues({ ...values, role: value })}
              value={values.role}
            >
              {user?.role === "admin" && (
                <>
                  <Select.Option value="admin">Admin</Select.Option>
                  <Select.Option value="manager">Manager</Select.Option>
                </>
              )}
              <Select.Option value="member">Member</Select.Option>
              <Select.Option value="guest">Guest</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MemberInviteModal;
