import React, { useState, useEffect } from "react";
import { ParentTable, ParentTableHead } from "../../../components";
import { Card, Row, Col, Button, Modal, Spin } from "antd";
import { RiEditFill, RiDeleteBin2Fill } from "react-icons/ri";
import { getAllCategories, deleteCategoryById } from "../../../api/category";
import {
  SuccessNotification,
  ErrorNotification,
  CategoryCreateModal,
  CategoryUpdateModal,
} from "../../../components";

const CategoriesList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [categoryId, setCategoryId] = useState("");

  // Handle create category modal
  const handleCreateModalClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  // Handle update category modal
  const handleUpdateModalClick = (id) => {
    setCategoryId(id);
    setUpdateModalOpen(!updateModalOpen);
  };

  // Fetch all categories
  const fetchCategories = async () => {
    setLoading(true);
    const { data } = await getAllCategories();
    setCategories(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Handle category delete
  const handleCategoryDelete = (id) => {
    // Confirm delete
    Modal.confirm({
      title: "Are you sure you want to delete this category?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteCategoryById(id);
          // Fetch all categories
          fetchCategories();
          // Show success notification
          SuccessNotification("Category deleted successfully");
        } catch (error) {
          // Show error notification
          ErrorNotification("Error deleting category");
        }
      },
    });
  };

  return (
    <>
      {/* Create Category Modal */}
      {createModalOpen && (
        <CategoryCreateModal
          open={createModalOpen}
          handleModalClick={handleCreateModalClick}
          fetchCategories={fetchCategories}
        />
      )}

      {/* Update Category Modal */}
      {updateModalOpen && (
        <CategoryUpdateModal
          open={updateModalOpen}
          handleModalClick={handleUpdateModalClick}
          fetchCategories={fetchCategories}
          categoryId={categoryId}
        />
      )}

      <Card
        className="hp-border-color-black-40 hp-mb-32 hp-analytics-project-table-card hp-project-table-card"
        style={{
          overflowY: "auto",
          height: "calc(100vh - 150px)",
        }}
      >
        <Row>
          <Col span={24}>
            <Row justify="space-between">
              <h3 className="hp-mb-16">Categories List</h3>
              <Button type="primary" onClick={handleCreateModalClick}>
                Create Category
              </Button>
            </Row>

            {/* All categories table */}
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(80vh - 50px)",
                }}
              >
                <Spin />
              </div>
            ) : (
              <ParentTable>
                <ParentTableHead>
                  <tr>
                    <th scope="col">Category name</th>
                    <th scope="col">Action</th>
                  </tr>
                </ParentTableHead>
                <tbody>
                  {categories?.length > 0 ? (
                    categories.map((category) => (
                      <tr key={category._id}>
                        <td>{category?.name}</td>
                        <td style={{ cursor: "pointer" }}>
                          {/* Edit Icon */}
                          <RiEditFill
                            onClick={() => handleUpdateModalClick(category._id)}
                          />
                          {/* Space between icons */}
                          <span style={{ margin: "0 10px" }} />
                          {/* Delete Icon */}
                          <RiDeleteBin2Fill
                            onClick={() => handleCategoryDelete(category._id)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No categories found</td>
                    </tr>
                  )}
                </tbody>
              </ParentTable>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CategoriesList;
