import React from "react";
import { Row, Avatar, Tooltip } from "antd";
import { BsFillPersonCheckFill, BsFillPersonPlusFill } from "react-icons/bs";

const AssignedTo = ({ values }) => {
  return (
    <>
      <Row>
        <h4>
          <BsFillPersonCheckFill
            style={{
              marginRight: "0.5rem",
              transform: "translateY(0.15rem)",
            }}
          />
          Assigned to{" "}
        </h4>

        <Avatar.Group
          size="large"
          style={{
            marginLeft: "1rem",
            marginTop: "-0.6rem",
          }}
        >
          {values.assignedTo?.length > 0 ? (
            values.assignedTo?.map((assignee) => (
              <Tooltip key={assignee._id} title={assignee.name} placement="top">
                <Avatar
                  style={{
                    backgroundColor: "#e1572f",
                    color: "white",
                  }}
                >
                  {/* First Char of first name + first char of second name if its present */}
                  {assignee.name.charAt(0).toUpperCase()}{" "}
                  {assignee.name.charAt(assignee.name.length - 1).toUpperCase()}
                </Avatar>
              </Tooltip>
            ))
          ) : (
            <>
              <Tooltip title="Add an user" placement="top">
                <Avatar icon={<BsFillPersonPlusFill />} />
              </Tooltip>
            </>
          )}
        </Avatar.Group>
      </Row>
    </>
  );
};

export default AssignedTo;
