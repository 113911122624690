import api from "./index";

// Create a category
export const createCategory = async (values) => {
  return await api.post("/category", values);
};

// Get all categories
export const getAllCategories = async () => {
  return await api.get(`/categories`);
};

// Get a category by id
export const getCategoryById = async (id) => {
  return await api.get(`/category/${id}`);
};

// Update a category by id
export const updateCategoryById = async (id, values) => {
  return await api.put(`/category/${id}`, values);
};

// Delete a category by id
export const deleteCategoryById = async (id) => {
  return await api.delete(`/category/${id}`);
};
