import React, { useState } from "react";
import { Card, Row, Col, Table, Tag, Button } from "antd";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import UpdateProjectTeamModal from "../../Modal/team/UpdateProjectTeamModal";
import UpdateMilestoneTeamModal from "../../Modal/team/UpdateMilestoneTeamModal";
import { RiAddFill } from "react-icons/ri";

const TeamListCard = ({
  members,
  managers,
  fetchUpdate,
  updateType,
  milestoneId,
}) => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [editModalOpen, setEditModalOpen] = useState(false);

  // Handle edit task button open/close
  const handleUpdateTeamClick = () => {
    setEditModalOpen(!editModalOpen);
  };

  const columns = [
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          #
        </span>
      ),
      dataIndex: "no",
      key: "no",
      render: (text) => (
        <p className="hp-mb-0 hp-font-weight-500 hp-text-color-black-100 hp-text-color-dark-0">
          {text}
        </p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          Name
        </span>
      ),
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <p className="hp-mb-0 hp-text-color-black-100 hp-text-color-dark-30">
          {text}
        </p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          Role
        </span>
      ),
      key: "role",
      dataIndex: "role",
      render: (role) => (
        <Tag
          color={
            role === "Manager" ? "green" : role === "Member" ? "blue" : "red"
          }
        >
          {role}
        </Tag>
      ),
    },
  ];

  // Combine members and managers into one array, and then map it to the table
  const data = [
    ...members.map((member, index) => ({
      key: index,
      no: index + 1,
      name: member.name,
      role: "Member",
    })),
    ...managers.map((manager, index) => ({
      key: index + members.length,
      no: index + members.length + 1,
      name: manager.name,
      role: "Manager",
    })),
  ];

  return (
    <>
      {/* Add/Edit Project Team Modal */}
      {editModalOpen && updateType === "project" && (
        <UpdateProjectTeamModal
          open={editModalOpen}
          handleModalClick={handleUpdateTeamClick}
          currentMembers={members}
          currentManagers={managers}
          projectId={id}
          fetchProject={fetchUpdate}
        />
      )}

      {/* Add/Edit Milestone Team Modal */}
      {editModalOpen && updateType === "milestone" && (
        <UpdateMilestoneTeamModal
          open={editModalOpen}
          handleModalClick={handleUpdateTeamClick}
          currentMembers={members}
          currentManagers={managers}
          projectId={id}
          fetchMilestone={fetchUpdate}
          milestoneId={milestoneId}
        />
      )}

      <Col className="joy-prj-mbrs-ovw joy-mstn-mbr-list" span={24}>
        <Card className="hp-mb-32 hp-card-1 hp-project-table-card">
          <Row>
            <Col span={24}>
              <Row justify="space-between">
                <h3 className="hp-mb-16 hp-font-weight-400">
                  {updateType === "project" ? "Project" : "Milestone"} Team
                </h3>
                {(user?.role === "admin" || user?.role === "manager") && (
                  <Button
                    type="primary"
                    size="small"
                    onClick={handleUpdateTeamClick}
                    className="hp-mb-16"
                  >
                    <RiAddFill className="remix-icon" size={16} />
                    <span className="hp-ml-8">Add/Update</span>
                  </Button>
                )}
              </Row>

              <Table
                style={{
                  overflowY: "auto",
                  height: "calc(85vh - 300px)",
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default TeamListCard;
