import React from "react";

import { Row, Col, Progress } from "antd";
import { useSelector } from "react-redux";

const TotalMessagesCard = () => {
  const { analytics } = useSelector((state) => state.admin);

  return (
    <div className="hp-border-2 hp-overflow-hidden hp-border-color-black-40 hp-border-color-dark-80 hp-border-radius hp-bg-color-black-0 hp-bg-color-dark-100 hp-p-8 hp-card-1">
      <Row gutter={16} align="middle">
        <Col span={24} className="hp-text-center">
          <div
            id="chart"
            className="hp-bg-color-dark-90 hp-border-radius hp-d-flex-center-full hp-mb-12"
            style={{
              backgroundColor: "#f1fee5",
            }}
          >
            <Progress
              type="circle"
              percent={Math.round(
                (analytics?.activeProjects / analytics?.totalProjects) * 100
              )}
              format={(percent) => `${percent} %`}
              width={72}
              style={{
                lineHeight: "6rem",
              }}
              strokeColor="#a2f84c"
            />
          </div>

          <h3 className="hp-mb-0">
            {analytics?.activeProjects ? analytics?.activeProjects : 0} / 5000
          </h3>
          <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-50">
            Total Messages
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default TotalMessagesCard;
