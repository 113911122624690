import { Home, Calendar, Edit, Document } from "react-iconly";

const guest = [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Home set="curved" className="remix-icon  joy-dash-guest" />,
    navLink: "/guest/dashboard",
  },
  {
    id: "project",
    title: "Projects",
    icon: <Document set="curved" className="remix-icon joy-projects-guest" />,
    navLink: "/guest/projects",
  },
  {
    id: "calendar",
    title: "Calendar",
    icon: <Calendar set="curved" className="remix-icon joy-calendar-guest" />,
    navLink: "/guest/calendar",
  },
  {
    id: "todos",
    title: "My To-Do's",
    icon: <Edit set="curved" className="remix-icon joy-todos-guest" />,
    navLink: "/guest/to-dos",
  },
];

export default guest;
