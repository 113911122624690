import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Card, Row, Col } from "antd";

import List from "./list";
import { useSelector, useDispatch } from "react-redux";
import { CreateTaskModal, ViewTaskModal } from "../../components";
import { updateTaskStatusAction } from "../../redux/actions/projectActions";
import { useParams } from "react-router";
// Joyride
import Joyride, { STATUS } from "react-joyride";
import { fireConfetti } from "../../utils/confetti";
import { updateAdminTutStatusAction } from "../../redux/actions/adminActions";

const KanbanContainer = () => {
  const dispatch = useDispatch();
  const { id, mid } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { kanbanObj } = useSelector((state) => state.project);
  const [data, setData] = useState(kanbanObj);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewTaskId, setViewTaskId] = useState();
  const [tutFinished, setTutFinished] = useState(
    JSON.parse(localStorage.getItem("joyride"))?.kbnDetails
  );

  useEffect(() => {
    setData(kanbanObj);
  }, [kanbanObj]);

  // Handle create task modal open/close
  const handleCreateTaskClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  // Handle view task modal open/close
  const handleViewTaskClick = (tid) => {
    setViewTaskId(tid);
    setViewModalOpen(!viewModalOpen);
  };

  // On drag end
  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (type === "list") {
      const newListIds = data.listIds;

      newListIds.splice(source.index, 1);
      newListIds.splice(destination.index, 0, draggableId);

      const newState = {
        ...data,
        listIds: newListIds,
      };
      setData(newState);
      return;
    }

    const sourceList = data.lists[source.droppableId];
    const destinationList = data.lists[destination.droppableId];
    const draggingCard = sourceList.cards.filter(
      (card) => card.id === draggableId
    )[0];

    if (source.droppableId === destination.droppableId) {
      sourceList.cards.splice(source.index, 1);
      destinationList.cards.splice(destination.index, 0, draggingCard);

      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [sourceList.id]: destinationList,
        },
      };
      setData(newState);
    } else {
      sourceList.cards.splice(source.index, 1);
      destinationList.cards.splice(destination.index, 0, draggingCard);

      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [sourceList.id]: sourceList,
          [destinationList.id]: destinationList,
        },
      };

      // Dispatch update task status action
      dispatch(
        updateTaskStatusAction(
          newState,
          newState.lists[sourceList.id].id,
          newState.lists[destinationList.id].id,
          draggingCard.id,
          mid,
          id
        )
      );
      setData(newState);
    }
  };

  // Joyride steps
  const steps = [
    {
      target: ".joy-kanban-admin",
      content: (
        <div>
          <h3>Task Board</h3>
          <p>
            Each milestone will have its own task board. <br />
            You can drag and drop tasks between lists to change their status
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
      styles: {
        options: {
          width: 400,
          zIndex: 1000,
        },
      },
      locale: {
        last: "Finish",
      },
    },
  ];

  // Handle joyride finish
  const handleJoyrideCallback = (data) => {
    if (data.status === STATUS.FINISHED) {
      setTutFinished(true);
      const pageToUpdate = {
        kbnDetails: true,
      };

      // If user is admin
      if (user?.role === "admin") {
        dispatch(updateAdminTutStatusAction(pageToUpdate));
      }

      // Get the joyride object from local storage
      const joyrideObj = JSON.parse(localStorage.getItem("joyride"));

      // Update the joyride object
      joyrideObj.kbnDetails = true;

      // Set the joyride object to local storage
      localStorage.setItem("joyride", JSON.stringify(joyrideObj));
      fireConfetti();
    }
  };

  return (
    <>
      {/* Create Task Modal */}
      {createModalOpen && (
        <CreateTaskModal
          open={createModalOpen}
          handleModalClick={handleCreateTaskClick}
        />
      )}

      {/* View Task Modal */}
      {viewModalOpen && (
        <ViewTaskModal
          open={viewModalOpen}
          handleModalClick={handleViewTaskClick}
          taskId={viewTaskId}
        />
      )}

      {/* Joyride */}
      {/* <Joyride
        steps={steps}
        run={!tutFinished}
        continuous
        showProgress={true}
        showSkipButton={false}
        // Run only once
        callback={(data) => {
          handleJoyrideCallback(data);
        }}
        disableScrolling
        disableOverlayClose
        disableCloseOnEsc
        spotlightClicks
      /> */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="app" type="list" direction="horizontal">
          {(provided) => (
            <Card
              style={{
                height: "calc(94vh - 100px)",
              }}
            >
              <Col span={24}>
                <Row
                  className="joy-kanban-admin"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {data?.listIds.map((listId, index) => {
                    const list = data.lists[listId];

                    return (
                      <List
                        createModalOpen={createModalOpen}
                        setCreateModalOpen={setCreateModalOpen}
                        handleViewTaskClick={handleViewTaskClick}
                        list={list}
                        key={listId}
                        index={index}
                      />
                    );
                  })}
                  {provided.placeholder}
                </Row>
              </Col>
            </Card>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default KanbanContainer;
