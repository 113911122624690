import { CHAT_TYPES } from "../constants/actionTypes";

// Initial state
const initialState = { notifications: [] };

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_TYPES.MESSAGE_NOTIFICATION:
      return action?.payload;
    default:
      return state;
  }
};

export default chatReducer;
