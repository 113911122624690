import api from "./index";

// Create a message
export const createMessage = (values) => {
  return api.post("/message", values);
};

// Get all messages for a chat
export const getMessages = (chatId) => {
  return api.get(`/messages/${chatId}`);
};
