import React, { useState, useEffect } from "react";
import { getProjectById, updateProjectById } from "../../../api/project";
import { getAdminTeamMembersAction } from "../../../redux/actions/adminActions";
import { getManagerTeamMembersAction } from "../../../redux/actions/managerActions";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Radio,
  Select,
  DatePicker,
  Modal,
  InputNumber,
} from "antd";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { SuccessNotification, ErrorNotification } from "../../../components";

const initialState = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  status: "",
  estimatedHours: "",
  managers: [],
  assignedTo: [],
  guests: [],
};

const EditProjectModal = ({ open, handleModalClick, projectId, history }) => {
  const { user } = useSelector((state) => state?.auth);
  const { members, managers, guests } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialState);
  const [dateRange, setDateRange] = useState([]);
  const [alreadySetManagers, setAlreadySetManagers] = useState([]);
  const [alreadySetMembers, setAlreadySetMembers] = useState([]);
  const [alreadySetGuests, setAlreadySetGuests] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    fetchProject();
    fetchTeamMembers();
  }, []);

  // Fetch all team members
  const fetchTeamMembers = async () => {
    if (user?.role === "admin") {
      dispatch(getAdminTeamMembersAction());
    } else if (user?.role === "manager") {
      dispatch(getManagerTeamMembersAction());
    }
  };

  // Get project by id
  const fetchProject = async () => {
    setLoading(true);
    try {
      const { data } = await getProjectById(projectId);

      // Change date format
      data.project.startDate = moment(
        data.project.startDate.split("T")[0],
        "YYYY/MM/DD"
      );
      data.project.endDate = moment(
        data.project.endDate.split("T")[0],
        "YYYY/MM/DD"
      );

      setValues(data.project);

      // Extract only name, email and _id from assignedTo array
      const modifiedAssignedTo = data.project.assignedTo.map((member) => ({
        label: member.name,
        email: member.email,
        value: member._id,
      }));

      // Extract only name, email and _id from managers array
      const modifiedManagers = data.project.managers.map((manager) => ({
        label: manager.name,
        email: manager.email,
        value: manager._id,
      }));

      // Extract only name, email and _id from guests array
      const modifiedGuests = data.project.guests.map((guest) => ({
        label: guest.name,
        email: guest.email,
        value: guest._id,
      }));

      // Set already set managers
      setAlreadySetManagers(modifiedManagers);
      setAlreadySetMembers(modifiedAssignedTo);
      setAlreadySetGuests(modifiedGuests);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("GET_PROJECT_BY_ID_ERROR", error);
    }
  };

  // Handle form changes
  const handleFormChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setUpdateLoading(true);

    // Set start and end date format
    if (dateRange.length > 0) {
      values.startDate = dateRange[0];
      values.endDate = dateRange[1];
    } else if (values.startDate && values.endDate) {
      values.startDate = moment(values.startDate).format("YYYY/MM/DD");
      values.endDate = moment(values.endDate).format("YYYY/MM/DD");
    }

    const modifiedAssignedTo = values.assignedTo.map((member) => member._id);
    values.assignedTo = modifiedAssignedTo;

    const modifiedManagers = values.managers.map((manager) => manager._id);
    values.managers = modifiedManagers;

    const modifiedGuests = values.guests.map((guest) => guest._id);
    values.guests = modifiedGuests;

    // Make sure all the values are set
    if (
      values.name === "" ||
      values.startDate === "" ||
      values.endDate === "" ||
      values.status === ""
    ) {
      setUpdateLoading(false);
      ErrorNotification("Please fill in all the required fields");
      return;
    }

    // Name length validation
    if (values.name.length > 100) {
      setUpdateLoading(false);
      ErrorNotification("Project name cannot be more than 100 characters");
      return;
    }
    // Description length validation
    if (values.description.length > 500) {
      setUpdateLoading(false);
      ErrorNotification(
        "Project description cannot be more than 500 characters"
      );
      return;
    }

    // Update project
    updateProjectById(projectId, values)
      .then((res) => {
        setUpdateLoading(false);
        SuccessNotification(`${res.data.name} updated successfully`);
        if (user?.role === "admin") {
          history.push(`/admin/projects/view/${res.data._id}`);
        } else if (user?.role === "manager") {
          history.push(`/manager/projects/view/${res.data._id}`);
        }
      })
      .catch((error) => {
        setUpdateLoading(false);
        ErrorNotification("Error updating project");
        console.log("UPDATE_PROJECT_ERROR", error);
      });
  };

  return (
    <>
      <Modal
        title={
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Edit Project
          </h2>
        }
        visible={open}
        onOk={handleModalClick}
        width={750}
        style={{
          top: "0.5rem",
        }}
        centered
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleFormSubmit}
            loading={updateLoading}
          >
            Update
          </Button>,
        ]}
      >
        {loading ? (
          <div className="clock-loader"></div>
        ) : (
          <>
            <Form
              labelCol={{
                span: 5,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              {/* Project name */}
              <Form.Item label="Name" required>
                <Input
                  name="name"
                  placeholder="Project name"
                  onChange={handleFormChange}
                  value={values.name}
                  rules={[{ required: true }]}
                />
              </Form.Item>

              {/* Project description */}
              <Form.Item label="Description">
                <Input.TextArea
                  name="description"
                  placeholder="Project description"
                  onChange={handleFormChange}
                  value={values.description}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>

              {/* Project start and end date */}
              {values?.startDate && (
                <Form.Item label="Timeline" required>
                  <Row>
                    <Col span={12}>
                      <DatePicker.RangePicker
                        name="dateRange"
                        onChange={(date, dateString) => {
                          setDateRange(dateString);
                        }}
                        defaultValue={[values.startDate, values.endDate]}
                        autoFocus
                        popupStyle={{
                          height: "auto",
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        style={{
                          width: "100%",
                        }}
                        popupAlign={{
                          offset: [0, 0],
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              )}

              {/* Project status */}
              <Form.Item label="Status" required>
                <Radio.Group
                  name="status"
                  onChange={(e) => handleFormChange(e)}
                  value={values.status}
                >
                  <Radio.Button value="In Progress">In Progress</Radio.Button>
                  <Radio.Button value="Completed">Completed</Radio.Button>
                  <Radio.Button value="On Hold">On Hold</Radio.Button>
                  <Radio.Button value="Cancelled">Cancelled</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {/* Project estimated hours */}
              <Form.Item label="Estimated hours">
                <InputNumber
                  name="estimatedHours"
                  placeholder="Hours (optional)"
                  onChange={(value) => {
                    setValues({
                      ...values,
                      estimatedHours: value,
                    });
                  }}
                  value={values.estimatedHours}
                  style={{ width: 200 }}
                />
              </Form.Item>

              {/* Add project managers */}
              {managers?.length ? (
                <Form.Item label="Managers">
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select managers"
                    labelInValue={true}
                    value={alreadySetManagers}
                    onChange={(value) => {
                      setAlreadySetManagers(value);

                      const modifiedManagers = value.map((manager) => ({
                        _id: manager.value,
                        name: manager.label,
                      }));
                      setValues({
                        ...values,
                        managers: modifiedManagers,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {managers.map((manager) => (
                      <Select.Option
                        key={manager._id}
                        value={manager._id}
                        label={manager.name}
                      >
                        {manager.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item label="Managers">
                  <Select
                    placeholder="No managers available"
                    disabled={managers?.length === 0}
                  ></Select>
                </Form.Item>
              )}

              {/* Add project members */}
              {members?.length ? (
                <Form.Item label="Members">
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select members"
                    labelInValue={true}
                    value={alreadySetMembers}
                    onChange={(value) => {
                      setAlreadySetMembers(value);

                      const modifiedMembers = value.map((member) => ({
                        _id: member.value,
                        name: member.label,
                      }));

                      setValues({
                        ...values,
                        assignedTo: modifiedMembers,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {members.map((member) => (
                      <Select.Option
                        key={member._id}
                        value={member._id}
                        label={member.name}
                      >
                        {member.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item label="Members">
                  <Select
                    placeholder="No members available"
                    disabled={members?.length === 0}
                  ></Select>
                </Form.Item>
              )}

              {/* Add project guests */}
              {guests?.length ? (
                <Form.Item label="Guests">
                  <Select
                    mode="multiple"
                    placeholder="Select guests"
                    labelInValue={true}
                    value={alreadySetGuests}
                    onChange={(value) => {
                      setAlreadySetGuests(value);

                      const modifiedGuests = value.map((guest) => ({
                        _id: guest.value,
                        name: guest.label,
                      }));

                      setValues({
                        ...values,
                        guests: modifiedGuests,
                      });
                    }}
                  >
                    {guests.map((guest) => (
                      <Select.Option
                        key={guest._id}
                        value={guest._id}
                        label={guest.name}
                      >
                        {guest.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item label="Guests">
                  <Select
                    placeholder="No guests available"
                    disabled={guests?.length === 0}
                  ></Select>
                </Form.Item>
              )}
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default EditProjectModal;
