import React, { useState } from "react";
import { Col, Row, Button, Input } from "antd";
import { ErrorNotification } from "../../../../../components";

const TaskDescription = ({
  values,
  setValues,
  handleUpdateTask,
  updateTaskLoading,
}) => {
  const [edit, setEdit] = useState(false);
  const [description, setDescription] = useState(values.description);

  //   Handle update description
  const handleUpdateDescription = () => {
    // Description should be less than 300 characters
    if (description.length > 499) {
      return ErrorNotification("Description must be less than 499 characters");
    }

    setEdit(false);

    setValues({
      ...values,
      description: description,
    });
    handleUpdateTask("description", description);
  };

  return (
    <>
      <Row>
        <h4>Description </h4>
        <Col span={24}>
          <Row onClick={() => setEdit(true)}>
            <Input.TextArea
              placeholder="Add a more detailed description..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              autoSize={{
                minRows: 2,
                maxRows: 10,
              }}
              size="small"
              style={{
                marginBottom: "1rem",
                backgroundColor: edit ? "#f5f5f5" : "#fff",
                cursor: edit ? "text" : "pointer",
              }}
            />
          </Row>

          {/* Save & Cancel buttons */}
          {edit && (
            <Row>
              <Button
                type="primary"
                size="small"
                style={{
                  marginRight: "0.7rem",
                }}
                onClick={() => {
                  handleUpdateDescription();
                }}
                loading={updateTaskLoading}
              >
                Save
              </Button>
              <Button size="small" onClick={() => setEdit(false)}>
                Cancel
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default TaskDescription;
