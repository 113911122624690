import React from "react";
import { Row, Button, Select, Dropdown } from "antd";
import { MdPriorityHigh } from "react-icons/md";

const { Option } = Select;

const Priority = ({ values, setValues, handleUpdateTodo }) => {
  // Handle priority change
  const handlePriorityChange = (val) => {
    // Set the parent state
    setValues({ ...values, priority: val });
    // Update the todo with the new priority
    handleUpdateTodo("priority", val);
  };

  return (
    <>
      <Row
        style={{
          marginBottom: "0.7rem",
        }}
      >
        <Dropdown
          getPopupContainer={(trigger) => trigger.parentNode}
          overlay={
            <>
              {/* Select todo priority */}
              <Select
                style={{ width: "200px" }}
                placeholder="Select priority"
                value={values.priority}
                onChange={(value) => handlePriorityChange(value)}
              >
                {["Low", "Medium", "High", "Critical"].map((item, index) => (
                  <Option key={index} value={item} label={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </>
          }
        >
          <Button
            shape="round"
            style={{
              background: "linear-gradient(to right, #839cff, #b1f6ff)",
            }}
            icon={
              <MdPriorityHigh
                style={{
                  marginRight: "0.5rem",
                }}
              />
            }
            size={"small"}
          >
            Priority
          </Button>
        </Dropdown>
      </Row>
    </>
  );
};

export default Priority;
