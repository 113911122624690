import React, { useState, useEffect } from "react";
import "./chatOnline.css";
import { getTeamMembers } from "../../../api/admin";
import { getAllMemberTeamMembers } from "../../../api/member";

const ChatOnline = ({ currentUser, onlineUsers }) => {
  const [members, setMembers] = useState([]);
  const [onlineMembers, setOnlineMembers] = useState([]);

  useEffect(() => {
    currentUser.role === "admin"
      ? getTeamMembers().then((res) => {
          setMembers(res.data.members);
        })
      : getAllMemberTeamMembers().then((res) => {
          setMembers(res.data.members);
        });
  }, [currentUser]);

  // Filter members to set online members
  useEffect(() => {
    currentUser.role === "admin"
      ? setOnlineMembers(
          members.filter((member) => {
            return onlineUsers.some((user) => user.userId === member.user);
          })
        )
      : // Set online members, where user.userId === member.user & !== currentUser._id
        setOnlineMembers(
          members.filter((member) => {
            return (
              onlineUsers.some((user) => user.userId === member.user) &&
              member.user !== currentUser._id
            );
          })
        );
  }, [members, onlineUsers]);

  return (
    <div className="chatOnline">
      {onlineMembers.map((member) => (
        <div className="chatOnlineMember" key={member._id}>
          <div className="chatOnlineImgContainer">
            <img src={member?.picture} alt="" className="chatOnlineImg" />
            <div className="chatOnlineBadge"></div>
          </div>
          <span className="chatOnlineName">{member?.name}</span>
        </div>
      ))}
    </div>
  );
};

export default ChatOnline;
