import React, { useState } from "react";
import { Row, Button, Input, Dropdown } from "antd";
import { BsCardChecklist } from "react-icons/bs";
import { createChecklistForTask } from "../../../../../api/checklist";

const Checklist = ({ taskId, values, setValues }) => {
  const [name, setName] = useState("");

  // Handle create checklist
  const handleCreateChecklist = async () => {
    const body = {
      name,
    };

    try {
      const res = await createChecklistForTask(taskId, body);
      // Insert new checklist to the beginning of the array
      const newChecklist = [res.data, ...values.checklists];

      setValues({
        ...values,
        checklists: newChecklist,
      });
      setName("");
    } catch (error) {
      console.log("CREATE_CHECKLIST_FOR_TASK_ERROR", error);
    }
  };

  return (
    <Row
      style={{
        marginBottom: "0.7rem",
      }}
    >
      <Dropdown
        getPopupContainer={(trigger) => trigger.parentNode}
        overlay={
          <>
            {/* Enter Checklist name */}
            <Input.Group compact>
              <Input
                style={{
                  width: "calc(100% - 100px)",
                }}
                placeholder="Checklist name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Button type="primary" onClick={handleCreateChecklist}>
                Create
              </Button>
            </Input.Group>
          </>
        }
      >
        <Button
          shape="round"
          style={{
            background: "linear-gradient(to right, #839cff, #b1f6ff)",
          }}
          icon={
            <BsCardChecklist
              style={{
                marginRight: "0.5rem",
              }}
            />
          }
          size={"small"}
        >
          Checklist
        </Button>
      </Dropdown>
    </Row>
  );
};

export default Checklist;
