import React, { useState } from "react";
import { useParams } from "react-router";
import { deleteMilestoneById } from "../../../../api/milestone";
import {
  MilestoneListCard,
  CreateMilestoneModal,
  EditMilestoneModal,
  ViewMilestoneModal,
} from "../../../../components";
import { Modal } from "antd";
import { SuccessNotification, ErrorNotification } from "../../../../components";

const MilestoneList = ({ milestones, loading, fetchProject }) => {
  const { id } = useParams();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editMilestoneId, setEditMilestoneId] = useState();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewMilestoneId, setViewMilestoneId] = useState();

  // Handle create milestone modal open/close
  const handleCreateMilestoneClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  // Handle edit milestone modal open/close
  const handleEditMilestoneClick = (mid) => {
    setEditModalOpen(!editModalOpen);
    setEditMilestoneId(mid);
  };

  // Handle view milestone modal open/close
  const handleViewMilestoneClick = (mid) => {
    setViewModalOpen(!viewModalOpen);
    setViewMilestoneId(mid);
  };

  // Handle milestone delete
  const handleMilestoneDelete = (mid) => {
    // Confirm delete
    Modal.confirm({
      title: "Are you sure you want to delete this milestone?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          // Delete event
          await deleteMilestoneById(id, mid);
          // Fetch all admin projects
          fetchProject();
          // Show success notification
          SuccessNotification("Milestone deleted successfully");
        } catch (error) {
          // Show error notification
          ErrorNotification("Error deleting milestone");
        }
      },
    });
  };

  return !loading && milestones ? (
    <>
      {/* Create Milestone Modal */}
      {createModalOpen && (
        <CreateMilestoneModal
          open={createModalOpen}
          handleModalClick={handleCreateMilestoneClick}
          fetchProject={fetchProject}
        />
      )}

      {/* Edit Milestone Modal */}
      {editModalOpen && (
        <EditMilestoneModal
          open={editModalOpen}
          handleModalClick={handleEditMilestoneClick}
          mid={editMilestoneId}
          fetchProject={fetchProject}
        />
      )}

      {/* View Milestone Modal */}
      {viewModalOpen && (
        <ViewMilestoneModal
          open={viewModalOpen}
          handleModalClick={handleViewMilestoneClick}
          mid={viewMilestoneId}
        />
      )}

      <MilestoneListCard
        milestones={milestones}
        handleMilestoneCreate={handleCreateMilestoneClick}
        handleMilestoneUpdate={handleEditMilestoneClick}
        handleMilestoneDelete={handleMilestoneDelete}
        handleMilestoneView={handleViewMilestoneClick}
      />
    </>
  ) : (
    <div className="loader"></div>
  );
};

export default MilestoneList;
