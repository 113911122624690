import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { logoutAction } from "../../../redux/actions/authActions";
import { Menu, Dropdown, Col, Avatar } from "antd";
import { User, Logout, Discount } from "react-iconly";
import { useSelector } from "react-redux";

const HeaderUser = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  // Handle logout
  const handleLogout = () => {
    // Dispatch logout action
    dispatch(logoutAction());
  };

  const menu = (
    <Menu theme={"light"}>
      <Menu.Item
        key={0}
        icon={
          <User
            set="curved"
            className="remix-icon hp-vertical-align-middle hp-text-color-dark-0"
            size={16}
          />
        }
        className="hp-text-color-dark-0"
      >
        <Link to="/profile/information">My Profile</Link>
      </Menu.Item>

      {/* My Subscription */}
      {user?.role === "admin" && (
        <Menu.Item
          key={2}
          icon={
            <Discount
              set="curved"
              className="remix-icon hp-vertical-align-middle hp-text-color-dark-0"
              size={16}
            />
          }
          className="hp-text-color-dark-0"
        >
          <Link to="/profile/subscription">My Subscription</Link>
        </Menu.Item>
      )}

      <Menu.Item
        key={5}
        icon={
          <Logout
            set="curved"
            className="remix-icon hp-vertical-align-middle hp-text-color-dark-0"
            size={16}
          />
        }
        className="hp-text-color-dark-0"
        onClick={handleLogout}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Col className="hp-d-flex-center" onClick={(e) => e.preventDefault()}>
          <Avatar
            size={48}
            style={{
              cursor: "pointer",
              backgroundColor: "#B4DAFD",
            }}
          >
            {user?.name?.charAt(0).toUpperCase()}{" "}
            {user?.name?.charAt(user?.name?.length - 1).toUpperCase()}
          </Avatar>
        </Col>
      </Dropdown>
    </Col>
  );
};

export default HeaderUser;
