import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, DatePicker } from "antd";
import { updateAdminEventAction } from "../../../redux/actions/adminActions";
import { updateManagerEventAction } from "../../../redux/actions/managerActions";
import { updateMemberEventAction } from "../../../redux/actions/memberActions";
import { updateGuestEventAction } from "../../../redux/actions/guestActions";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdminEventById } from "../../../api/admin";
import { deleteManagerEventById } from "../../../api/manager";
import { deleteMemberEventById } from "../../../api/member";
import { deleteGuestEventById } from "../../../api/guest";
import moment from "moment";
import { SuccessNotification, ErrorNotification } from "../../../components";

const initialState = {
  name: "",
  description: "",
  start: "",
  end: "",
};

const EditEventModal = ({ open, handleModalClose, editEvent, fetchEvents }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const isProject = editEvent?.project ? editEvent?.project : false;
  const isTodo = editEvent?.todo ? editEvent?.todo : false;
  const isEvent = editEvent?.event ? editEvent?.event : false;

  useEffect(() => {
    if (editEvent) {
      setValues({
        name: editEvent.title,
        description: editEvent.desc,
        start: moment(editEvent.start),
        end: moment(editEvent.end),
      });
    }
  }, [editEvent]);

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle event create
  const handleEventUpdate = async (e) => {
    e.preventDefault();

    // Check if the form is valid
    if (values.name.length === 0 || values.description.length === 0) {
      ErrorNotification("Please fill in all fields");
      return;
    }

    // Convert start and end dates mongoose Date format with the same time
    values.start = moment(values.start).toDate();
    values.end = moment(values.end).toDate();

    // If user is admin, dispatch action to create event
    if (user?.role === "admin") {
      // Dispatch action to update event
      dispatch(
        updateAdminEventAction(isProject, editEvent, values, isTodo, isEvent)
      );
      setValues(initialState);
      handleModalClose();
    } else if (
      (user?.role === "manager" || user?.role === "member") &&
      isProject
    ) {
      ErrorNotification("You can't modify a project event");
      handleModalClose();
    } else if (user?.role === "manager" && !isProject) {
      dispatch(
        updateManagerEventAction(isProject, editEvent, values, isTodo, isEvent)
      );
      setValues(initialState);
      handleModalClose();
    } else if (user?.role === "member" && !isProject) {
      dispatch(
        updateMemberEventAction(isProject, editEvent, values, isTodo, isEvent)
      );
      setValues(initialState);
      handleModalClose();
    } else if (user?.role === "guest" && !isProject) {
      dispatch(
        updateGuestEventAction(isProject, editEvent, values, isTodo, isEvent)
      );
      setValues(initialState);
      handleModalClose();
    }
  };

  // Handle event delete
  const handleEventDelete = async (e) => {
    e.preventDefault();
    handleModalClose();

    // If user is admin
    if (user?.role === "admin") {
      Modal.confirm({
        title: "Are you sure you want to delete this event?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          try {
            // Delete event
            await deleteAdminEventById(editEvent.id);
            // Fetch all events
            fetchEvents();
            // Show success notification
            SuccessNotification("Event deleted successfully");
          } catch (error) {
            // Show error notification
            ErrorNotification("Error deleting event");
          }
        },
      });
    } else if (user?.role === "manager") {
      Modal.confirm({
        title: "Are you sure you want to delete this event?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          try {
            // Delete event
            await deleteManagerEventById(editEvent.id);
            // Fetch all events
            fetchEvents();
            // Show success notification
            SuccessNotification("Event deleted successfully");
          } catch (error) {
            // Show error notification
            ErrorNotification("Error deleting event");
          }
        },
      });
    } else if (user?.role === "member") {
      Modal.confirm({
        title: "Are you sure you want to delete this event?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          try {
            // Delete event
            await deleteMemberEventById(editEvent.id);
            // Fetch all events
            fetchEvents();
            // Show success notification
            SuccessNotification("Event deleted successfully");
          } catch (error) {
            // Show error notification
            ErrorNotification("Error deleting event");
          }
        },
      });
    } else if (user?.role === "guest") {
      Modal.confirm({
        title: "Are you sure you want to delete this event?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          try {
            // Delete event
            await deleteGuestEventById(editEvent.id);
            // Fetch all events
            fetchEvents();
            // Show success notification
            SuccessNotification("Event deleted successfully");
          } catch (error) {
            // Show error notification
            ErrorNotification("Error deleting event");
          }
        },
      });
    }
  };

  return (
    <>
      <Modal
        title={
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Update Event
          </h2>
        }
        visible={open}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <Button key="back" onClick={handleModalClose}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleEventUpdate}
            loading={false}
            disabled={
              (user?.role === "manager" || user?.role === "member") && isProject
            }
          >
            Update
          </Button>,

          // Don't show delete button for projects and todos
          !isTodo && !isProject && (
            <Button
              key="delete"
              type="danger"
              onClick={handleEventDelete}
              loading={false}
              disabled={
                (user?.role === "manager" || user?.role === "member") &&
                isProject
              }
            >
              Delete
            </Button>
          ),
        ]}
      >
        <Form
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          {/* Event name */}
          <Form.Item label="Name">
            <Input
              name="name"
              placeholder="Enter event name"
              onChange={handleFormChange}
              value={values.name}
              rules={[{ required: true }]}
              disabled={
                (user?.role === "manager" || user?.role === "member") &&
                isProject
              }
            />
          </Form.Item>

          {/* Event description */}
          <Form.Item label="Description">
            <Input.TextArea
              name="description"
              placeholder="Enter event description"
              onChange={handleFormChange}
              value={values.description}
              autoSize={{ minRows: 2, maxRows: 6 }}
              disabled={
                (user?.role === "manager" || user?.role === "member") &&
                isProject
              }
            />
          </Form.Item>

          {/* Event start time */}
          {values?.start && (
            <Form.Item label="Start">
              <DatePicker
                name="start"
                showTime={{ format: "HH:mm" }}
                value={values.start}
                onChange={(date, dateString) => {
                  setValues({
                    ...values,
                    start: date,
                  });
                }}
                disabled={
                  (user?.role === "manager" || user?.role === "member") &&
                  isProject
                }
                autoFocus
                popupStyle={{
                  height: "auto",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                style={{
                  width: "100%",
                }}
                popupAlign={{
                  offset: [0, 0],
                }}
              />
            </Form.Item>
          )}

          {/* Event end time */}
          {values?.end && (
            <Form.Item label="End">
              <DatePicker
                name="end"
                showTime={{ format: "HH:mm" }}
                value={values.end}
                onChange={(date, dateString) => {
                  setValues({
                    ...values,
                    end: date,
                  });
                }}
                disabled={
                  (user?.role === "manager" || user?.role === "member") &&
                  isProject
                }
                autoFocus
                popupStyle={{
                  height: "auto",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                style={{
                  width: "100%",
                }}
                popupAlign={{
                  offset: [0, 0],
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default EditEventModal;
