import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { BiArrowBack } from "react-icons/bi";
import { getProjectById } from "../../../api/project";
import MilestoneList from "./milestone/MilestoneList";
import { ProjectOverviewCard, TeamListCard } from "../../../components";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
// Joyride
import Joyride, { STATUS } from "react-joyride";
import { adminProjectDetailsSteps } from "../../../helpers/joyride/adminProjectDetailsSteps";
import { updateAdminTutStatusAction } from "../../../redux/actions/adminActions";

const initialState = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  estimatedHours: "",
  budget: "",
  tags: [],
  status: "",
  assignedTo: [],
  managers: [],
};

const ProjectDetail = ({ history }) => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({ totalTasks: 0, completedTasks: 0 });
  const [tutFinished, setTutFinished] = useState(
    JSON.parse(localStorage.getItem("joyride"))?.projDetails
  );

  // Get project by id
  const fetchProject = async () => {
    try {
      setLoading(true);
      const { data } = await getProjectById(id);
      data.project.startDate = data.project.startDate.split("T")[0];
      data.project.endDate = data.project.endDate.split("T")[0];
      setValues(data.project);
      // Set the total tasks and completed tasks
      setStats({
        totalTasks: data.totalTasks,
        completedTasks: data.completedTasks,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("GET_PROJECT_BY_ID_ERROR", error);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  // Handle back button click
  const handleBackButtonClick = () => {
    if (user?.role === "admin") {
      history.push("/admin/projects");
    } else if (user?.role === "manager") {
      history.push("/manager/projects");
    } else if (user?.role === "guest") {
      history.push("/guest/projects");
    }
  };

  // Handle joyride finish
  const handleJoyrideCallback = (data) => {
    if (data.status === STATUS.FINISHED) {
      setTutFinished(true);
      const pageToUpdate = {
        projDetails: true,
      };

      // If user is admin
      if (user?.role === "admin") {
        dispatch(updateAdminTutStatusAction(pageToUpdate));
      }

      // Get the joyride object from local storage
      const joyrideObj = JSON.parse(localStorage.getItem("joyride"));

      // Update the joyride object
      joyrideObj.projDetails = true;

      // Set the joyride object to local storage
      localStorage.setItem("joyride", JSON.stringify(joyrideObj));
    }
  };

  return (
    <>
      {loading ? (
        <div
          className="clock-loader"
          style={{ top: "calc(50vh - 100px)" }}
        ></div>
      ) : (
        <>
          {/* <Joyride
            steps={adminProjectDetailsSteps}
            run={!tutFinished}
            continuous
            showProgress={true}
            showSkipButton={false}
            // Run only once
            callback={(data) => {
              handleJoyrideCallback(data);
            }}
            disableScrolling
            disableOverlayClose
            disableCloseOnEsc
            spotlightClicks
          /> */}
          <Row>
            <Col span={24}>
              <Row>
                <Col span={10}>
                  <BiArrowBack
                    style={{
                      fontSize: "2em",
                      cursor: "pointer",
                      marginTop: "-3em",
                    }}
                    onClick={() => handleBackButtonClick()}
                  />
                </Col>
              </Row>

              {/* Project Overview Section */}
              <Row>
                <Col span={24}>
                  <ProjectOverviewCard stats={stats} values={values} />
                </Col>
              </Row>

              {/* Place Milestone List and Member List in the same row */}
              <Row>
                <Col span={16}>
                  {/* Project Milestone List Section */}
                  <MilestoneList
                    milestones={values.milestones}
                    loading={loading}
                    fetchProject={fetchProject}
                  />
                </Col>

                <Col span={8}>
                  {/* Project Team List Section */}
                  <TeamListCard
                    members={values.assignedTo}
                    managers={values.managers}
                    fetchUpdate={fetchProject}
                    updateType="project"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProjectDetail;
