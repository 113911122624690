import { ACTION_TYPES, DASHBOARD_TYPES } from "../constants/actionTypes";
import { GUEST_TYPES } from "../constants/guestTypes";
import {
  createGuestEvent,
  getGuestEvents,
  updateGuestEventById,
  getGuestAnalytics,
} from "../../api/guest";
import { updateTodoById } from "../../api/user";
import { SuccessNotification, ErrorNotification } from "../../components";

/********************************************
  Create guest event action
*********************************************/
export const createGuestEventAction = (event) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Fetch response from server
    createGuestEvent(event)
      .then(() => {
        SuccessNotification(`Event created successfully`);
        // Dispatch get guest events action
        dispatch(getGuestEventsAction());
      })
      .catch((err) => {
        ErrorNotification(err.response.data.message);
        console.log("CREATE_GUEST_EVENT_ERROR", err);
      });

    // Dispatch a success/error sign up alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "CREATE_GUEST_EVENT_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Get guest events action
*********************************************/
export const getGuestEventsAction = (setLoading) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Fetch response from server
    const res = await getGuestEvents();

    // Filter the name, description, startDate, endDate, and _id from projects
    const filteredProjects = res.data.projects.map((project) => {
      return {
        id: project._id,
        title: project.name,
        desc: project.description,
        start: new Date(project.startDate),
        end: new Date(project.endDate),
        allDay: true,
        project: true,
        color: project.color,
      };
    });

    // Filter the name, description, startDate, endDate, and _id from todos
    const filteredTodos = res.data.todos.map((todo) => {
      return {
        id: todo._id,
        title: todo.name,
        desc: todo.description,
        start: new Date(todo.startDate),
        end: new Date(todo.endDate),
        todo: true,
        color: todo.color,
      };
    });

    // Filter the name, description, startDate, endDate, and _id from events
    const filteredEvents = res.data.events.map((event) => {
      return {
        id: event._id,
        title: event.name,
        desc: event.description,
        start: new Date(event.start),
        end: new Date(event.end),
        event: true,
        color: event.color,
      };
    });

    // Combine the projects, todos and events
    const events = filteredProjects
      .concat(filteredEvents)
      .concat(filteredTodos);

    // Dispatch milestone and tasks
    dispatch({
      type: GUEST_TYPES.GET_GUEST_EVENTS,
      payload: {
        events,
      },
    });

    setLoading(false);

    // Dispatch a success/error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "GET_GUEST_EVENTS_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Update guest event action
*********************************************/
export const updateGuestEventAction =
  (isProject, editEvent, values, isTodo, isEvent) => async (dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      if (isEvent) {
        updateGuestEventById(editEvent.id, values)
          .then(() => {
            SuccessNotification(`Event updated successfully`);
            // Dispatch get guest events action
            dispatch(getGuestEventsAction());
          })
          .catch((err) => {
            ErrorNotification(err.response.data.message);
            console.log("UPDATE_GUEST_EVENT_ERROR", err);
          });
      } else if (isTodo) {
        // Modify value name from start to startDate and end to endDate
        const startDate = values.start;
        const endDate = values.end;
        const newValues = {
          startDate,
          endDate,
          name: values.name,
          description: values.description,
        };
        updateTodoById(editEvent.id, newValues)
          .then(() => {
            SuccessNotification(`Todo updated successfully`);
            // Dispatch get admin events action
            dispatch(getGuestEventsAction());
          })
          .catch((err) => {
            ErrorNotification(err.response.data.message);
            console.log("UPDATE_ADMIN_TODO_ERROR", err);
          });
      }

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_GUEST_EVENTS_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Get guest analytics action
*********************************************/
export const getGuestAnalyticsAction = (setLoading) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Fetch response from server
    const res = await getGuestAnalytics();

    // Dispatch analytics
    dispatch({
      type: DASHBOARD_TYPES.GET_ANALYTICS,
      payload: res.data,
    });

    setLoading(false);

    // Dispatch a success/error sign up alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "GET_GUEST_ANALYTICS_ACTION_ERROR",
      },
    });
  }
};
