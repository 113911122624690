import { v4 as uuid } from "uuid";
import { ACTION_TYPES } from "../constants/actionTypes";
import { USER_TYPES } from "../constants/userTypes";
import { getAllUserTodos, updateTodoById } from "../../api/user";

/********************************************
  Get all todos of user action
*********************************************/
export const getTodosOfUserAction = (setLoading) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Fetch response from server
    const res = await getAllUserTodos();

    const stageList = ["To do", "In Progress", "Review", "Done"];
    const stageListWithEmptyCards = stageList.reduce((acc, stage) => {
      if (!acc[stage]) {
        acc[stage] = {
          id: uuid(),
          title: stage,
          cards: [],
        };
      }
      return acc;
    }, {});

    // For each todo in todos, add it to the correct stage's cards
    res.data?.todos?.forEach((todo) => {
      stageListWithEmptyCards[todo.stage].cards.push(todo);
    });

    // Rename the group name with a unique id
    const modifiedTodoList = Object.keys(stageListWithEmptyCards).reduce(
      (acc, stage) => {
        acc[stageListWithEmptyCards[stage].id] = stageListWithEmptyCards[stage];
        return acc;
      },
      {}
    );

    // Rename key of each todo name to title
    const modifiedTodoListWithTitle = Object.keys(modifiedTodoList).reduce(
      (acc, stage) => {
        acc[stage] = {
          ...modifiedTodoList[stage],
          cards: modifiedTodoList[stage].cards.map((todo) => ({
            ...todo,
            title: todo.name,
            id: todo._id,
          })),
        };
        return acc;
      },
      {}
    );

    // Extract the stage names from the modifiedTodoListWithTitle
    const stageNames = Object.keys(modifiedTodoListWithTitle);

    const kanbanObj = { listIds: stageNames, lists: modifiedTodoListWithTitle };

    // Dispatch todos
    dispatch({
      type: USER_TYPES.GET_USER_TODOS,
      payload: {
        todos: res.data,
        kanbanObj,
      },
    });

    setLoading(false);

    // Dispatch a success/error sign up alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "GET_TODOS_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Update todos board action
*********************************************/
export const updateTodosBoardAction = () => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Dispatch getTodosOfUserAction action
    dispatch(getTodosOfUserAction());

    // Dispatch a success/error sign up alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "UPDATE_TODOS_BOARD_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Update todo status action
*********************************************/
export const updateTodoStatusAction =
  (kanbanObj, src, dest, todoId) => async (dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Get the stage of the destination card
      const destStage = kanbanObj.lists[dest].title;

      // Update todo stage to the destination stage
      await updateTodoById(todoId, { stage: destStage }).then(() => {
        // Dispatch updateTodosBoardAction action
        dispatch(updateTodosBoardAction());
      });

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_TODO_STATUS_ACTION_ERROR",
        },
      });
    }
  };
