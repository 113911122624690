import React from "react";
import { Card, Row, Col, Tag, Progress } from "antd";
import {
  RiCalendarCheckFill,
  RiCalendarCheckLine,
  RiTimerFill,
} from "react-icons/ri";

const OverviewCard = ({ status, percentComplete, values }) => {
  const dateFormat = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return (
    <Card className="hp-border-color-black-40 hp-mb-16 hp-card-1">
      <Row gutter={16} justify="space-between">
        {/* Header */}
        <Col className="hp-px-0" span={24}>
          <Row justify="space-between">
            <h3 className="hp-mr-8">
              {values.name.charAt(0).toUpperCase() + values.name.slice(1)} -
              Overview
            </h3>
          </Row>
        </Col>

        {/* Overview */}
        <Col
          span={2}
          className="hp-mb-xs-32 hp-px-0"
          style={{
            marginTop: "1em",
          }}
        >
          <h5 className="hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
            Status
          </h5>

          <h2 className="hp-my-4">
            <Tag
              color={
                status === "Completed"
                  ? "green"
                  : status === "In Progress"
                  ? "blue"
                  : status === "On Hold"
                  ? "yellow"
                  : "red"
              }
            >
              {status}
            </Tag>
          </h2>
        </Col>

        <Col
          span={6}
          className="hp-mb-xs-32 hp-px-0"
          style={{
            marginTop: "1em",
            marginRight: "2em",
          }}
        >
          <h5 className="hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
            Project Completion
          </h5>
          <Progress
            percent={percentComplete}
            format={(percent) => `${Math.round(percent)} %`}
            style={{
              marginTop: "1.5em",
            }}
          />
        </Col>

        {/* Start Date */}
        <Col
          span={4}
          className="hp-mb-xs-32"
          style={{
            marginTop: "1.5em",
          }}
        >
          <Row>
            <Col className="hp-statistic-icon-bg hp-mr-lg-8 hp-mb-xs-16 hp-bg-color-primary-4 hp-bg-color-dark-primary">
              <RiCalendarCheckFill
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 remix-icon"
                size={24}
              />
            </Col>

            <Col>
              <h4 className="hp-mb-4" style={{ fontSize: "1rem" }}>
                {new Date(values.startDate).toLocaleDateString(
                  "en-US",
                  dateFormat
                )}
              </h4>
              <p className="hp-badge-text hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                Start date
              </p>
            </Col>
          </Row>
        </Col>

        {/* End Date */}
        <Col span={4} className="hp-mb-xs-32" style={{ marginTop: "1.5em" }}>
          <Row>
            <Col className="hp-statistic-icon-bg hp-mr-lg-8 hp-mb-xs-16 hp-bg-color-warning-4 hp-bg-color-dark-warning">
              <RiCalendarCheckLine
                className="hp-text-color-warning-1 remix-icon"
                size={24}
              />
            </Col>

            <Col>
              <h4 className="hp-mb-4" style={{ fontSize: "1rem" }}>
                {new Date(values.endDate).toLocaleDateString(
                  "en-US",
                  dateFormat
                )}
              </h4>
              <p className="hp-badge-text hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                End date
              </p>
            </Col>
          </Row>
        </Col>

        {/* Day's Left */}
        <Col
          span={4}
          style={{
            marginTop: "1.5em",
          }}
        >
          <Row>
            <Col className="hp-statistic-icon-bg hp-mr-lg-8 hp-mr-xs-8 hp-mb-xs-16 hp-bg-color-danger-4 hp-bg-color-dark-danger">
              <RiTimerFill
                className="hp-text-color-danger-1 remix-icon"
                size={24}
              />
            </Col>

            <Col>
              <h4 className="hp-mb-4" style={{ fontSize: "1rem" }}>
                {Math.floor(
                  (new Date(values.endDate).getTime() - new Date().getTime()) /
                    (1000 * 60 * 60 * 24) >
                    0
                    ? Math.floor(
                        (new Date(values.endDate).getTime() -
                          new Date().getTime()) /
                          (1000 * 60 * 60 * 24)
                      )
                    : 0
                )}
              </h4>
              <p className="hp-badge-text hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                Days left
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default OverviewCard;
