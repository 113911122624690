import api from "./index";

// Create a tag
export const createTag = async (values) => {
  return await api.post("/tag", values);
};

// Get all tags
export const getAllTags = async () => {
  return await api.get(`/tags`);
};

// Get a tag by id
export const getTagById = async (id) => {
  return await api.get(`/tag/${id}`);
};

// Update a tag by id
export const updateTagById = async (id, values) => {
  return await api.put(`/tag/${id}`, values);
};

// Delete a tag by id
export const deleteTagById = async (id) => {
  return await api.delete(`/tag/${id}`);
};
