import React from "react";
import { Row, Slider, Drawer, Radio, Space } from "antd";

const AllTasksFilter = ({
  onClose,
  visible,
  // Filter States
  projectNames,
  memberNames,
  // Set Filter States
  filteredProject,
  setFilteredProject,
  filteredMember,
  setFilteredMember,
  filteredDaysLeft,
  setFilteredDaysLeft,
  filteredStage,
  setFilteredStage,
  filteredPriority,
  setFilteredPriority,
}) => {
  // Handle project name filter set
  const handleProjectNameFilter = (e) => {
    setFilteredProject(e.target.value);
  };

  // Handle member name filter set
  const handleMemberNameFilter = (e) => {
    setFilteredMember(e.target.value);
  };

  return (
    <>
      <Drawer
        title={
          <h3
            style={{
              textAlign: "center",
            }}
          >
            Filters
          </h3>
        }
        placement="right"
        size="medium"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        {/* By Project */}
        <h5
          style={{
            fontWeight: "bold",
          }}
        >
          By Project
        </h5>
        <Radio.Group value={filteredProject} onChange={handleProjectNameFilter}>
          <Space direction="vertical">
            <Radio key={891} value="">
              All Projects{" "}
            </Radio>
            {projectNames.map((name, i) => (
              <Radio key={i} value={name}>
                {name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>

        {/* 2 Line Space */}
        <Row style={{ marginTop: "1.5rem" }} />

        {/* By Members */}
        <h5
          style={{
            fontWeight: "bold",
          }}
        >
          By Assigned To
        </h5>
        <Radio.Group value={filteredMember} onChange={handleMemberNameFilter}>
          <Space direction="vertical">
            <Radio key={891} value="">
              All Members
            </Radio>
            {memberNames.map((name, i) => (
              <Radio key={i} value={name}>
                {name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>

        {/* 2 Line Space */}
        {/* <Row style={{ marginTop: "1.5rem" }} /> */}

        {/* By Days Left */}
        {/* <h5
          style={{
            fontWeight: "bold",
          }}
        >
          Days Left
        </h5>
        <Slider
          min={0}
          max={60}
          step={1}
          value={filteredDaysLeft}
          onChange={(value) => setFilteredDaysLeft(value)}
        /> */}

        {/* 2 Line Space */}
        <Row style={{ marginTop: "1.5rem" }} />

        {/* By Task Stage */}
        <h5
          style={{
            fontWeight: "bold",
          }}
        >
          Task Stage
        </h5>
        {/* Drop Down with "To do", "In Progress", "Review", "Done" */}
        <Radio.Group
          value={filteredStage}
          onChange={(e) => setFilteredStage(e.target.value)}
        >
          <Space direction="vertical">
            <Radio key={0} value="">
              All
            </Radio>
            <Radio key={1} value="To do">
              To do
            </Radio>
            <Radio key={2} value="In Progress">
              In Progress
            </Radio>
            <Radio key={3} value="Review">
              Review
            </Radio>
            <Radio key={4} value="Done">
              Done
            </Radio>
          </Space>
        </Radio.Group>

        {/* 2 Line Space */}
        <Row style={{ marginTop: "1.5rem" }} />

        {/* By Task Priority */}
        <h5
          style={{
            fontWeight: "bold",
          }}
        >
          Task Priority
        </h5>
        <Radio.Group
          value={filteredPriority}
          onChange={(e) => setFilteredPriority(e.target.value)}
        >
          <Space direction="vertical">
            <Radio key={0} value="">
              All
            </Radio>
            <Radio key={1} value="Low">
              Low
            </Radio>
            <Radio key={2} value="Medium">
              Medium
            </Radio>
            <Radio key={3} value="High">
              High
            </Radio>
            <Radio key={4} value="Critical">
              Critical
            </Radio>
          </Space>
        </Radio.Group>
      </Drawer>
    </>
  );
};

export default AllTasksFilter;
