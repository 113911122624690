import api from "./index";

// Check if current user is a guest
export const currentGuest = () => {
  return api.post("/currentGuest");
};

// Get all guest team members
export const getGuestTeamMembers = () => {
  return api.get("/guest/team/members");
};

// Get all guest projects
export const getAllGuestProjects = () => {
  return api.get("/guest/projects");
};

// Search for guest team members using query string of name or email
export const searchGuestTeamMembers = async (query) => {
  return await api.get(`/guest/team/search?keyword=${query}`);
};

// Get guest team members for chat
export const getGuestTeam = () => {
  return api.get("/guest/team");
};

// Create guest event
export const createGuestEvent = async (values) => {
  return await api.post("/guest/events", values);
};

// Get all guest events
export const getGuestEvents = async () => {
  return await api.get("/guest/events");
};

// Update guest event by id
export const updateGuestEventById = async (id, values) => {
  return await api.put(`/guest/event/${id}`, values);
};

// Delete guest event by id
export const deleteGuestEventById = async (id) => {
  return await api.delete(`/guest/event/${id}`);
};

// Get guest analytics
export const getGuestAnalytics = async () => {
  return await api.get("/guest/analytics");
};
