import { ACTION_TYPES, DASHBOARD_TYPES } from "../constants/actionTypes";
import { MANAGER_TYPES } from "../constants/managerTypes";
import { ADMIN_TYPES } from "../constants/adminTypes";
import {
  createManagerEvent,
  getManagerEvents,
  updateManagerEventById,
  getManagerAnalytics,
  getManagerTeamMembers,
} from "../../api/manager";
import { updateTodoById } from "../../api/user";
import { SuccessNotification, ErrorNotification } from "../../components";

/********************************************
  Create manager event action
*********************************************/
export const createManagerEventAction = (event) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Fetch response from server
    createManagerEvent(event)
      .then(() => {
        SuccessNotification(`Event created successfully`);
        // Dispatch get manager events action
        dispatch(getManagerEventsAction());
      })
      .catch((err) => {
        ErrorNotification(err.response.data.message);
        console.log("CREATE_MANAGER_EVENT_ERROR", err);
      });

    // Dispatch a success/error sign up alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "CREATE_MANAGER_EVENT_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Get manager events action
*********************************************/
export const getManagerEventsAction = (setLoading) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Fetch response from server
    const res = await getManagerEvents();

    // Filter the name, description, startDate, endDate, and _id from projects
    const filteredProjects = res.data.projects.map((project) => {
      return {
        id: project._id,
        title: project.name,
        desc: project.description,
        start: new Date(project.startDate),
        end: new Date(project.endDate),
        allDay: true,
        project: true,
        color: project.color,
      };
    });

    // Filter the name, description, startDate, endDate, and _id from todos
    const filteredTodos = res.data.todos.map((todo) => {
      return {
        id: todo._id,
        title: todo.name,
        desc: todo.description,
        start: new Date(todo.startDate),
        end: new Date(todo.endDate),
        todo: true,
        color: todo.color,
      };
    });

    // Filter the name, description, startDate, endDate, and _id from events
    const filteredEvents = res.data.events.map((event) => {
      return {
        id: event._id,
        title: event.name,
        desc: event.description,
        start: new Date(event.start),
        end: new Date(event.end),
        event: true,
        color: event.color,
      };
    });

    // Combine the projects, todos and events
    const events = filteredProjects
      .concat(filteredEvents)
      .concat(filteredTodos);

    // Dispatch milestone and tasks
    dispatch({
      type: MANAGER_TYPES.GET_MANAGER_EVENTS,
      payload: {
        events,
      },
    });

    setLoading(false);

    // Dispatch a success/error sign up alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "GET_MANAGER_EVENTS_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Update manager event action
*********************************************/
export const updateManagerEventAction =
  (isProject, editEvent, values, isTodo, isEvent) => async (dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      if (isEvent) {
        updateManagerEventById(editEvent.id, values)
          .then(() => {
            SuccessNotification(`Event updated successfully`);
            // Dispatch get manager events action
            dispatch(getManagerEventsAction());
          })
          .catch((err) => {
            ErrorNotification(err.response.data.message);
            console.log("UPDATE_MANAGER_EVENT_ERROR", err);
          });
      } else if (isTodo) {
        // Modify value name from start to startDate and end to endDate
        const startDate = values.start;
        const endDate = values.end;
        const newValues = {
          startDate,
          endDate,
          name: values.name,
          description: values.description,
        };
        updateTodoById(editEvent.id, newValues)
          .then(() => {
            SuccessNotification(`Todo updated successfully`);
            // Dispatch get admin events action
            dispatch(getManagerEventsAction());
          })
          .catch((err) => {
            ErrorNotification(err.response.data.message);
            console.log("UPDATE_ADMIN_TODO_ERROR", err);
          });
      }

      // Dispatch a success/error sign up alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Dispatch a error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: "UPDATE_MANAGER_EVENTS_ACTION_ERROR",
        },
      });
    }
  };

/********************************************
  Get manager analytics action
*********************************************/
export const getManagerAnalyticsAction = (setLoading) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Fetch response from server
    const res = await getManagerAnalytics();

    // Dispatch analytics
    dispatch({
      type: DASHBOARD_TYPES.GET_ANALYTICS,
      payload: res.data,
    });

    setLoading(false);

    // Dispatch a success/error sign up alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "GET_MANAGER_ANALYTICS_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Get manger team members action
*********************************************/
export const getManagerTeamMembersAction = () => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    // Fetch response from server
    const res = await getManagerTeamMembers();

    // Combine managers, members & guests to form a team
    const team = res.data.managers.concat(res.data.members);

    // Dispatch milestone and tasks to the admin store itself
    dispatch({
      type: ADMIN_TYPES.GET_TEAM_MEMBERS,
      payload: {
        team,
        members: res.data.members,
        managers: res.data.managers,
        guests: res.data.guests,
      },
    });

    // Dispatch a success/error sign up alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    // Dispatch a error alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "GET_MANAGER_TEAM_MEMBERS_ACTION_ERROR",
      },
    });
  }
};
