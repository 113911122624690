import React from "react";
import { Row, Col, List, Button } from "antd";
import { GrAttachment } from "react-icons/gr";

const Attachments = ({ values }) => {
  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
    // Time
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  return (
    <>
      <Row>
        <h4>
          <GrAttachment
            style={{
              marginRight: "0.5rem",
              transform: "translateY(0.15rem)",
            }}
          />
          Attachments
        </h4>
      </Row>
      {/* List of uploaded files, with download button click to download file */}
      <Row>
        <Col
          span={24}
          style={{
            overflow: "auto",
            maxHeight: "500px",
          }}
        >
          <List
            itemLayout="horizontal"
            dataSource={values.attachments}
            renderItem={(item) => (
              <>
                <List.Item>
                  <List.Item.Meta
                    title={`${item.user?.name} uploaded ${item?.ogName}`}
                    description={new Date(item.createdAt).toLocaleDateString(
                      "en-US",
                      dateFormat
                    )}
                  />
                  {/* Download button downloads the file */}
                  <a href={item?.url} download>
                    <Button size="small" type="primary">
                      Download
                    </Button>
                  </a>
                </List.Item>
              </>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default Attachments;
