import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Tag } from "antd";
import { ViewUserDetailsModal } from "../../../../components";
import { getTeamAnalytics } from "../../../../api/admin";
import { useHistory } from "react-router-dom";

const { Meta } = Card;

const TeamReport = () => {
  const history = useHistory();
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewUserDetails, setViewUserDetails] = useState();

  useEffect(() => {
    setLoading(true);
    getTeamAnalytics()
      .then((res) => {
        setTeam(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  }, []);

  // Handle view member details modal open/close
  const handleViewDetailsClick = (details) => {
    setViewUserDetails(details);
    setViewModalOpen(!viewModalOpen);
  };

  return (
    <>
      {loading ? (
        <div
          className="clock-loader"
          style={{ top: "calc(50vh - 100px)" }}
        ></div>
      ) : (
        <>
          {/* View Todo Modal */}
          {viewModalOpen && (
            <ViewUserDetailsModal
              open={viewModalOpen}
              handleModalClick={handleViewDetailsClick}
              details={viewUserDetails}
            />
          )}
          <Row
            style={{
              marginBottom: "1.5rem",
            }}
          >
            <Col span={24} xs={8} sm={16} md={24} lg={32}>
              <Row justify="space-between">
                {/* Title */}
                <Col span={5}>
                  <h3
                    style={{
                      marginBottom: "0.5rem",
                    }}
                  >
                    Team Report
                  </h3>
                </Col>

                {/* Back Button */}
                <Col
                  span={6}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Go back
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Team */}
          <Col
            span={24}
            md={24}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(100vh - 200px)",
              paddingRight: "10px",
            }}
          >
            <Row gutter={16}>
              {team?.length > 0 ? (
                team?.map((member) => {
                  return (
                    <Col
                      key={member._id}
                      md={4}
                      style={{
                        transform: "scale(0.9)",
                        marginTop: "-1rem",
                        marginBottom: "1rem",
                        marginRight: "1.8rem",
                      }}
                    >
                      <Card
                        hoverable
                        style={{
                          width: "250px",
                          height: "340px",
                        }}
                        cover={
                          <div
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50%",
                              background: "#512DA8",
                              fontSize: "35px",
                              color: "#fff",
                              textAlign: "center",
                              lineHeight: "80px",
                              margin: "20px 0 0 0",
                              position: "absolute",
                              top: "15%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            {member.name.charAt(0).toUpperCase()}
                          </div>
                        }
                      >
                        <>
                          <Meta
                            title={
                              <>
                                <span
                                  style={{
                                    // Move right to center
                                    marginLeft: "0.5rem",
                                    fontSize: "1.1rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {member.name}
                                </span>
                              </>
                            }
                            description={
                              <Tag
                                color={
                                  member?.role === "manager"
                                    ? "blue"
                                    : member?.role === "member"
                                    ? "orange"
                                    : "volcano"
                                }
                              >
                                {member.role.toUpperCase()}
                              </Tag>
                            }
                            style={{
                              position: "absolute",
                              top: "45%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </>

                        {/* Projects */}
                        <p
                          style={{
                            marginBottom: "0rem",
                            fontSize: "0.95rem",
                            position: "absolute",
                            top: "60%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <b>Open Projects:</b> {member.stats.activeProjects}
                        </p>

                        {/* Milestones */}
                        {member.role === "member" && (
                          <p
                            style={{
                              marginBottom: "0rem",
                              fontSize: "0.95rem",
                              position: "absolute",
                              top: "70%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <b>Milestones:</b> {member.stats.activeMilestones}
                          </p>
                        )}

                        {/* Tasks */}
                        <p
                          style={{
                            marginBottom: "0rem",
                            fontSize: "0.95rem",
                            position: "absolute",
                            top: "80%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <b>Open Tasks:</b> {member.stats.activeTasks}
                        </p>

                        {/* Button to view details */}
                        <Button
                          type="primary"
                          size="small"
                          style={{
                            position: "absolute",
                            top: "92%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          onClick={() => {
                            if (member.role === "member") {
                              handleViewDetailsClick({
                                name: member.name,
                                role: member.role,
                                projects: member.details.projects,
                                milestones: member.details.milestones,
                                tasks: member.details.tasks,
                              });
                            } else if (member.role === "manager") {
                              handleViewDetailsClick({
                                name: member.name,
                                role: member.role,
                                projects: member.details.projects,
                                tasks: member.details.tasks,
                              });
                            }
                          }}
                        >
                          View Details
                        </Button>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <Col span={24}>
                  <h3>No team members found</h3>
                </Col>
              )}
            </Row>
          </Col>
        </>
      )}
    </>
  );
};

export default TeamReport;
