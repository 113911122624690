import React from "react";
import { Divider } from "antd";
import {
  Timeline,
  Checklist,
  Attachments,
  Logs,
  Priority,
  Actions,
} from "./subComponentsRight";

const TodoRightCol = ({
  values,
  setValues,
  handleUpdateTodo,
  todoId,
  handleModalClick,
}) => {
  return (
    <>
      <h4>Add to todo</h4>

      {/* Timeline */}
      <Timeline
        values={values}
        setValues={setValues}
        handleUpdateTodo={handleUpdateTodo}
      />

      {/* Priority */}
      <Priority
        values={values}
        setValues={setValues}
        handleUpdateTodo={handleUpdateTodo}
      />

      {/* Checklist */}
      <Checklist todoId={todoId} values={values} setValues={setValues} />

      {/* Attachments */}
      <Attachments todoId={todoId} values={values} setValues={setValues} />

      <Divider />

      {/* Logs */}
      <Logs values={values} />

      <Divider />

      {/* Actions */}
      <Actions todoId={todoId} handleModalClick={handleModalClick} />
    </>
  );
};

export default TodoRightCol;
