import { MEMBER_TYPES } from "../constants/memberTypes";

// Initial state
const initialState = {};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_TYPES.GET_MEMBER_PROJECTS:
      return action?.payload;
    case MEMBER_TYPES.GET_MEMBER_EVENTS:
      return action?.payload;
    default:
      return state;
  }
};

export default memberReducer;
