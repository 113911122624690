import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Input, Button, Checkbox } from "antd";
import LeftContent from "./leftContent";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { sumoSignupAction } from "../../../redux/actions/authActions";
import { ErrorNotification } from "../../../components";
import { validateCoupon } from "../../../api/coupon";
import { fireConfetti } from "../../../utils/confetti";

const initialFormData = {
  name: "",
  email: "",
  organization: "",
  password: "",
};

const AppSumo = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialFormData);
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validCoupon, setValidCoupon] = useState(false);

  useEffect(() => {
    if (code) {
      validateCoupon({ code })
        .then((res) => {
          if (res.data === true) {
            setValidCoupon(true);
            fireConfetti();
          } else {
            setValidCoupon(false);
            ErrorNotification(
              "Coupon is not valid, please contact us for more info"
            );
          }
        })
        .catch((err) => {
          ErrorNotification(
            "Coupon is not valid, please contact us for more info"
          );
        });
    }
  }, [code]);

  // Handle form change
  const handleFormChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle email sign up
  const handleEmailSignup = (e) => {
    e.preventDefault();

    const { name, email, organization, password } = formData;

    // Check if all fields are filled
    if (name === "" || email === "" || organization === "" || password === "") {
      ErrorNotification("Please enter all fields");
      return;
    }

    // If not agreed, show error
    if (!agree) {
      ErrorNotification("You need to agree to the terms and conditions");
      return;
    }

    try {
      // Dispatch email, password, org to action
      dispatch(
        sumoSignupAction({
          name,
          email,
          organization,
          password,
          setFormData,
          initialFormData,
          setLoading,
          code,
        })
      );
    } catch (error) {
      console.log("SIGNUP_ERROR", error);
    }
  };

  return (
    <>
      <Row gutter={[32, 0]} className="hp-authentication-page">
        <LeftContent />

        <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
          <Row className="hp-h-100" align="middle" justify="center">
            <Col
              xxl={11}
              xl={15}
              lg={20}
              md={20}
              sm={24}
              className="hp-px-sm-8 hp-pt-24 hp-pb-48"
            >
              <h1 className="hp-mb-sm-0">Create Account</h1>

              <Form
                layout="vertical"
                name="basic"
                className="hp-mt-sm-16 hp-mt-32"
              >
                <Form.Item label="Name :" className="hp-mb-16">
                  <Input
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleFormChange}
                  />
                </Form.Item>

                <Form.Item label="Email :">
                  <Input
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleFormChange}
                  />
                </Form.Item>

                <Form.Item label="Organization :">
                  <Input
                    id="organization"
                    name="organization"
                    value={formData.organization}
                    onChange={handleFormChange}
                  />
                </Form.Item>

                <Form.Item label="Password :">
                  <Input.Password
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleFormChange}
                  />
                </Form.Item>

                {/* Check box agreeing to terms and privacy */}
                <Form.Item>
                  <Row>
                    <Col span={24}>
                      <Checkbox
                        checked={agree}
                        onChange={() => setAgree(!agree)}
                      >
                        I agree to the{" "}
                        <Link
                          to="/terms"
                          className="hp-text-color-blue-100 hp-text-color-dark-80"
                        >
                          Term of use
                        </Link>{" "}
                        &{" "}
                        <Link
                          to="/privacy"
                          className="hp-text-color-blue-100 hp-text-color-dark-80"
                        >
                          Privacy policy
                        </Link>
                      </Checkbox>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item className="hp-mt-16 hp-mb-8">
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={handleEmailSignup}
                    loading={loading}
                    disabled={!validCoupon}
                  >
                    Sign up
                  </Button>
                </Form.Item>
              </Form>

              <div className="hp-form-info">
                <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                  Already have an account?{" "}
                </span>

                <Link
                  to="/login"
                  className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                >
                  Login
                </Link>
              </div>

              <Col
                className="hp-other-links hp-mt-24"
                style={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Link
                  to="/privacy"
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                >
                  Privacy Policy
                </Link>

                {/* A dot in between */}
                <span
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                  style={{ margin: "0 8px", fontSize: "12px" }}
                >
                  .
                </span>

                <Link
                  to="/terms"
                  className="hp-text-color-black-80 hp-text-color-dark-40"
                >
                  Term of use
                </Link>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AppSumo;
