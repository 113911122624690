import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import { RiCloseFill } from "react-icons/ri";
import { updateUserProfile } from "../../../api/user";
import { SuccessNotification, ErrorNotification } from "../../../components";

const initialProfileValues = {
  name: "",
};

const UpdateProfileModal = ({
  editProfileModalVisible,
  editProfileModalCancel,
}) => {
  const [profileValues, setProfileValues] = useState(initialProfileValues);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const handleUpdateProfile = () => {
    setUpdateLoading(true);
    updateUserProfile(profileValues)
      .then(() => {
        SuccessNotification(
          "Profile updated successfully, please reload the page"
        );
        setUpdateLoading(false);
        editProfileModalCancel();
      })
      .catch((err) => {
        ErrorNotification(err.message);
        setUpdateLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        title={
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Details Update
          </h2>
        }
        width={650}
        centered
        visible={editProfileModalVisible}
        onCancel={editProfileModalCancel}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
        footer={[
          <Button key="back" onClick={editProfileModalCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleUpdateProfile}
            loading={updateLoading}
          >
            Update
          </Button>,
        ]}
      >
        {loading && profileValues.name === "" ? (
          <div className="clock-loader"></div>
        ) : (
          <>
            <Form layout="vertical">
              <Form.Item label="Name" name="name">
                <Input
                  name="name"
                  placeholder="Enter your name"
                  size="large"
                  value={profileValues.name ? profileValues.name : ""}
                  onChange={(e) =>
                    setProfileValues({ ...profileValues, name: e.target.value })
                  }
                />
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default UpdateProfileModal;
