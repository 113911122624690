import api from "./index";

// Check current user
export const currentManager = () => {
  return api.post("/currentManager");
};

// Get all manager team members
export const getManagerTeamMembers = () => {
  return api.get("/manager/team/members");
};

// Get all manager projects
export const getAllManagerProjects = () => {
  return api.get("/manager/projects");
};

// Search for manager team members using query string of name or email
export const searchManagerTeamMembers = async (query) => {
  return await api.get(`/manager/team/search?keyword=${query}`);
};

// Get manager team members for chat
export const getManagerTeam = () => {
  return api.get("/manager/team");
};

// Create manager event
export const createManagerEvent = async (values) => {
  return await api.post("/manager/events", values);
};

// Get all manager events
export const getManagerEvents = async () => {
  return await api.get("/manager/events");
};

// Update manager event by id
export const updateManagerEventById = async (id, values) => {
  return await api.put(`/manager/event/${id}`, values);
};

// Delete manager event by id
export const deleteManagerEventById = async (id) => {
  return await api.delete(`/manager/event/${id}`);
};

// Get manager analytics
export const getManagerAnalytics = async () => {
  return await api.get("/manager/analytics");
};

// Create project by manager
export const createManagerProject = async (values) => {
  return await api.post("/manager/project", values);
};

// Get manager all projects tasks
export const getManagerAllProjectsTasks = async () => {
  return await api.get("/manager/projects/tasks");
};
