import React, { useState } from "react";
import { Modal, Button, Tag, Row, Col, Select } from "antd";
import { ParentTable, ParentTableHead } from "../../Atom/Parent/style";

const ViewUserDetailsModal = ({ open, handleModalClick, details }) => {
  const [selectedStage, setSelectedStage] = useState("all");
  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Filter tasks by stage
  const filteredTasks = (stage) => {
    if (stage === "all") {
      return details.tasks;
    } else {
      return details.tasks.filter((task) => task.stage === stage);
    }
  };

  return (
    <>
      <Modal
        title={
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {details.name} - Report
          </h2>
        }
        width={1200}
        visible={open}
        onCancel={handleModalClick}
        onOk={handleModalClick}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Close
          </Button>,
        ]}
      >
        <Row justify="center">
          <h4
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              marginTop: "-1.5rem",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            Projects
          </h4>
        </Row>
        {/* Table of projects with details.project object keys as column names */}
        <ParentTable>
          <ParentTableHead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Status</th>
              <th scope="col">Start date</th>
              <th scope="col">End date</th>
              <th scope="col">Days left</th>
            </tr>
          </ParentTableHead>

          <tbody>
            {details.projects &&
              details.projects?.map((project) => (
                <tr key={project._id} style={{ cursor: "pointer" }}>
                  <td>
                    {/* Text color blue and underlined */}
                    <h5
                      style={{
                        color: "#0070f3",
                        fontSize: "0.9rem",
                      }}
                    >
                      {project.name}
                    </h5>
                  </td>
                  <td>
                    <Tag
                      color={
                        project.status === "Completed"
                          ? "#389e0d"
                          : project.status === "In Progress"
                          ? "blue"
                          : project.status === "On Hold"
                          ? "yellow"
                          : "red"
                      }
                    >
                      {project.status}
                    </Tag>
                  </td>
                  <td>
                    {new Date(project.startDate).toLocaleDateString(
                      "en-US",
                      dateFormat
                    )}
                  </td>
                  <td>
                    {new Date(project.endDate).toLocaleDateString(
                      "en-US",
                      dateFormat
                    )}
                  </td>
                  <td>
                    {Math.floor(
                      (new Date(project.endDate).getTime() -
                        new Date().getTime()) /
                        (1000 * 60 * 60 * 24) >
                        0
                        ? Math.floor(
                            (new Date(project.endDate).getTime() -
                              new Date().getTime()) /
                              (1000 * 60 * 60 * 24)
                          )
                        : 0
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </ParentTable>

        {/* 1 line space */}
        <br />

        {/* Table of tasks with details.tasks object keys as column names */}
        <Row justify="center">
          <Col span={18}>
            <h4
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                marginTop: "1rem",
                marginBottom: "1rem",
                textAlign: "center",
                transform: "translateX(9rem)",
              }}
            >
              Tasks
            </h4>
          </Col>

          {/* Filter by task stage */}
          <Col
            span={6}
            style={{
              transform: "translateX(9.5rem)",
            }}
          >
            <Select
              showSearch
              style={{ width: 130 }}
              placeholder="Filter by..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                setSelectedStage(value);
              }}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="To do">To do</Select.Option>
              <Select.Option value="In Progress">In Progress</Select.Option>
              <Select.Option value="Review">Review</Select.Option>
              <Select.Option value="Done">Done</Select.Option>
            </Select>
          </Col>
        </Row>

        <ParentTable>
          <ParentTableHead>
            <tr>
              <th scope="col">Project</th>
              <th scope="col">Milestone</th>
              <th scope="col">Task</th>
              <th scope="col">Stage</th>
              <th scope="col">Priority</th>
              <th scope="col">Start date</th>
              <th scope="col">End date</th>
              <th scope="col">Days left</th>
            </tr>
          </ParentTableHead>
          <tbody>
            {details.tasks &&
              filteredTasks(selectedStage).map((task) => (
                <tr key={task._id} style={{ cursor: "pointer" }}>
                  <td>
                    <h5
                      style={{
                        color: "#0070f3",
                        fontSize: "0.9rem",
                      }}
                    >
                      {task.projectName}
                    </h5>
                  </td>

                  <td>
                    <h5
                      style={{
                        color: "#0070f3",
                        fontSize: "0.9rem",
                      }}
                    >
                      {task.milestoneName}
                    </h5>
                  </td>

                  <td>
                    <h5
                      style={{
                        color: "#0070f3",
                        fontSize: "0.9rem",
                      }}
                    >
                      {task.name}
                    </h5>
                  </td>
                  <td>
                    <Tag
                      color={
                        task.stage === "Done"
                          ? "#389e0d"
                          : task.stage === "In Progress"
                          ? "blue"
                          : task.stage === "Review"
                          ? "yellow"
                          : "volcano"
                      }
                    >
                      {task.stage}
                    </Tag>
                  </td>
                  <td>
                    <Tag
                      color={
                        task.priority === "Critical"
                          ? "red"
                          : task.priority === "High"
                          ? "orange"
                          : task.priority === "Medium"
                          ? "yellow"
                          : "#389e0d"
                      }
                    >
                      {task.priority}
                    </Tag>
                  </td>
                  <td>
                    {new Date(task.startDate).toLocaleDateString(
                      "en-US",
                      dateFormat
                    )}
                  </td>
                  <td>
                    {new Date(task.endDate).toLocaleDateString(
                      "en-US",
                      dateFormat
                    )}
                  </td>
                  <td>
                    {Math.floor(
                      (new Date(task.endDate).getTime() -
                        new Date().getTime()) /
                        (1000 * 60 * 60 * 24) >
                        0
                        ? Math.floor(
                            (new Date(task.endDate).getTime() -
                              new Date().getTime()) /
                              (1000 * 60 * 60 * 24)
                          )
                        : 0
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </ParentTable>
      </Modal>
    </>
  );
};

export default ViewUserDetailsModal;
