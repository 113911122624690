import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { CreateEventModal, EditEventModal } from "../";
import { CustomAgendaEvent } from "./custom/CalendarCustom";
import { useSelector, useDispatch } from "react-redux";
import { updateAdminEventAction } from "../../redux/actions/adminActions";
import { updateManagerEventAction } from "../../redux/actions/managerActions";
import { updateMemberEventAction } from "../../redux/actions/memberActions";
import { updateGuestEventAction } from "../../redux/actions/guestActions";
import { ErrorNotification } from "../../components";

// Add DnD to the calendar
const DnDCalendar = withDragAndDrop(Calendar);

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

// Formats
const formats = {
  agendaDateFormat: "dddd, MMMM Do",
  monthHeaderFormat: "MMMM YYYY",
  agendaTimeGutterFormat: "h:mm a",
  monthFormat: "MMMM YYYY",
  dayHeaderFormat: "dddd, MMMM Do",
};

const EventCalendar = ({ events, fetchEvents, loading }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [eventsData, setEventsData] = useState([]);
  const [eventStart, setEventStart] = useState(null);
  const [eventEnd, setEventEnd] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editEvent, setEditEvent] = useState({});

  // Set the events data from the events state
  useEffect(() => {
    setEventsData(events);
  }, [events]);

  // Handle event click
  const handleEventClick = (event) => {
    setEditModalOpen(!editModalOpen);
    setEditEvent(event);
  };

  // Handle event select slot
  const handleSlotSelect = ({ start, end }) => {
    // Convert to datepicker date time 24 hour format
    const startDate = moment(start);
    const endDate = moment(end);

    // Set the start and end dates
    setEventStart(startDate);
    setEventEnd(endDate);
    setCreateModalOpen(true);
  };

  // Handle event drop
  const handleEventDropOrResize = ({ event, start, end }) => {
    const isProject = event.project ? event.project : false;
    const isTodo = event.todo ? event.todo : false;
    const isEvent = event.event ? event.event : false;

    const values = {
      name: event.title,
      description: event.desc,
      start: moment(start).toDate(),
      end: moment(end).toDate(),
    };

    // Dispatch action to update event
    if (user?.role === "admin") {
      dispatch(
        updateAdminEventAction(isProject, event, values, isTodo, isEvent)
      );
    } else if (
      (user?.role === "manager" || user?.role === "member") &&
      isProject
    ) {
      ErrorNotification("You can't modify a project event");
    } else if (user?.role === "manager" && !isProject) {
      dispatch(
        updateManagerEventAction(isProject, event, values, isTodo, isEvent)
      );
    } else if (user?.role === "member" && !isProject) {
      dispatch(
        updateMemberEventAction(isProject, event, values, isTodo, isEvent)
      );
    } else if (user?.role === "guest" && !isProject) {
      dispatch(
        updateGuestEventAction(isProject, event, values, isTodo, isEvent)
      );
    }
  };

  return (
    <>
      {/* Create Event Modal */}
      {createModalOpen && (
        <CreateEventModal
          eventStart={eventStart}
          eventEnd={eventEnd}
          open={createModalOpen}
          handleModalClose={() => setCreateModalOpen(false)}
        />
      )}

      {/* Edit Event Modal */}
      {editModalOpen && (
        <EditEventModal
          open={editModalOpen}
          handleModalClose={() => setEditModalOpen(false)}
          editEvent={editEvent}
          fetchEvents={fetchEvents}
        />
      )}

      {loading ? (
        <div
          className="clock-loader"
          style={{ top: "calc(50vh - 100px)" }}
        ></div>
      ) : (
        <>
          <DnDCalendar
            selectable
            views={["day", "week", "month", "agenda"]}
            defaultView="week"
            startAccessor="start"
            endAccessor="end"
            formats={formats}
            dateFormat="h t"
            localizer={localizer}
            defaultDate={new Date()}
            events={eventsData}
            style={{ height: "75vh" }}
            onSelectEvent={handleEventClick}
            onSelectSlot={handleSlotSelect}
            onEventDrop={handleEventDropOrResize}
            onEventResize={handleEventDropOrResize}
            step={30} // 30 min slots in an hour
            timeslots={2} // 2 slots in hour
            min={new Date(2021, 0, 1, 6, 0, 0)} // Minimum time in day
            max={new Date(2021, 0, 1, 23, 0, 0)} // Maximum time in day
            // Event props
            eventPropGetter={(event, start, end, isSelected) => ({
              style: {
                borderRadius: "0.7rem",
                border: "none",
                margin: "0.08rem",
                backgroundColor: event.color,
                color: "black",
                // Shadow
                boxShadow: "2px 5px 5px 0px rgba(0,0,0,0.1)",
                borderColor: "black",
                // Reduce the size of cell
                height: "1.5rem",
                // Reduce the size of the event
                fontSize: "0.8rem",
                // Move text to the right
                paddingLeft: "1.5rem",
              },
            })}
            components={{
              agenda: {
                event: CustomAgendaEvent,
              },
            }}
            length={4} // 4 day view in agenda
            getDrilldownView={(
              targetDate,
              currentViewName,
              configuredViewNames
            ) => {
              if (
                currentViewName === "month" &&
                configuredViewNames.includes("day")
              )
                return "day";

              return null;
            }}
          />
        </>
      )}
    </>
  );
};

export default EventCalendar;
