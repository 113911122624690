import React from "react";
import { Col, Row, Switch } from "antd";

const ChatSection = ({ mbrChatDisabled, setMbrChatDisabled }) => {
  return (
    <>
      <h4
        style={{
          marginBottom: "1.5rem",
        }}
      >
        Chat
      </h4>
      {/* Chat  */}
      <Row
        align="middle"
        style={{
          marginBottom: "30px",
        }}
      >
        <Col
          sm={15}
          span={12}
          className="hp-caption hp-text-color-black-80 hp-text-color-dark-30"
        >
          Disable chat for members
        </Col>

        <Switch
          className="hp-mt-sm-8 hp-ml-sm-0 hp-ml-8"
          checked={mbrChatDisabled}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={(checked) => setMbrChatDisabled(checked)}
        />
      </Row>
    </>
  );
};

export default ChatSection;
