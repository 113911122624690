import React from "react";

import { Card, Row, Col, Table, Tag, Button } from "antd";

const TaskListTableCard = ({ tasks, handleTasksView }) => {
  const columns = [
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          #
        </span>
      ),
      dataIndex: "no",
      key: "no",
      render: (text) => (
        <p className="hp-mb-0 hp-font-weight-500 hp-text-color-black-100 hp-text-color-dark-0">
          {text}
        </p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          Name
        </span>
      ),
      dataIndex: "taskName",
      key: "taskName",
      render: (text) => (
        <p className="hp-mb-0 hp-text-color-black-100 hp-text-color-dark-30">
          {text}
        </p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          Stage
        </span>
      ),
      key: "stage",
      dataIndex: "stage",
      render: (stage) => (
        <Tag
          color={
            stage === "Done"
              ? "green"
              : stage === "In Progress"
              ? "blue"
              : stage === "Review"
              ? "yellow"
              : "volcano"
          }
          key={stage}
        >
          {stage}
        </Tag>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          Start Date
        </span>
      ),
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => (
        <p className="hp-mb-0 hp-text-color-black-100 hp-text-color-dark-30">
          {text}
        </p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          End Date
        </span>
      ),
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => (
        <p className="hp-mb-0 hp-text-color-black-100 hp-text-color-dark-30">
          {text}
        </p>
      ),
    },
    {
      title: (
        <span className="hp-badge-size hp-font-weight-600 hp-text-color-black-100 hp-text-color-dark-0">
          Priority
        </span>
      ),
      key: "priority",
      dataIndex: "priority",
      render: (priority) => (
        <Tag
          color={
            priority === "Critical"
              ? "red"
              : priority === "High"
              ? "orange"
              : priority === "Medium"
              ? "yellow"
              : "green"
          }
          key={priority}
        >
          {priority}
        </Tag>
      ),
    },
  ];

  const data = tasks?.map((task, index) => {
    return {
      key: task._id,
      no: index + 1,
      taskName: task.name,
      stage: task.stage,
      startDate: task?.startDate
        ? new Date(task?.startDate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        : "Not Set",
      endDate: task?.endDate
        ? new Date(task?.endDate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        : "Not Set",
      priority: task.priority,
    };
  });

  return (
    <Col span={23}>
      <Card className="hp-card-1 hp-project-table-card">
        <Row>
          <Col span={24}>
            <Row justify="space-between">
              <h3 className="hp-mb-16 hp-font-weight-400">Tasks</h3>

              {/* View all Tasks */}
              <Button
                type="primary"
                size="small"
                onClick={handleTasksView}
                className="hp-mb-16"
              >
                <span className="hp-ml-8">View Tasks</span>
              </Button>
            </Row>

            <Table
              style={{
                overflowY: "auto",
                height: "calc(85vh - 300px)",
              }}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{ x: 700 }}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default TaskListTableCard;
