import styled from "styled-components";

export const ParentTable = styled.table`
  width: 100%;
  border-radius: 5px;
  overflow-y: scroll;
  th,
  td {
    padding: 8px;
  }
`;

export const ParentTableHead = styled.thead`
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  font-weight: normal;
  z-index: 99;
  text-align: left;
  tr {
    border-bottom: 1px solid #e9e9e9;
  }
`;
