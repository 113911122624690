import React, { useState, useEffect } from "react";
import {
  MemberInviteModal,
  ChangeRoleModal,
  ParentTable,
  ParentTableHead,
  SuccessNotification,
  ErrorNotification,
  InfoNotification,
} from "../../../components";
import { Card, Row, Col, Button, Tag, Dropdown, Menu, Modal } from "antd";
import { getTeamMembers } from "../../../api/admin";
import { getManagerTeamMembers } from "../../../api/manager";
import { deleteUserAccount } from "../../../api/user";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import {
  RiEyeFill,
  RiDeleteBin6Fill,
  RiBarChartHorizontalFill,
} from "react-icons/ri";
import { FaExchangeAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Joyride
import Joyride, { STATUS } from "react-joyride";
import { adminTeamSteps } from "../../../helpers/joyride/adminTeamInvite";
import { updateAdminTutStatusAction } from "../../../redux/actions/adminActions";

const TeamList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  // Invite Modal
  const [open, setOpen] = useState(false);
  const [team, setTeam] = useState([]);
  // Role Change Modal
  const [changeRoleOpen, setChangeRoleOpen] = useState(false);
  const [changeCurrentRole, setChangeCurrentRole] = useState("");
  // Team
  const [members, setMembers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [guests, setGuests] = useState([]);

  const [tutFinished, setTutFinished] = useState(
    JSON.parse(localStorage.getItem("joyride"))?.teamInvite
  );

  // Handle modal open/close
  const handleInviteMember = () => {
    if (user?.currentPlan === "Free") {
      InfoNotification("Upgrade your plan to invite your team");
    } else if (
      user?.currentPlan === "Starter" &&
      managers?.length + members?.length >= 5
    ) {
      InfoNotification("Upgrade your plan to invite more members");
    } else if (
      user?.currentPlan === "Business" &&
      managers?.length + members?.length >= 20
    ) {
      InfoNotification("Upgrade your plan to invite more members");
    } else {
      setOpen(!open);
    }
  };

  // Handle role change modal open/close
  const handleChangeRole = (member) => {
    setChangeCurrentRole(member);
    setChangeRoleOpen(!changeRoleOpen);
  };

  // Handle view team report
  const handleViewTeamReport = () => {
    if (user?.role === "admin") {
      history.push("/admin/team/report");
    } else if (user?.role === "manager") {
      history.push("/manager/team/report");
    }
  };

  // Handle delete account
  const handleDeleteAccount = (member) => {
    const value = {
      role: member.role.toLowerCase(),
    };
    // Confirm delete
    Modal.confirm({
      title: `Are you sure you want to delete ${member?.name}'s account?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          // Delete event
          deleteUserAccount(member._id, value)
            .then(() => {
              SuccessNotification("User account deleted successfully");
              // Fetch all team members
              fetchTeamMembers();
            })
            .catch((err) => {
              console.log("err", err);
            });
        } catch (error) {
          // Show error notification
          ErrorNotification(error.data.message);
        }
      },
    });
  };

  // Fetch all team members
  const fetchTeamMembers = async () => {
    setLoading(true);
    if (user?.role === "admin") {
      // Admin
      const { data } = await getTeamMembers();

      const modAdmins = data?.admins?.map((admin) => {
        return {
          ...admin,
          role: "Admin",
        };
      });

      const modMembers = data?.members?.map((member) => {
        return { ...member, role: "Member" };
      });
      const modManagers = data?.managers?.map((manager) => {
        return { ...manager, role: "Manager" };
      });
      const modGuests = data?.guests?.map((guest) => {
        return { ...guest, role: "Guest" };
      });
      const adminObject = {
        _id: user?._id,
        organization: user?.organization,
        name: user?.name,
        email: user?.email,
        role: "Admin",
        createdAt: user?.createdAt,
      };

      setMembers(modMembers);
      setManagers(modManagers);
      setGuests(modGuests);

      setTeam([
        adminObject,
        ...modAdmins,
        ...modMembers,
        ...modManagers,
        ...modGuests,
      ]);
    } else if (user?.role === "manager") {
      // Manager
      const { data } = await getManagerTeamMembers();

      const modAdmins = data?.admins?.map((admin) => {
        return {
          ...admin,
          role: "Admin",
        };
      });
      const modMembers = data?.members?.map((member) => {
        return { ...member, role: "Member" };
      });
      const modManagers = data?.managers?.map((manager) => {
        return { ...manager, role: "Manager" };
      });
      const modGuests = data?.guests?.map((guest) => {
        return { ...guest, role: "Guest" };
      });

      // To the data.admin object, add the role "Admin"
      const adminObject = {
        _id: data?.admin?._id,
        name: data?.admin?.name,
        email: data?.admin?.email,
        role: "Admin",
        createdAt: data?.admin?.createdAt,
      };

      setMembers(modMembers);
      setManagers(modManagers);
      setGuests(modGuests);
      const combined = [
        adminObject,
        ...modAdmins,
        ...modMembers,
        ...modManagers,
        ...modGuests,
      ];
      setTeam(combined);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  // Handle joyride finish
  const handleJoyrideCallback = (data) => {
    if (data.status === STATUS.FINISHED) {
      setTutFinished(true);
      const pageToUpdate = {
        teamInvite: true,
      };

      // If user is admin
      if (user?.role === "admin") {
        dispatch(updateAdminTutStatusAction(pageToUpdate));
      }

      // Get the joyride object from local storage
      const joyrideObj = JSON.parse(localStorage.getItem("joyride"));

      // Update the joyride object
      joyrideObj.teamInvite = true;

      // Set the joyride object to local storage
      localStorage.setItem("joyride", JSON.stringify(joyrideObj));
    }
  };

  return (
    <>
      {/* <Joyride
        steps={adminTeamSteps}
        run={!tutFinished}
        continuous
        showProgress={true}
        showSkipButton={false}
        // Run only once
        callback={(data) => {
          handleJoyrideCallback(data);
        }}
        disableScrolling
        disableOverlayClose
        disableCloseOnEsc
        spotlightClicks
      /> */}
      <Card
        className="hp-border-color-black-40 hp-mb-32 hp-analytics-project-table-card hp-project-table-card joy-admin-team-inv"
        style={{
          height: "85vh",
        }}
      >
        <Row>
          <Col span={24} className="joy-admin-team-list">
            <Row justify="space-between">
              <h3 className="hp-mb-16">My Team</h3>

              <Row>
                <Button
                  type="primary"
                  size="small"
                  onClick={handleViewTeamReport}
                  className="joy-admin-team-inv-button"
                  style={{
                    backgroundColor: "#d6e5fe",
                    marginRight: "16px",
                    color: "#2e67f8",
                  }}
                >
                  View Team Report
                </Button>
                {/* Invite team button */}
                <Button
                  type="primary"
                  size="small"
                  onClick={handleInviteMember}
                  className="joy-admin-team-inv-button"
                >
                  Invite Teammate
                </Button>
              </Row>
            </Row>

            {open && (
              <MemberInviteModal
                open={open}
                handleModalClick={handleInviteMember}
              />
            )}

            {/* Change role modal */}
            {changeRoleOpen && (
              <ChangeRoleModal
                open={changeRoleOpen}
                setOpen={setChangeRoleOpen}
                changeCurrentRole={changeCurrentRole}
                fetchTeamMembers={fetchTeamMembers}
              />
            )}

            {loading ? (
              <div
                className="clock-loader"
                style={{ top: "calc(40vh - 100px)" }}
              ></div>
            ) : (
              <>
                <Col
                  style={{
                    overflowY: "auto",
                    height: "calc(100vh - 230px)",
                  }}
                >
                  <Col>
                    {/* 1 Line Space */}
                    <div style={{ height: "20px" }}></div>
                    {/* Team list */}
                    <div>
                      {/* Table of joined team*/}
                      <ParentTable>
                        <ParentTableHead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Role</th>
                            <th scope="col">Email</th>
                            <th scope="col">Join date</th>
                            <th scope="col">Action</th>
                          </tr>
                        </ParentTableHead>
                        <tbody>
                          {team?.map((member) => (
                            <tr key={member?._id}>
                              <td style={{ cursor: "pointer" }}>
                                {member?.name}
                              </td>
                              <td>
                                <Tag
                                  color={
                                    member?.role === "Admin"
                                      ? "purple"
                                      : member?.role === "Manager"
                                      ? "blue"
                                      : member?.role === "Member"
                                      ? "orange"
                                      : "volcano"
                                  }
                                >
                                  {member?.role}
                                </Tag>
                              </td>
                              <td>{member?.email}</td>
                              <td>{member?.createdAt.split("T")[0]}</td>
                              {user?.email !== member?.email ? (
                                <tr>
                                  <Dropdown
                                    trigger={["click"]}
                                    placement="bottomRight"
                                    overlay={
                                      <Menu>
                                        {/* Admin Menu */}
                                        {user?.role === "admin" &&
                                          member?.role !== "Guest" && (
                                            <Menu.Item
                                              key={0}
                                              onClick={() =>
                                                handleChangeRole(member)
                                              }
                                              icon={
                                                <FaExchangeAlt
                                                  size={16}
                                                  style={{
                                                    marginRight: "14px",
                                                  }}
                                                />
                                              }
                                            >
                                              Change Role
                                            </Menu.Item>
                                          )}
                                        {user?.role === "admin" && (
                                          <Menu.Item
                                            key={1}
                                            onClick={() =>
                                              handleDeleteAccount(member)
                                            }
                                            icon={
                                              <RiDeleteBin6Fill
                                                size={16}
                                                style={{ marginRight: "14px" }}
                                              />
                                            }
                                          >
                                            Delete Account
                                          </Menu.Item>
                                        )}
                                        {/* Manager Menu */}
                                        {user?.role === "manager" && (
                                          <Menu.Item
                                            key={1}
                                            onClick={() =>
                                              handleViewTeamReport()
                                            }
                                            icon={
                                              <RiBarChartHorizontalFill
                                                size={16}
                                                style={{ marginRight: "14px" }}
                                              />
                                            }
                                          >
                                            View Report
                                          </Menu.Item>
                                        )}
                                        {user?.role === "manager" &&
                                          member?.role === "Member" && (
                                            <Menu.Item
                                              key={1}
                                              onClick={() =>
                                                handleDeleteAccount(member)
                                              }
                                              icon={
                                                <RiEyeFill
                                                  size={16}
                                                  style={{
                                                    marginRight: "14px",
                                                  }}
                                                />
                                              }
                                            >
                                              Delete Account
                                            </Menu.Item>
                                          )}
                                      </Menu>
                                    }
                                  >
                                    <Button
                                      size="small"
                                      style={{
                                        border: "none",
                                      }}
                                    >
                                      <BiDotsHorizontalRounded
                                        className="remix-icon"
                                        size={24}
                                      />
                                    </Button>
                                  </Dropdown>
                                </tr>
                              ) : (
                                <Tag color="cyan">Me</Tag>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </ParentTable>
                    </div>
                  </Col>
                </Col>
              </>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default TeamList;
