import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Radio,
  Form,
  Input,
  Select,
  DatePicker,
} from "antd";
import { createProject } from "../../../api/project";
import { createManagerProject } from "../../../api/manager";
import { getAdminTeamMembersAction } from "../../../redux/actions/adminActions";
import { getManagerTeamMembersAction } from "../../../redux/actions/managerActions";
import { useSelector, useDispatch } from "react-redux";
import { SuccessNotification, ErrorNotification } from "../..";
import moment from "moment";

const initialState = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  status: "",
  assignedTo: [],
  managers: [],
  guests: [],
};

const CreateProjectModal = ({ open, handleModalClick, history }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  const { members, managers, guests } = useSelector((state) => state.admin);
  const [values, setValues] = useState(initialState);
  const [dateRange, setDateRange] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  // Fetch all team members
  const fetchTeamMembers = async () => {
    if (user?.role === "admin") {
      dispatch(getAdminTeamMembersAction());
    } else if (user?.role === "manager") {
      dispatch(getManagerTeamMembersAction());
    }
  };

  // Handle form changes
  const handleFormChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setCreateLoading(true);

    // Set start and end date from date range
    // Convert start and end dates mongoose Date format with the same time
    values.startDate = moment(dateRange[0]).toDate();
    values.endDate = moment(dateRange[1]).toDate();

    // Make sure all the values are set
    if (
      values.name === "" ||
      values.startDate === "" ||
      values.endDate === "" ||
      values.status === ""
    ) {
      setCreateLoading(false);
      ErrorNotification("Please fill in all the required fields");
      return;
    }

    // Name length validation
    if (values.name.length > 100) {
      setCreateLoading(false);
      ErrorNotification("Project name cannot be more than 100 characters");
      return;
    }
    // Description length validation
    if (values.description.length > 500) {
      setCreateLoading(false);
      ErrorNotification(
        "Project description cannot be more than 500 characters"
      );
      return;
    }

    if (user?.role === "admin") {
      // Create project
      createProject(values)
        .then((res) => {
          setCreateLoading(false);
          SuccessNotification(`${res.data.name} created successfully`);
          history.push(`/admin/projects/view/${res.data._id}`);
        })
        .catch((err) => {
          setCreateLoading(false);
          ErrorNotification(err.response.data.message);
          console.log("CREATE_PROJECT_ERROR", err);
        });
    } else if (user?.role === "manager") {
      // Create manager project
      createManagerProject(values)
        .then((res) => {
          setCreateLoading(false);
          SuccessNotification(`${res.data.name} created successfully`);
          history.push(`/manager/projects/view/${res.data._id}`);
        })
        .catch((err) => {
          setCreateLoading(false);
          ErrorNotification(err.response.data.message);
          console.log("CREATE_MANAGER_PROJECT_ERROR", err);
        });
    }
  };

  return (
    <>
      <Modal
        title={
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Create Project
          </h2>
        }
        visible={open}
        onOk={handleModalClick}
        width={750}
        style={{
          top: "0.5rem",
        }}
        centered
        closable={false}
        footer={[
          <Button key="back" onClick={handleModalClick}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleFormSubmit}
            loading={createLoading}
          >
            Create
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 24,
          }}
        >
          {/* Project name */}
          <Form.Item label="Name" required>
            <Input
              name="name"
              placeholder="Project name"
              onChange={handleFormChange}
              value={values.name}
            />
          </Form.Item>
          {/* Project description */}
          <Form.Item label="Description">
            <Input.TextArea
              name="description"
              placeholder="Project description"
              onChange={handleFormChange}
              value={values.description}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>
          {/* Project start and end date */}
          <Form.Item label="Timeline" required>
            <Row>
              <Col span={12}>
                <DatePicker.RangePicker
                  name="dateRange"
                  onChange={(date, dateString) => {
                    setDateRange(dateString);
                  }}
                  autoFocus
                  popupStyle={{
                    height: "auto",
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  style={{
                    width: "100%",
                  }}
                  popupAlign={{
                    offset: [0, 0],
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
          {/* Project status */}
          <Form.Item label="Status" required>
            <Radio.Group
              name="status"
              onChange={(e) => handleFormChange(e)}
              value={values.status}
            >
              <Radio.Button value="In Progress">In Progress</Radio.Button>
              <Radio.Button value="Completed">Completed</Radio.Button>
              <Radio.Button value="On Hold">On Hold</Radio.Button>
              <Radio.Button value="Cancelled">Cancelled</Radio.Button>
            </Radio.Group>
          </Form.Item>

          {/* Add project managers */}
          {managers && (
            <Form.Item label="Managers">
              <Select
                mode="multiple"
                showSearch
                name="managers"
                // If no managers, placeholder no managers else select managers
                placeholder={
                  managers.length === 0
                    ? "No managers available"
                    : "Select managers"
                }
                onChange={(manager) => {
                  setValues({
                    ...values,
                    managers: manager,
                  });
                }}
                value={values.managers}
                disabled={managers.length === 0}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {managers.map((manager) => (
                  <Select.Option key={manager._id} value={manager._id}>
                    {manager.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {/* Add project members */}
          {members && (
            <Form.Item label="Members">
              <Select
                mode="multiple"
                showSearch
                name="assignedTo"
                placeholder={
                  members.length === 0
                    ? "No members available"
                    : "Select members"
                }
                onChange={(member) => {
                  setValues({
                    ...values,
                    assignedTo: member,
                  });
                }}
                value={values.assignedTo}
                disabled={members.length === 0}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {members.map((member) => (
                  <Select.Option key={member._id} value={member._id}>
                    {member.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {/* Add project guests */}
          {guests && (
            <Form.Item label="Guests">
              <Select
                mode="multiple"
                name="guests"
                placeholder={
                  guests.length === 0 ? "No guests available" : "Select guests"
                }
                onChange={(guest) => {
                  setValues({
                    ...values,
                    guests: guest,
                  });
                }}
                value={values.guests}
                disabled={guests.length === 0}
              >
                {guests.map((guest) => (
                  <Select.Option key={guest._id} value={guest._id}>
                    {guest.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default CreateProjectModal;
