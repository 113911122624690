import React, { useState, useEffect } from "react";
import { ParentTable, ParentTableHead } from "../../../components";
import { Card, Row, Col, Button, Modal, Spin } from "antd";
import { RiEditFill, RiDeleteBin2Fill } from "react-icons/ri";
import { getAllBlogs, deleteBlogById } from "../../../api/blog";
import { SuccessNotification, ErrorNotification } from "../../../components";

const BlogsList = ({ history }) => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch all blogs
  const fetchBlogs = async () => {
    setLoading(true);
    const { data } = await getAllBlogs();
    setBlogs(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  // Handle blog create
  const handleBlogCreate = () => {
    history.push("/super-admin/blog/create");
  };

  // Handle blog update
  const handleBlogUpdate = (id) => {
    history.push(`/super-admin/blog/${id}/update`);
  };

  // Handle blog delete
  const handleBlogDelete = (id) => {
    // Confirm delete
    Modal.confirm({
      title: "Are you sure you want to delete this blog?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteBlogById(id);
          // Fetch all blogs
          fetchBlogs();
          // Show success notification
          SuccessNotification("Blog deleted successfully");
        } catch (error) {
          // Show error notification
          ErrorNotification("Error deleting blog");
        }
      },
    });
  };

  const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <>
      <Card
        className="hp-border-color-black-40 hp-mb-32 hp-analytics-project-table-card hp-project-table-card"
        style={{
          overflowY: "auto",
          height: "calc(100vh - 150px)",
        }}
      >
        <Row>
          <Col span={24}>
            <Row justify="space-between">
              <h3 className="hp-mb-16">Blogs List</h3>
              <Button type="primary" onClick={handleBlogCreate}>
                Create Blog
              </Button>
            </Row>

            {/* All blogs table */}
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(80vh - 50px)",
                }}
              >
                <Spin />
              </div>
            ) : (
              <ParentTable>
                <ParentTableHead>
                  <tr>
                    <th scope="col">Blog title</th>
                    <th scope="col">Author</th>
                    <th scope="col">Category</th>
                    <th scope="col">Published at</th>
                    <th scope="col">Created at</th>
                    <th scope="col">Updated at</th>
                  </tr>
                </ParentTableHead>
                <tbody>
                  {blogs.map((blog) => (
                    <tr key={blog._id}>
                      <td>{blog?.title}</td>
                      <td>{blog?.author.name}</td>
                      <td>{blog?.category}</td>
                      <td>
                        {new Date(blog?.publishedDate).toLocaleDateString(
                          "en-US",
                          dateFormat
                        )}{" "}
                      </td>
                      <td>
                        {new Date(blog?.createdAt).toLocaleDateString(
                          "en-US",
                          dateFormat
                        )}{" "}
                      </td>
                      <td>
                        {new Date(blog?.updatedAt).toLocaleDateString(
                          "en-US",
                          dateFormat
                        )}{" "}
                      </td>
                      <td style={{ cursor: "pointer" }}>
                        {/* Edit Icon */}
                        <RiEditFill
                          onClick={(e) => handleBlogUpdate(blog._id)}
                        />
                        {/* Space between icons */}
                        <span style={{ margin: "0 10px" }} />
                        {/* Delete Icon */}
                        <RiDeleteBin2Fill
                          onClick={(e) => handleBlogDelete(blog._id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ParentTable>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default BlogsList;
