import React, { useState } from "react";
import { AutoComplete } from "antd";
import { searchTeamMembers } from "../../../api/admin";
import { searchManagerTeamMembers } from "../../../api/manager";
import { searchMemberTeamMembers } from "../../../api/member";
import { createChat } from "../../../api/chat";
import { useSelector } from "react-redux";

const { Option } = AutoComplete;

const SearchUser = ({ handleSelectedChat }) => {
  const { user } = useSelector((state) => state.auth);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  // Handle search user
  const handleSearchUser = (val) => {
    // If admin
    if (user?.role === "admin") {
      searchTeamMembers(val)
        .then((res) => {
          // Extract the name, email & _id from the response
          const searchResult = res.data.map((member) => {
            return {
              name: member.name,
              email: member.email,
              _id: member.user,
            };
          });
          setSearchResult(searchResult);
        })
        .catch((err) => {
          console.log("SEARCH_ADMIN_TEAM_MEMBER_ERROR", err);
        });
    } else if (user?.role === "manager") {
      searchManagerTeamMembers(val)
        .then((res) => {
          // Extract the name, email & _id from the response
          const searchResult = res.data.map((member) => {
            return {
              name: member.name,
              email: member.email,
              _id: member.user,
            };
          });
          setSearchResult(searchResult);
        })
        .catch((err) => {
          console.log("SEARCH_MANAGER_TEAM_MEMBER_ERROR", err);
        });
    } else {
      searchMemberTeamMembers(val)
        .then((res) => {
          // Extract the name, email & _id from the response
          const searchResult = res.data.map((member) => {
            return {
              name: member.name,
              email: member.email,
              _id: member.user,
            };
          });
          setSearchResult(searchResult);
        })
        .catch((err) => {
          console.log("SEARCH_MEMBER_TEAM_MEMBER_ERROR", err);
        });
    }
  };

  // Handle select user
  const handleSelectUser = (id) => {
    if (id) {
      createChat({ otherMemberId: id })
        .then((res) => {
          handleSelectedChat(res.data.chat);
        })
        .catch((err) => {
          console.log("CREATE_CHAT_ERROR", err);
        });
    }
  };

  return (
    <div
      style={{
        marginBottom: "25px",
      }}
      className="joy-admin-chat-search"
    >
      <AutoComplete
        dropdownMatchSelectWidth={252}
        style={{ width: "100%" }}
        onSelect={(value, option) => {
          handleSelectUser(value);
          // Set search to member name
          setSearch(option.children);
        }}
        onSearch={(val) => handleSearchUser(val)}
        placeholder="Type team member name"
        onChange={(val) => setSearch(val)}
        value={search}
      >
        {searchResult.map((member) => (
          <Option key={member._id} value={member._id}>
            {member.name}
          </Option>
        ))}
      </AutoComplete>
    </div>
  );
};

export default SearchUser;
