import React from "react";
import { Row, Col, Card, Tag } from "antd";
import { Draggable } from "react-beautiful-dnd";

const TaskCard = ({ card, index, handleViewTaskClick }) => {
  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided) => (
        <Col
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <Card
            style={{
              marginBottom: "0.5rem",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "0.5rem",
            }}
            onClick={() => handleViewTaskClick(card.id)}
          >
            <Row justify="space-between" style={{ cursor: "pointer" }}>
              {/* Task Name */}
              <Row>
                <p
                  style={{
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "16ch",
                  }}
                >
                  {card.name}
                </p>
              </Row>

              {/* Task Priority */}
              <Row
                style={{
                  height: "20px",
                  marginTop: "-15px",
                  marginRight: "-25px",
                  marginBottom: "10px",
                }}
              >
                <Tag
                  color={
                    card.priority === "Critical"
                      ? "red"
                      : card.priority === "High"
                      ? "orange"
                      : card.priority === "Medium"
                      ? "yellow"
                      : "blue"
                  }
                >
                  {card.priority}
                </Tag>
              </Row>
            </Row>
          </Card>
        </Col>
      )}
    </Draggable>
  );
};

export default TaskCard;
