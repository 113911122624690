import React from "react";
import { Divider } from "antd";
import {
  AssignedTo,
  TaskDescription,
  ChecklistDisplay,
  Timeline,
  Attachments,
  Comments,
} from "./subComponentsLeft";

const TaskLeftCol = ({
  values,
  setValues,
  handleUpdateTask,
  taskId,
  updateTaskLoading,
}) => {
  return (
    <>
      {/* Assigned to avatars */}
      <AssignedTo values={values} />

      <Divider />

      {/* Task description */}
      <TaskDescription
        values={values}
        setValues={setValues}
        handleUpdateTask={handleUpdateTask}
        updateTaskLoading={updateTaskLoading}
      />

      <Divider />

      {/* Timeline */}
      <Timeline values={values} />

      <Divider />

      {/* Task Checklist */}
      <ChecklistDisplay values={values} setValues={setValues} taskId={taskId} />

      <Divider />

      {/* Attachments */}
      <Attachments values={values} />

      <Divider />

      {/* Comments */}
      <Comments values={values} setValues={setValues} taskId={taskId} />
    </>
  );
};

export default TaskLeftCol;
