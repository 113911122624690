import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import logoText from "../../../../assets/images/logo/logoText.png";
import { redirectToDashboard } from "../../../../utils/roleBasedRedirect";

const MenuLogo = (props) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Link
      to={redirectToDashboard(user)}
      className="hp-header-logo hp-d-flex hp-align-items-end"
      onClick={props.onClose}
      style={{
        // Move down
        transform: "translateX(-10px) translateY(5px)",
      }}
    >
      <img
        className="hp-logo"
        src={logoText}
        alt="logo"
        style={{ backgroundColor: "transparent" }}
      />
    </Link>
  );
};

export default MenuLogo;
