import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { BiArrowBack } from "react-icons/bi";
import MemberMilestoneList from "./milestone/MemberMilestoneList";
import { ProjectOverviewCard } from "../../../components";
import { Row, Col } from "antd";
import { getMemberProjectById } from "../../../api/member";

const initialState = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  estimatedHours: "",
  tags: [],
  status: "",
};

const MemberProjectDetail = ({ history }) => {
  const { id } = useParams();
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({ totalTasks: 0, completedTasks: 0 });

  // Get project by id
  const fetchProject = async () => {
    try {
      setLoading(true);
      const { data } = await getMemberProjectById(id);
      data.project.startDate = data.project.startDate.split("T")[0];
      data.project.endDate = data.project.endDate.split("T")[0];
      setValues(data.project);
      // Set the total tasks and completed tasks
      setStats({
        totalTasks: data.totalTasks,
        completedTasks: data.completedTasks,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("GET_MEMBER_PROJECT_BY_ID_ERROR", error);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="clock-loader"
          style={{ top: "calc(50vh - 100px)" }}
        ></div>
      ) : (
        <Row>
          <Col span={24}>
            <Row>
              <BiArrowBack
                style={{ fontSize: "2em", cursor: "pointer" }}
                onClick={() => history.push("/member/projects")}
              />
              <h3
                className="hp-mb-16"
                style={{
                  fontSize: "2em",
                  marginLeft: "calc(45% - 100px)",
                }}
              >
                {values.name}
              </h3>
            </Row>

            {/* Project Overview Section */}
            <ProjectOverviewCard stats={stats} values={values} />

            <Row gutter={[16, 16]} type="flex">
              {/* Milestone section */}
              <MemberMilestoneList />
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default MemberProjectDetail;
