import React from "react";
import { Card, Row, Col, Progress } from "antd";

const CompletedTasks = ({ percentComplete, completedTasks }) => {
  return (
    <Card className="hp-border-color-black-40 hp-mb-16 hp-card-1">
      <Row gutter={16} align="middle">
        <Col className="hp-pl-0" span={10}>
          <div id="chart">
            <Progress
              type="circle"
              percent={Math.round(percentComplete)}
              format={(percent) => `${percent} %`}
              width={72}
              strokeColor="#1BE7FF"
            />
          </div>
        </Col>

        <Col className="hp-pr-0" span={14}>
          <h3 className="hp-mb-0">{completedTasks}</h3>

          <p className="hp-p1-body hp-mb-0">Completed Tasks</p>
        </Col>
      </Row>
    </Card>
  );
};

export default CompletedTasks;
